import React from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";
import { HtmlButtonProps } from "../../types/ReusableTypes";
import InlineLoading from "../InlineLoading/InlineLoading";

type ButtonType = "default" | "inline" | "navigation" | "hollow" | "link";

type Props = HtmlButtonProps & {
    label: string;
    displayType: ButtonType;
    loading: boolean;
};

const Button = ({ className, label, displayType, loading, ...props }: Props) => {
    return (
        <button
            className={classNames(styles.container, styles[displayType], className)}
            type="button"
            data-testid="Button"
            {...props}
        >
            {loading ? <InlineLoading /> : label}
        </button>
    );
};

Button.defaultProps = {
    className: "",
    displayType: "default",
    loading: false,
};

export default Button;
