/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PresenceStatistic
 */
export interface PresenceStatistic {
    /**
     *
     * @type {number}
     * @memberof PresenceStatistic
     */
    present?: number;
    /**
     *
     * @type {number}
     * @memberof PresenceStatistic
     */
    absent?: number;
    /**
     *
     * @type {number}
     * @memberof PresenceStatistic
     */
    unknown?: number;
}

/**
 * Check if a given object implements the PresenceStatistic interface.
 */
export function instanceOfPresenceStatistic(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PresenceStatisticFromJSON(json: any): PresenceStatistic {
    return PresenceStatisticFromJSONTyped(json, false);
}

export function PresenceStatisticFromJSONTyped(json: any, ignoreDiscriminator: boolean): PresenceStatistic {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        present: !exists(json, "present") ? (undefined as any) : json["present"],
        absent: !exists(json, "absent") ? (undefined as any) : json["absent"],
        unknown: !exists(json, "unknown") ? (undefined as any) : json["unknown"],
    };
}

export function PresenceStatisticToJSON(value?: PresenceStatistic | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
