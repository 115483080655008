import React, { PropsWithChildren } from "react";
import { HappeningsApiProvider } from "./HappeningsApiContext";
import { RegistrationsApiProvider } from "./RegistrationsApiContext";
import { OrganisationInfoApiProvider } from "./OrganisationApiContext";
import { TagApiProvider } from "./TagApiContext";
import { TagsContextProvider } from "./TagsContext";
const ApiProviderCollection = ({ children }: PropsWithChildren<{}>) => (
    <HappeningsApiProvider>
        <OrganisationInfoApiProvider>
            <TagApiProvider>
                <TagsContextProvider>
                    <RegistrationsApiProvider>{children}</RegistrationsApiProvider>
                </TagsContextProvider>
            </TagApiProvider>
        </OrganisationInfoApiProvider>
    </HappeningsApiProvider>
);

export default ApiProviderCollection;
