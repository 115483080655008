/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 * - Append nested object fields in array as formdata fields
 * - Always check for nullable in "body: .map" for json patch
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

function appendToParams(formParams: { append(param: string, value: any): any }, path: string, value: any) {
    if (value === undefined) {
        return;
    } else if (Array.isArray(value)) {
        value.forEach((v, i) => appendToParams(formParams, `${path}[${i}]`, v));
    } else if (value instanceof Date) {
        formParams.append(path, value.toUTCString());
    } else if (value instanceof Blob) {
        formParams.append(path, value);
    } else if (typeof value == "object" && !!value) {
        Object.keys(value).forEach((key) => appendToParams(formParams, `${path}.${key}`, value[key]));
    } else {
        formParams.append(path, value);
    }
}

import * as runtime from "../runtime";
import {
    DayOfWeek,
    DayOfWeekFromJSON,
    DayOfWeekToJSON,
    FavoriteOutput,
    FavoriteOutputFromJSON,
    FavoriteOutputToJSON,
    FilterInclusion,
    FilterInclusionFromJSON,
    FilterInclusionToJSON,
    HappeningListItemViewModel,
    HappeningListItemViewModelFromJSON,
    HappeningListItemViewModelToJSON,
    HappeningListItemViewModelPaginatedViewModel,
    HappeningListItemViewModelPaginatedViewModelFromJSON,
    HappeningListItemViewModelPaginatedViewModelToJSON,
    HappeningOrderBy,
    HappeningOrderByFromJSON,
    HappeningOrderByToJSON,
    HappeningRegistrationType,
    HappeningRegistrationTypeFromJSON,
    HappeningRegistrationTypeToJSON,
    HappeningState,
    HappeningStateFromJSON,
    HappeningStateToJSON,
    HappeningTagInput,
    HappeningTagInputFromJSON,
    HappeningTagInputToJSON,
    HappeningTimeAndDateInput,
    HappeningTimeAndDateInputFromJSON,
    HappeningTimeAndDateInputToJSON,
    HappeningType,
    HappeningTypeFromJSON,
    HappeningTypeToJSON,
    HappeningViewModel,
    HappeningViewModelFromJSON,
    HappeningViewModelToJSON,
    InfoLevel,
    InfoLevelFromJSON,
    InfoLevelToJSON,
    LocationInputModel,
    LocationInputModelFromJSON,
    LocationInputModelToJSON,
    LocationType,
    LocationTypeFromJSON,
    LocationTypeToJSON,
    MapItemViewModel,
    MapItemViewModelFromJSON,
    MapItemViewModelToJSON,
    PaymentMethod,
    PaymentMethodFromJSON,
    PaymentMethodToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    QuestionInput,
    QuestionInputFromJSON,
    QuestionInputToJSON,
    ReasonForCancellation,
    ReasonForCancellationFromJSON,
    ReasonForCancellationToJSON,
    RecurrenceInput,
    RecurrenceInputFromJSON,
    RecurrenceInputToJSON,
    ReferenceOutput,
    ReferenceOutputFromJSON,
    ReferenceOutputToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
    TeamViewModel,
    TeamViewModelFromJSON,
    TeamViewModelToJSON,
    UpdateTarget,
    UpdateTargetFromJSON,
    UpdateTargetToJSON,
    UsagePurpose,
    UsagePurposeFromJSON,
    UsagePurposeToJSON,
    UserSubset,
    UserSubsetFromJSON,
    UserSubsetToJSON,
    Video,
    VideoFromJSON,
    VideoToJSON,
} from "../models";

export interface AddImageToHappeningRequest {
    version: string;
    id: string;
    altText: string;
    file: Blob;
}

export interface AddSelfToNotifyMeListRequest {
    id: string;
    version: string;
}

export interface AddUserToNotifyMeListRequest {
    id: string;
    version: string;
    profileIds?: Array<string>;
}

export interface ArchiveHappeningRequest {
    id: string;
    version: string;
    target?: UpdateTarget;
}

export interface CancelHappeningRequest {
    id: string;
    version: string;
    dateTimes?: Array<number>;
    reasonForCancellation?: ReasonForCancellation;
    reason?: string;
    target?: UpdateTarget;
}

export interface CreateHappeningRequest {
    version: string;
    happeningType: HappeningType;
    title: string;
    restrictAge: boolean;
    state: HappeningState;
    isOnline: boolean;
    requiresApprovalFromMarketing: boolean;
    description?: string;
    link?: string;
    linkLabel?: string;
    targetGroup?: UserSubset;
    targetOrganisations?: Array<string>;
    minAge?: number;
    maxAge?: number;
    schools?: Array<string>;
    askForSchool?: boolean;
    publicationDate?: Date;
    tags?: Array<string>;
    tagInputs?: Array<HappeningTagInput>;
    infoLevel?: InfoLevel;
    maxNumberOfRegistrations?: number;
    locationType?: LocationType;
    location?: Array<LocationInputModel>;
    contact?: string;
    projectLeader?: string;
    projectCoLeaders?: Array<string>;
    projectMembers?: Array<string>;
    price?: number;
    images?: Array<number>;
    videos?: Array<Video>;
    isAccessible?: boolean;
    allowedPaymentMethods?: PaymentMethod;
    organizer?: string;
    cluster?: string;
    happeningRegistrationType?: HappeningRegistrationType;
    requiresRegistration?: boolean;
    minPeoplePerGroup?: number;
    maxPeoplePerGroup?: number;
    canOnlyRegisterAsGroup?: boolean;
    priceForIndividual?: number;
    isProRata?: boolean;
    timeAndDates?: Array<HappeningTimeAndDateInput>;
    recurrence?: Array<RecurrenceInput>;
    updateTarget?: UpdateTarget;
    extraQuestions?: Array<QuestionInput>;
    registrationStartDate?: Date;
    registrationEndDate?: Date;
    durationInMinutes?: number;
}

export interface CreateTeamRequest {
    happeningId: string;
    version: string;
    body?: string;
}

export interface DeleteHappeningRequest {
    id: string;
    version: string;
}

export interface DeleteTeamRequest {
    happeningId: string;
    teamId: string;
    version: string;
}

export interface DownloadHappeningParticipantsRequest {
    id: string;
    version: string;
}

export interface FavoriteHappeningRequest {
    id: string;
    version: string;
}

export interface GetHappeningRequest {
    id: string;
    version: string;
}

export interface GetHappeningCardRequest {
    id: string;
    version: string;
}

export interface GetHappeningsRequest {
    version: string;
    page?: number;
    itemsPerPage?: number;
    itemsToSkip?: number;
    projectMemberId?: string;
    participantId?: string;
    includeRegisteredHappenings?: boolean;
    sanitizedQuery?: string;
    registered?: FilterInclusion;
    purpose?: UsagePurpose;
    query?: string;
    areas?: Array<string>;
    groups?: Array<string>;
    states?: Array<HappeningState>;
    isInternal?: FilterInclusion;
    targetAges?: Array<number>;
    minAge?: number;
    maxAge?: number;
    fromPublicationDate?: Date;
    toPublicationDate?: Date;
    fromDate?: Date;
    toDate?: Date;
    fromTime?: Date;
    toTime?: Date;
    projectLeaders?: Array<string>;
    happeningTypes?: Array<string>;
    dayOfWeekList?: Array<DayOfWeek>;
    free?: FilterInclusion;
    full?: FilterInclusion;
    locationTypes?: Array<LocationType>;
    popular?: FilterInclusion;
    maxDistance?: number;
    onlyFavorites?: boolean;
    intendedHappenings?: Array<string>;
    privateHappenings?: FilterInclusion;
    externalHappenings?: FilterInclusion;
    clusters?: Array<string>;
    viewTopLeftLatitude?: number;
    viewTopLeftLongitude?: number;
    viewBottomRightLatitude?: number;
    viewBottomRightLongitude?: number;
    isAccessible?: FilterInclusion;
    targetGroups?: Array<UserSubset>;
    isClustered?: FilterInclusion;
    isOrganizedByPartner?: FilterInclusion;
    orderBy?: HappeningOrderBy;
    sortOrder?: SortOrder;
    isDescending?: boolean;
    groupClusters?: boolean;
    userLocationLatitude?: number;
    userLocationLongitude?: number;
    userPostalCode?: string;
}

export interface GetMapHappeningsRequest {
    version: string;
    projectMemberId?: string;
    participantId?: string;
    includeRegisteredHappenings?: boolean;
    sanitizedQuery?: string;
    registered?: FilterInclusion;
    purpose?: UsagePurpose;
    query?: string;
    areas?: Array<string>;
    groups?: Array<string>;
    states?: Array<HappeningState>;
    isInternal?: FilterInclusion;
    targetAges?: Array<number>;
    minAge?: number;
    maxAge?: number;
    fromPublicationDate?: Date;
    toPublicationDate?: Date;
    fromDate?: Date;
    toDate?: Date;
    fromTime?: Date;
    toTime?: Date;
    projectLeaders?: Array<string>;
    happeningTypes?: Array<string>;
    dayOfWeekList?: Array<DayOfWeek>;
    free?: FilterInclusion;
    full?: FilterInclusion;
    locationTypes?: Array<LocationType>;
    popular?: FilterInclusion;
    maxDistance?: number;
    onlyFavorites?: boolean;
    intendedHappenings?: Array<string>;
    privateHappenings?: FilterInclusion;
    externalHappenings?: FilterInclusion;
    clusters?: Array<string>;
    viewTopLeftLatitude?: number;
    viewTopLeftLongitude?: number;
    viewBottomRightLatitude?: number;
    viewBottomRightLongitude?: number;
    isAccessible?: FilterInclusion;
    targetGroups?: Array<UserSubset>;
    isClustered?: FilterInclusion;
    isOrganizedByPartner?: FilterInclusion;
    userLocationLatitude?: number;
    userLocationLongitude?: number;
    userPostalCode?: string;
}

export interface GetMyHappeningsRequest {
    version: string;
    page?: number;
    itemsPerPage?: number;
    itemsToSkip?: number;
    purpose?: UsagePurpose;
    query?: string;
    areas?: Array<string>;
    groups?: Array<string>;
    states?: Array<HappeningState>;
    isInternal?: FilterInclusion;
    targetAges?: Array<number>;
    minAge?: number;
    maxAge?: number;
    fromPublicationDate?: Date;
    toPublicationDate?: Date;
    fromDate?: Date;
    toDate?: Date;
    fromTime?: Date;
    toTime?: Date;
    projectLeaders?: Array<string>;
    happeningTypes?: Array<string>;
    dayOfWeekList?: Array<DayOfWeek>;
    free?: FilterInclusion;
    full?: FilterInclusion;
    locationTypes?: Array<LocationType>;
    popular?: FilterInclusion;
    maxDistance?: number;
    onlyFavorites?: boolean;
    intendedHappenings?: Array<string>;
    privateHappenings?: FilterInclusion;
    externalHappenings?: FilterInclusion;
    clusters?: Array<string>;
    viewTopLeftLatitude?: number;
    viewTopLeftLongitude?: number;
    viewBottomRightLatitude?: number;
    viewBottomRightLongitude?: number;
    isAccessible?: FilterInclusion;
    targetGroups?: Array<UserSubset>;
    isClustered?: FilterInclusion;
    isOrganizedByPartner?: FilterInclusion;
    orderBy?: HappeningOrderBy;
    sortOrder?: SortOrder;
    isDescending?: boolean;
    groupClusters?: boolean;
    userLocationLatitude?: number;
    userLocationLongitude?: number;
    userPostalCode?: string;
}

export interface GetMyMapHappeningsRequest {
    version: string;
    purpose?: UsagePurpose;
    query?: string;
    areas?: Array<string>;
    groups?: Array<string>;
    states?: Array<HappeningState>;
    isInternal?: FilterInclusion;
    targetAges?: Array<number>;
    minAge?: number;
    maxAge?: number;
    fromPublicationDate?: Date;
    toPublicationDate?: Date;
    fromDate?: Date;
    toDate?: Date;
    fromTime?: Date;
    toTime?: Date;
    projectLeaders?: Array<string>;
    happeningTypes?: Array<string>;
    dayOfWeekList?: Array<DayOfWeek>;
    free?: FilterInclusion;
    full?: FilterInclusion;
    locationTypes?: Array<LocationType>;
    popular?: FilterInclusion;
    maxDistance?: number;
    onlyFavorites?: boolean;
    intendedHappenings?: Array<string>;
    privateHappenings?: FilterInclusion;
    externalHappenings?: FilterInclusion;
    clusters?: Array<string>;
    viewTopLeftLatitude?: number;
    viewTopLeftLongitude?: number;
    viewBottomRightLatitude?: number;
    viewBottomRightLongitude?: number;
    isAccessible?: FilterInclusion;
    targetGroups?: Array<UserSubset>;
    isClustered?: FilterInclusion;
    isOrganizedByPartner?: FilterInclusion;
    userLocationLatitude?: number;
    userLocationLongitude?: number;
    userPostalCode?: string;
}

export interface GetTeamRequest {
    happeningId: string;
    teamId: string;
    version: string;
}

export interface MoveRegistrationToTeamRequest {
    happeningId: string;
    teamId: string;
    registrationId: string;
    version: string;
}

export interface OpenHappeningRequest {
    id: string;
    version: string;
    target?: UpdateTarget;
}

export interface ReconceptualizeHappeningRequest {
    id: string;
    version: string;
}

export interface RejectHappeningRequest {
    id: string;
    version: string;
    reason: string;
}

export interface RemoveSelfFromNotifyMeListRequest {
    id: string;
    version: string;
}

export interface RemoveUserFromNotifyMeListRequest {
    id: string;
    userId: string;
    version: string;
}

export interface UpdateHappeningRequest {
    id: string;
    version: string;
    happeningType: HappeningType;
    title: string;
    restrictAge: boolean;
    state: HappeningState;
    isOnline: boolean;
    requiresApprovalFromMarketing: boolean;
    description?: string;
    link?: string;
    linkLabel?: string;
    targetGroup?: UserSubset;
    targetOrganisations?: Array<string>;
    minAge?: number;
    maxAge?: number;
    schools?: Array<string>;
    askForSchool?: boolean;
    publicationDate?: Date;
    tags?: Array<string>;
    tagInputs?: Array<HappeningTagInput>;
    infoLevel?: InfoLevel;
    maxNumberOfRegistrations?: number;
    locationType?: LocationType;
    location?: Array<LocationInputModel>;
    contact?: string;
    projectLeader?: string;
    projectCoLeaders?: Array<string>;
    projectMembers?: Array<string>;
    price?: number;
    images?: Array<number>;
    videos?: Array<Video>;
    isAccessible?: boolean;
    allowedPaymentMethods?: PaymentMethod;
    organizer?: string;
    cluster?: string;
    happeningRegistrationType?: HappeningRegistrationType;
    requiresRegistration?: boolean;
    minPeoplePerGroup?: number;
    maxPeoplePerGroup?: number;
    canOnlyRegisterAsGroup?: boolean;
    priceForIndividual?: number;
    isProRata?: boolean;
    timeAndDates?: Array<HappeningTimeAndDateInput>;
    recurrence?: Array<RecurrenceInput>;
    updateTarget?: UpdateTarget;
    extraQuestions?: Array<QuestionInput>;
    registrationStartDate?: Date;
    registrationEndDate?: Date;
    durationInMinutes?: number;
}

export interface UpdateTeamNameRequest {
    happeningId: string;
    teamId: string;
    version: string;
    body?: string;
}

/**
 * no description
 */
export class HappeningsApi extends runtime.BaseAPI {
    /**
     */
    async addImageToHappeningRaw(
        requestParameters: AddImageToHappeningRequest,
    ): Promise<runtime.ApiResponse<HappeningViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling addImageToHappening.",
            );
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling addImageToHappening.",
            );
        }
        if (requestParameters.altText === null || requestParameters.altText === undefined) {
            throw new runtime.RequiredError(
                "altText",
                "Required parameter requestParameters.altText was null or undefined when calling addImageToHappening.",
            );
        }
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError(
                "file",
                "Required parameter requestParameters.file was null or undefined when calling addImageToHappening.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.altText !== undefined) {
            formParams.append("AltText", requestParameters.altText as any);
            hasParams = true;
        }
        if (requestParameters.file !== undefined) {
            formParams.append("File", requestParameters.file as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Happenings/{id}/images`
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version)))
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HappeningViewModelFromJSON(jsonValue));
    }
    /**
     */
    async addImageToHappening(requestParameters: AddImageToHappeningRequest): Promise<HappeningViewModel> {
        const response = await this.addImageToHappeningRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async addSelfToNotifyMeListRaw(
        requestParameters: AddSelfToNotifyMeListRequest,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling addSelfToNotifyMeList.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling addSelfToNotifyMeList.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Happenings/{id}/notifyMe`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async addSelfToNotifyMeList(requestParameters: AddSelfToNotifyMeListRequest): Promise<void> {
        await this.addSelfToNotifyMeListRaw(requestParameters);
    }
    /**
     */
    async addUserToNotifyMeListRaw(
        requestParameters: AddUserToNotifyMeListRequest,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling addUserToNotifyMeList.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling addUserToNotifyMeList.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.profileIds) {
            requestParameters.profileIds.forEach((element, index) => {
                appendToParams(formParams, `ProfileIds[${index}]`, element);
                hasParams = true;
            });
        }
        const response = await this.request({
            path: `/v{version}/Happenings/{id}/notifyUser`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async addUserToNotifyMeList(requestParameters: AddUserToNotifyMeListRequest): Promise<void> {
        await this.addUserToNotifyMeListRaw(requestParameters);
    }
    /**
     */
    async archiveHappeningRaw(requestParameters: ArchiveHappeningRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling archiveHappening.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling archiveHappening.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.target !== undefined) {
            queryParameters["target"] = requestParameters.target;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Happenings/{id}/archive`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.TextApiResponse(response) as any;
    }
    /**
     */
    async archiveHappening(requestParameters: ArchiveHappeningRequest): Promise<string> {
        const response = await this.archiveHappeningRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async cancelHappeningRaw(requestParameters: CancelHappeningRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling cancelHappening.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling cancelHappening.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.dateTimes) {
            requestParameters.dateTimes.forEach((element, index) => {
                appendToParams(formParams, `DateTimes[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.reasonForCancellation !== undefined) {
            formParams.append("ReasonForCancellation", requestParameters.reasonForCancellation as any);
            hasParams = true;
        }
        if (requestParameters.reason !== undefined) {
            formParams.append("Reason", requestParameters.reason as any);
            hasParams = true;
        }
        if (requestParameters.target !== undefined) {
            formParams.append("Target", requestParameters.target as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Happenings/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.TextApiResponse(response) as any;
    }
    /**
     */
    async cancelHappening(requestParameters: CancelHappeningRequest): Promise<string> {
        const response = await this.cancelHappeningRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async createHappeningRaw(
        requestParameters: CreateHappeningRequest,
    ): Promise<runtime.ApiResponse<HappeningViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling createHappening.",
            );
        }
        if (requestParameters.happeningType === null || requestParameters.happeningType === undefined) {
            throw new runtime.RequiredError(
                "happeningType",
                "Required parameter requestParameters.happeningType was null or undefined when calling createHappening.",
            );
        }
        if (requestParameters.title === null || requestParameters.title === undefined) {
            throw new runtime.RequiredError(
                "title",
                "Required parameter requestParameters.title was null or undefined when calling createHappening.",
            );
        }
        if (requestParameters.restrictAge === null || requestParameters.restrictAge === undefined) {
            throw new runtime.RequiredError(
                "restrictAge",
                "Required parameter requestParameters.restrictAge was null or undefined when calling createHappening.",
            );
        }
        if (requestParameters.state === null || requestParameters.state === undefined) {
            throw new runtime.RequiredError(
                "state",
                "Required parameter requestParameters.state was null or undefined when calling createHappening.",
            );
        }
        if (requestParameters.isOnline === null || requestParameters.isOnline === undefined) {
            throw new runtime.RequiredError(
                "isOnline",
                "Required parameter requestParameters.isOnline was null or undefined when calling createHappening.",
            );
        }
        if (
            requestParameters.requiresApprovalFromMarketing === null ||
            requestParameters.requiresApprovalFromMarketing === undefined
        ) {
            throw new runtime.RequiredError(
                "requiresApprovalFromMarketing",
                "Required parameter requestParameters.requiresApprovalFromMarketing was null or undefined when calling createHappening.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.happeningType !== undefined) {
            formParams.append("HappeningType", requestParameters.happeningType as any);
            hasParams = true;
        }
        if (requestParameters.title !== undefined) {
            formParams.append("Title", requestParameters.title as any);
            hasParams = true;
        }
        if (requestParameters.description !== undefined) {
            formParams.append("Description", requestParameters.description as any);
            hasParams = true;
        }
        if (requestParameters.link !== undefined) {
            formParams.append("Link", requestParameters.link as any);
            hasParams = true;
        }
        if (requestParameters.linkLabel !== undefined) {
            formParams.append("LinkLabel", requestParameters.linkLabel as any);
            hasParams = true;
        }
        if (requestParameters.targetGroup !== undefined) {
            formParams.append("TargetGroup", requestParameters.targetGroup as any);
            hasParams = true;
        }
        if (requestParameters.targetOrganisations) {
            requestParameters.targetOrganisations.forEach((element, index) => {
                appendToParams(formParams, `TargetOrganisations[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.minAge !== undefined) {
            formParams.append("MinAge", requestParameters.minAge as any);
            hasParams = true;
        }
        if (requestParameters.maxAge !== undefined) {
            formParams.append("MaxAge", requestParameters.maxAge as any);
            hasParams = true;
        }
        if (requestParameters.restrictAge !== undefined) {
            formParams.append("RestrictAge", requestParameters.restrictAge as any);
            hasParams = true;
        }
        if (requestParameters.schools) {
            requestParameters.schools.forEach((element, index) => {
                appendToParams(formParams, `Schools[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.askForSchool !== undefined) {
            formParams.append("AskForSchool", requestParameters.askForSchool as any);
            hasParams = true;
        }
        if (requestParameters.state !== undefined) {
            formParams.append("State", requestParameters.state as any);
            hasParams = true;
        }
        if (requestParameters.publicationDate !== undefined) {
            formParams.append("PublicationDate", (requestParameters.publicationDate as any).toUTCString());
            hasParams = true;
        }
        if (requestParameters.tags) {
            requestParameters.tags.forEach((element, index) => {
                appendToParams(formParams, `Tags[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.tagInputs) {
            requestParameters.tagInputs.forEach((element, index) => {
                appendToParams(formParams, `TagInputs[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.infoLevel !== undefined) {
            formParams.append("InfoLevel", requestParameters.infoLevel as any);
            hasParams = true;
        }
        if (requestParameters.maxNumberOfRegistrations !== undefined) {
            formParams.append("MaxNumberOfRegistrations", requestParameters.maxNumberOfRegistrations as any);
            hasParams = true;
        }
        if (requestParameters.isOnline !== undefined) {
            formParams.append("IsOnline", requestParameters.isOnline as any);
            hasParams = true;
        }
        if (requestParameters.locationType !== undefined) {
            formParams.append("LocationType", requestParameters.locationType as any);
            hasParams = true;
        }
        if (requestParameters.location) {
            requestParameters.location.forEach((element, index) => {
                appendToParams(formParams, `Location[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.contact !== undefined) {
            formParams.append("Contact", requestParameters.contact as any);
            hasParams = true;
        }
        if (requestParameters.projectLeader !== undefined) {
            formParams.append("ProjectLeader", requestParameters.projectLeader as any);
            hasParams = true;
        }
        if (requestParameters.projectCoLeaders) {
            requestParameters.projectCoLeaders.forEach((element, index) => {
                appendToParams(formParams, `ProjectCoLeaders[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.projectMembers) {
            requestParameters.projectMembers.forEach((element, index) => {
                appendToParams(formParams, `ProjectMembers[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.price !== undefined) {
            formParams.append("Price", requestParameters.price as any);
            hasParams = true;
        }
        if (requestParameters.images) {
            requestParameters.images.forEach((element, index) => {
                appendToParams(formParams, `Images[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.videos) {
            requestParameters.videos.forEach((element, index) => {
                appendToParams(formParams, `Videos[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.requiresApprovalFromMarketing !== undefined) {
            formParams.append("RequiresApprovalFromMarketing", requestParameters.requiresApprovalFromMarketing as any);
            hasParams = true;
        }
        if (requestParameters.isAccessible !== undefined) {
            formParams.append("IsAccessible", requestParameters.isAccessible as any);
            hasParams = true;
        }
        if (requestParameters.allowedPaymentMethods !== undefined) {
            formParams.append("AllowedPaymentMethods", requestParameters.allowedPaymentMethods as any);
            hasParams = true;
        }
        if (requestParameters.organizer !== undefined) {
            formParams.append("Organizer", requestParameters.organizer as any);
            hasParams = true;
        }
        if (requestParameters.cluster !== undefined) {
            formParams.append("Cluster", requestParameters.cluster as any);
            hasParams = true;
        }
        if (requestParameters.happeningRegistrationType !== undefined) {
            formParams.append("HappeningRegistrationType", requestParameters.happeningRegistrationType as any);
            hasParams = true;
        }
        if (requestParameters.requiresRegistration !== undefined) {
            formParams.append("RequiresRegistration", requestParameters.requiresRegistration as any);
            hasParams = true;
        }
        if (requestParameters.minPeoplePerGroup !== undefined) {
            formParams.append("MinPeoplePerGroup", requestParameters.minPeoplePerGroup as any);
            hasParams = true;
        }
        if (requestParameters.maxPeoplePerGroup !== undefined) {
            formParams.append("MaxPeoplePerGroup", requestParameters.maxPeoplePerGroup as any);
            hasParams = true;
        }
        if (requestParameters.canOnlyRegisterAsGroup !== undefined) {
            formParams.append("CanOnlyRegisterAsGroup", requestParameters.canOnlyRegisterAsGroup as any);
            hasParams = true;
        }
        if (requestParameters.priceForIndividual !== undefined) {
            formParams.append("PriceForIndividual", requestParameters.priceForIndividual as any);
            hasParams = true;
        }
        if (requestParameters.isProRata !== undefined) {
            formParams.append("IsProRata", requestParameters.isProRata as any);
            hasParams = true;
        }
        if (requestParameters.timeAndDates) {
            requestParameters.timeAndDates.forEach((element, index) => {
                appendToParams(formParams, `TimeAndDates[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.recurrence) {
            requestParameters.recurrence.forEach((element, index) => {
                appendToParams(formParams, `Recurrence[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.updateTarget !== undefined) {
            formParams.append("UpdateTarget", requestParameters.updateTarget as any);
            hasParams = true;
        }
        if (requestParameters.extraQuestions) {
            requestParameters.extraQuestions.forEach((element, index) => {
                appendToParams(formParams, `ExtraQuestions[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.registrationStartDate !== undefined) {
            formParams.append("RegistrationStartDate", (requestParameters.registrationStartDate as any).toUTCString());
            hasParams = true;
        }
        if (requestParameters.registrationEndDate !== undefined) {
            formParams.append("RegistrationEndDate", (requestParameters.registrationEndDate as any).toUTCString());
            hasParams = true;
        }
        if (requestParameters.durationInMinutes !== undefined) {
            formParams.append("DurationInMinutes", requestParameters.durationInMinutes as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Happenings`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HappeningViewModelFromJSON(jsonValue));
    }
    /**
     */
    async createHappening(requestParameters: CreateHappeningRequest): Promise<HappeningViewModel> {
        const response = await this.createHappeningRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async createTeamRaw(requestParameters: CreateTeamRequest): Promise<runtime.ApiResponse<TeamViewModel>> {
        if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
            throw new runtime.RequiredError(
                "happeningId",
                "Required parameter requestParameters.happeningId was null or undefined when calling createTeam.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling createTeam.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters["Content-Type"] = "application/json-patch+json";
        const response = await this.request({
            path: `/v{version}/Happenings/{happeningId}/team`
                .replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => TeamViewModelFromJSON(jsonValue));
    }
    /**
     */
    async createTeam(requestParameters: CreateTeamRequest): Promise<TeamViewModel> {
        const response = await this.createTeamRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async deleteHappeningRaw(
        requestParameters: DeleteHappeningRequest,
    ): Promise<runtime.ApiResponse<HappeningViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling deleteHappening.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling deleteHappening.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Happenings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HappeningViewModelFromJSON(jsonValue));
    }
    /**
     */
    async deleteHappening(requestParameters: DeleteHappeningRequest): Promise<HappeningViewModel> {
        const response = await this.deleteHappeningRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async deleteTeamRaw(requestParameters: DeleteTeamRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
            throw new runtime.RequiredError(
                "happeningId",
                "Required parameter requestParameters.happeningId was null or undefined when calling deleteTeam.",
            );
        }
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError(
                "teamId",
                "Required parameter requestParameters.teamId was null or undefined when calling deleteTeam.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling deleteTeam.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Happenings/{happeningId}/team/{teamId}`
                .replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId)))
                .replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteTeam(requestParameters: DeleteTeamRequest): Promise<void> {
        await this.deleteTeamRaw(requestParameters);
    }
    /**
     */
    async downloadHappeningParticipantsRaw(
        requestParameters: DownloadHappeningParticipantsRequest,
    ): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling downloadHappeningParticipants.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling downloadHappeningParticipants.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Happenings/{id}/download-participants`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse<any>(response);
    }
    /**
     */
    async downloadHappeningParticipants(requestParameters: DownloadHappeningParticipantsRequest): Promise<object> {
        const response = await this.downloadHappeningParticipantsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async favoriteHappeningRaw(
        requestParameters: FavoriteHappeningRequest,
    ): Promise<runtime.ApiResponse<FavoriteOutput>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling favoriteHappening.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling favoriteHappening.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Happenings/{id}/favorite`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => FavoriteOutputFromJSON(jsonValue));
    }
    /**
     */
    async favoriteHappening(requestParameters: FavoriteHappeningRequest): Promise<FavoriteOutput> {
        const response = await this.favoriteHappeningRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getHappeningRaw(requestParameters: GetHappeningRequest): Promise<runtime.ApiResponse<HappeningViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling getHappening.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getHappening.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Happenings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HappeningViewModelFromJSON(jsonValue));
    }
    /**
     */
    async getHappening(requestParameters: GetHappeningRequest): Promise<HappeningViewModel> {
        const response = await this.getHappeningRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getHappeningCardRaw(
        requestParameters: GetHappeningCardRequest,
    ): Promise<runtime.ApiResponse<HappeningListItemViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling getHappeningCard.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getHappeningCard.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Happenings/{id}/mini`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HappeningListItemViewModelFromJSON(jsonValue));
    }
    /**
     */
    async getHappeningCard(requestParameters: GetHappeningCardRequest): Promise<HappeningListItemViewModel> {
        const response = await this.getHappeningCardRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getHappeningsRaw(
        requestParameters: GetHappeningsRequest,
    ): Promise<runtime.ApiResponse<HappeningListItemViewModelPaginatedViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getHappenings.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.page !== undefined) {
            queryParameters["Page"] = requestParameters.page;
        }
        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters["ItemsPerPage"] = requestParameters.itemsPerPage;
        }
        if (requestParameters.itemsToSkip !== undefined) {
            queryParameters["ItemsToSkip"] = requestParameters.itemsToSkip;
        }
        if (requestParameters.projectMemberId !== undefined) {
            queryParameters["ProjectMemberId"] = requestParameters.projectMemberId;
        }
        if (requestParameters.participantId !== undefined) {
            queryParameters["ParticipantId"] = requestParameters.participantId;
        }
        if (requestParameters.includeRegisteredHappenings !== undefined) {
            queryParameters["IncludeRegisteredHappenings"] = requestParameters.includeRegisteredHappenings;
        }
        if (requestParameters.sanitizedQuery !== undefined) {
            queryParameters["SanitizedQuery"] = requestParameters.sanitizedQuery;
        }
        if (requestParameters.registered !== undefined) {
            queryParameters["Registered"] = requestParameters.registered;
        }
        if (requestParameters.purpose !== undefined) {
            queryParameters["Purpose"] = requestParameters.purpose;
        }
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        if (requestParameters.areas && requestParameters.areas.length > 0) {
            queryParameters["Areas"] = requestParameters.areas;
        }
        if (requestParameters.groups && requestParameters.groups.length > 0) {
            queryParameters["Groups"] = requestParameters.groups;
        }
        if (requestParameters.states && requestParameters.states.length > 0) {
            queryParameters["States"] = requestParameters.states;
        }
        if (requestParameters.isInternal !== undefined) {
            queryParameters["IsInternal"] = requestParameters.isInternal;
        }
        if (requestParameters.targetAges && requestParameters.targetAges.length > 0) {
            queryParameters["TargetAges"] = requestParameters.targetAges;
        }
        if (requestParameters.minAge !== undefined) {
            queryParameters["MinAge"] = requestParameters.minAge;
        }
        if (requestParameters.maxAge !== undefined) {
            queryParameters["MaxAge"] = requestParameters.maxAge;
        }
        if (requestParameters.fromPublicationDate !== undefined) {
            queryParameters["FromPublicationDate"] = (requestParameters.fromPublicationDate as any).toUTCString();
        }
        if (requestParameters.toPublicationDate !== undefined) {
            queryParameters["ToPublicationDate"] = (requestParameters.toPublicationDate as any).toUTCString();
        }
        if (requestParameters.fromDate !== undefined) {
            queryParameters["FromDate"] = (requestParameters.fromDate as any).toUTCString();
        }
        if (requestParameters.toDate !== undefined) {
            queryParameters["ToDate"] = (requestParameters.toDate as any).toUTCString();
        }
        if (requestParameters.fromTime !== undefined) {
            queryParameters["FromTime"] = (requestParameters.fromTime as any).toUTCString();
        }
        if (requestParameters.toTime !== undefined) {
            queryParameters["ToTime"] = (requestParameters.toTime as any).toUTCString();
        }
        if (requestParameters.projectLeaders && requestParameters.projectLeaders.length > 0) {
            queryParameters["ProjectLeaders"] = requestParameters.projectLeaders;
        }
        if (requestParameters.happeningTypes && requestParameters.happeningTypes.length > 0) {
            queryParameters["HappeningTypes"] = requestParameters.happeningTypes;
        }
        if (requestParameters.dayOfWeekList && requestParameters.dayOfWeekList.length > 0) {
            queryParameters["DayOfWeekList"] = requestParameters.dayOfWeekList;
        }
        if (requestParameters.free !== undefined) {
            queryParameters["Free"] = requestParameters.free;
        }
        if (requestParameters.full !== undefined) {
            queryParameters["Full"] = requestParameters.full;
        }
        if (requestParameters.locationTypes && requestParameters.locationTypes.length > 0) {
            queryParameters["LocationTypes"] = requestParameters.locationTypes;
        }
        if (requestParameters.popular !== undefined) {
            queryParameters["Popular"] = requestParameters.popular;
        }
        if (requestParameters.maxDistance !== undefined) {
            queryParameters["MaxDistance"] = requestParameters.maxDistance;
        }
        if (requestParameters.onlyFavorites !== undefined) {
            queryParameters["OnlyFavorites"] = requestParameters.onlyFavorites;
        }
        if (requestParameters.intendedHappenings && requestParameters.intendedHappenings.length > 0) {
            queryParameters["IntendedHappenings"] = requestParameters.intendedHappenings;
        }
        if (requestParameters.privateHappenings !== undefined) {
            queryParameters["PrivateHappenings"] = requestParameters.privateHappenings;
        }
        if (requestParameters.externalHappenings !== undefined) {
            queryParameters["ExternalHappenings"] = requestParameters.externalHappenings;
        }
        if (requestParameters.clusters && requestParameters.clusters.length > 0) {
            queryParameters["Clusters"] = requestParameters.clusters;
        }
        if (requestParameters.viewTopLeftLatitude !== undefined) {
            queryParameters["ViewTopLeft.Latitude"] = requestParameters.viewTopLeftLatitude;
        }
        if (requestParameters.viewTopLeftLongitude !== undefined) {
            queryParameters["ViewTopLeft.Longitude"] = requestParameters.viewTopLeftLongitude;
        }
        if (requestParameters.viewBottomRightLatitude !== undefined) {
            queryParameters["ViewBottomRight.Latitude"] = requestParameters.viewBottomRightLatitude;
        }
        if (requestParameters.viewBottomRightLongitude !== undefined) {
            queryParameters["ViewBottomRight.Longitude"] = requestParameters.viewBottomRightLongitude;
        }
        if (requestParameters.isAccessible !== undefined) {
            queryParameters["IsAccessible"] = requestParameters.isAccessible;
        }
        if (requestParameters.targetGroups && requestParameters.targetGroups.length > 0) {
            queryParameters["TargetGroups"] = requestParameters.targetGroups;
        }
        if (requestParameters.isClustered !== undefined) {
            queryParameters["IsClustered"] = requestParameters.isClustered;
        }
        if (requestParameters.isOrganizedByPartner !== undefined) {
            queryParameters["IsOrganizedByPartner"] = requestParameters.isOrganizedByPartner;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters["OrderBy"] = requestParameters.orderBy;
        }
        if (requestParameters.sortOrder !== undefined) {
            queryParameters["SortOrder"] = requestParameters.sortOrder;
        }
        if (requestParameters.isDescending !== undefined) {
            queryParameters["IsDescending"] = requestParameters.isDescending;
        }
        if (requestParameters.groupClusters !== undefined) {
            queryParameters["GroupClusters"] = requestParameters.groupClusters;
        }
        if (requestParameters.userLocationLatitude !== undefined) {
            queryParameters["UserLocation.Latitude"] = requestParameters.userLocationLatitude;
        }
        if (requestParameters.userLocationLongitude !== undefined) {
            queryParameters["UserLocation.Longitude"] = requestParameters.userLocationLongitude;
        }
        if (requestParameters.userPostalCode !== undefined) {
            queryParameters["UserPostalCode"] = requestParameters.userPostalCode;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Happenings`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            HappeningListItemViewModelPaginatedViewModelFromJSON(jsonValue),
        );
    }
    /**
     */
    async getHappenings(
        requestParameters: GetHappeningsRequest,
    ): Promise<HappeningListItemViewModelPaginatedViewModel> {
        const response = await this.getHappeningsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getMapHappeningsRaw(
        requestParameters: GetMapHappeningsRequest,
    ): Promise<runtime.ApiResponse<Array<MapItemViewModel>>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getMapHappenings.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.projectMemberId !== undefined) {
            queryParameters["ProjectMemberId"] = requestParameters.projectMemberId;
        }
        if (requestParameters.participantId !== undefined) {
            queryParameters["ParticipantId"] = requestParameters.participantId;
        }
        if (requestParameters.includeRegisteredHappenings !== undefined) {
            queryParameters["IncludeRegisteredHappenings"] = requestParameters.includeRegisteredHappenings;
        }
        if (requestParameters.sanitizedQuery !== undefined) {
            queryParameters["SanitizedQuery"] = requestParameters.sanitizedQuery;
        }
        if (requestParameters.registered !== undefined) {
            queryParameters["Registered"] = requestParameters.registered;
        }
        if (requestParameters.purpose !== undefined) {
            queryParameters["Purpose"] = requestParameters.purpose;
        }
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        if (requestParameters.areas && requestParameters.areas.length > 0) {
            queryParameters["Areas"] = requestParameters.areas;
        }
        if (requestParameters.groups && requestParameters.groups.length > 0) {
            queryParameters["Groups"] = requestParameters.groups;
        }
        if (requestParameters.states && requestParameters.states.length > 0) {
            queryParameters["States"] = requestParameters.states;
        }
        if (requestParameters.isInternal !== undefined) {
            queryParameters["IsInternal"] = requestParameters.isInternal;
        }
        if (requestParameters.targetAges && requestParameters.targetAges.length > 0) {
            queryParameters["TargetAges"] = requestParameters.targetAges;
        }
        if (requestParameters.minAge !== undefined) {
            queryParameters["MinAge"] = requestParameters.minAge;
        }
        if (requestParameters.maxAge !== undefined) {
            queryParameters["MaxAge"] = requestParameters.maxAge;
        }
        if (requestParameters.fromPublicationDate !== undefined) {
            queryParameters["FromPublicationDate"] = (requestParameters.fromPublicationDate as any).toUTCString();
        }
        if (requestParameters.toPublicationDate !== undefined) {
            queryParameters["ToPublicationDate"] = (requestParameters.toPublicationDate as any).toUTCString();
        }
        if (requestParameters.fromDate !== undefined) {
            queryParameters["FromDate"] = (requestParameters.fromDate as any).toUTCString();
        }
        if (requestParameters.toDate !== undefined) {
            queryParameters["ToDate"] = (requestParameters.toDate as any).toUTCString();
        }
        if (requestParameters.fromTime !== undefined) {
            queryParameters["FromTime"] = (requestParameters.fromTime as any).toUTCString();
        }
        if (requestParameters.toTime !== undefined) {
            queryParameters["ToTime"] = (requestParameters.toTime as any).toUTCString();
        }
        if (requestParameters.projectLeaders && requestParameters.projectLeaders.length > 0) {
            queryParameters["ProjectLeaders"] = requestParameters.projectLeaders;
        }
        if (requestParameters.happeningTypes && requestParameters.happeningTypes.length > 0) {
            queryParameters["HappeningTypes"] = requestParameters.happeningTypes;
        }
        if (requestParameters.dayOfWeekList && requestParameters.dayOfWeekList.length > 0) {
            queryParameters["DayOfWeekList"] = requestParameters.dayOfWeekList;
        }
        if (requestParameters.free !== undefined) {
            queryParameters["Free"] = requestParameters.free;
        }
        if (requestParameters.full !== undefined) {
            queryParameters["Full"] = requestParameters.full;
        }
        if (requestParameters.locationTypes && requestParameters.locationTypes.length > 0) {
            queryParameters["LocationTypes"] = requestParameters.locationTypes;
        }
        if (requestParameters.popular !== undefined) {
            queryParameters["Popular"] = requestParameters.popular;
        }
        if (requestParameters.maxDistance !== undefined) {
            queryParameters["MaxDistance"] = requestParameters.maxDistance;
        }
        if (requestParameters.onlyFavorites !== undefined) {
            queryParameters["OnlyFavorites"] = requestParameters.onlyFavorites;
        }
        if (requestParameters.intendedHappenings && requestParameters.intendedHappenings.length > 0) {
            queryParameters["IntendedHappenings"] = requestParameters.intendedHappenings;
        }
        if (requestParameters.privateHappenings !== undefined) {
            queryParameters["PrivateHappenings"] = requestParameters.privateHappenings;
        }
        if (requestParameters.externalHappenings !== undefined) {
            queryParameters["ExternalHappenings"] = requestParameters.externalHappenings;
        }
        if (requestParameters.clusters && requestParameters.clusters.length > 0) {
            queryParameters["Clusters"] = requestParameters.clusters;
        }
        if (requestParameters.viewTopLeftLatitude !== undefined) {
            queryParameters["ViewTopLeft.Latitude"] = requestParameters.viewTopLeftLatitude;
        }
        if (requestParameters.viewTopLeftLongitude !== undefined) {
            queryParameters["ViewTopLeft.Longitude"] = requestParameters.viewTopLeftLongitude;
        }
        if (requestParameters.viewBottomRightLatitude !== undefined) {
            queryParameters["ViewBottomRight.Latitude"] = requestParameters.viewBottomRightLatitude;
        }
        if (requestParameters.viewBottomRightLongitude !== undefined) {
            queryParameters["ViewBottomRight.Longitude"] = requestParameters.viewBottomRightLongitude;
        }
        if (requestParameters.isAccessible !== undefined) {
            queryParameters["IsAccessible"] = requestParameters.isAccessible;
        }
        if (requestParameters.targetGroups && requestParameters.targetGroups.length > 0) {
            queryParameters["TargetGroups"] = requestParameters.targetGroups;
        }
        if (requestParameters.isClustered !== undefined) {
            queryParameters["IsClustered"] = requestParameters.isClustered;
        }
        if (requestParameters.isOrganizedByPartner !== undefined) {
            queryParameters["IsOrganizedByPartner"] = requestParameters.isOrganizedByPartner;
        }
        if (requestParameters.userLocationLatitude !== undefined) {
            queryParameters["UserLocation.Latitude"] = requestParameters.userLocationLatitude;
        }
        if (requestParameters.userLocationLongitude !== undefined) {
            queryParameters["UserLocation.Longitude"] = requestParameters.userLocationLongitude;
        }
        if (requestParameters.userPostalCode !== undefined) {
            queryParameters["UserPostalCode"] = requestParameters.userPostalCode;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Happenings/map`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MapItemViewModelFromJSON));
    }
    /**
     */
    async getMapHappenings(requestParameters: GetMapHappeningsRequest): Promise<Array<MapItemViewModel>> {
        const response = await this.getMapHappeningsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getMyHappeningsRaw(
        requestParameters: GetMyHappeningsRequest,
    ): Promise<runtime.ApiResponse<HappeningListItemViewModelPaginatedViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getMyHappenings.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.page !== undefined) {
            queryParameters["Page"] = requestParameters.page;
        }
        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters["ItemsPerPage"] = requestParameters.itemsPerPage;
        }
        if (requestParameters.itemsToSkip !== undefined) {
            queryParameters["ItemsToSkip"] = requestParameters.itemsToSkip;
        }
        if (requestParameters.purpose !== undefined) {
            queryParameters["Purpose"] = requestParameters.purpose;
        }
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        if (requestParameters.areas && requestParameters.areas.length > 0) {
            queryParameters["Areas"] = requestParameters.areas;
        }
        if (requestParameters.groups && requestParameters.groups.length > 0) {
            queryParameters["Groups"] = requestParameters.groups;
        }
        if (requestParameters.states && requestParameters.states.length > 0) {
            queryParameters["States"] = requestParameters.states;
        }
        if (requestParameters.isInternal !== undefined) {
            queryParameters["IsInternal"] = requestParameters.isInternal;
        }
        if (requestParameters.targetAges && requestParameters.targetAges.length > 0) {
            queryParameters["TargetAges"] = requestParameters.targetAges;
        }
        if (requestParameters.minAge !== undefined) {
            queryParameters["MinAge"] = requestParameters.minAge;
        }
        if (requestParameters.maxAge !== undefined) {
            queryParameters["MaxAge"] = requestParameters.maxAge;
        }
        if (requestParameters.fromPublicationDate !== undefined) {
            queryParameters["FromPublicationDate"] = (requestParameters.fromPublicationDate as any).toUTCString();
        }
        if (requestParameters.toPublicationDate !== undefined) {
            queryParameters["ToPublicationDate"] = (requestParameters.toPublicationDate as any).toUTCString();
        }
        if (requestParameters.fromDate !== undefined) {
            queryParameters["FromDate"] = (requestParameters.fromDate as any).toUTCString();
        }
        if (requestParameters.toDate !== undefined) {
            queryParameters["ToDate"] = (requestParameters.toDate as any).toUTCString();
        }
        if (requestParameters.fromTime !== undefined) {
            queryParameters["FromTime"] = (requestParameters.fromTime as any).toUTCString();
        }
        if (requestParameters.toTime !== undefined) {
            queryParameters["ToTime"] = (requestParameters.toTime as any).toUTCString();
        }
        if (requestParameters.projectLeaders && requestParameters.projectLeaders.length > 0) {
            queryParameters["ProjectLeaders"] = requestParameters.projectLeaders;
        }
        if (requestParameters.happeningTypes && requestParameters.happeningTypes.length > 0) {
            queryParameters["HappeningTypes"] = requestParameters.happeningTypes;
        }
        if (requestParameters.dayOfWeekList && requestParameters.dayOfWeekList.length > 0) {
            queryParameters["DayOfWeekList"] = requestParameters.dayOfWeekList;
        }
        if (requestParameters.free !== undefined) {
            queryParameters["Free"] = requestParameters.free;
        }
        if (requestParameters.full !== undefined) {
            queryParameters["Full"] = requestParameters.full;
        }
        if (requestParameters.locationTypes && requestParameters.locationTypes.length > 0) {
            queryParameters["LocationTypes"] = requestParameters.locationTypes;
        }
        if (requestParameters.popular !== undefined) {
            queryParameters["Popular"] = requestParameters.popular;
        }
        if (requestParameters.maxDistance !== undefined) {
            queryParameters["MaxDistance"] = requestParameters.maxDistance;
        }
        if (requestParameters.onlyFavorites !== undefined) {
            queryParameters["OnlyFavorites"] = requestParameters.onlyFavorites;
        }
        if (requestParameters.intendedHappenings && requestParameters.intendedHappenings.length > 0) {
            queryParameters["IntendedHappenings"] = requestParameters.intendedHappenings;
        }
        if (requestParameters.privateHappenings !== undefined) {
            queryParameters["PrivateHappenings"] = requestParameters.privateHappenings;
        }
        if (requestParameters.externalHappenings !== undefined) {
            queryParameters["ExternalHappenings"] = requestParameters.externalHappenings;
        }
        if (requestParameters.clusters && requestParameters.clusters.length > 0) {
            queryParameters["Clusters"] = requestParameters.clusters;
        }
        if (requestParameters.viewTopLeftLatitude !== undefined) {
            queryParameters["ViewTopLeft.Latitude"] = requestParameters.viewTopLeftLatitude;
        }
        if (requestParameters.viewTopLeftLongitude !== undefined) {
            queryParameters["ViewTopLeft.Longitude"] = requestParameters.viewTopLeftLongitude;
        }
        if (requestParameters.viewBottomRightLatitude !== undefined) {
            queryParameters["ViewBottomRight.Latitude"] = requestParameters.viewBottomRightLatitude;
        }
        if (requestParameters.viewBottomRightLongitude !== undefined) {
            queryParameters["ViewBottomRight.Longitude"] = requestParameters.viewBottomRightLongitude;
        }
        if (requestParameters.isAccessible !== undefined) {
            queryParameters["IsAccessible"] = requestParameters.isAccessible;
        }
        if (requestParameters.targetGroups && requestParameters.targetGroups.length > 0) {
            queryParameters["TargetGroups"] = requestParameters.targetGroups;
        }
        if (requestParameters.isClustered !== undefined) {
            queryParameters["IsClustered"] = requestParameters.isClustered;
        }
        if (requestParameters.isOrganizedByPartner !== undefined) {
            queryParameters["IsOrganizedByPartner"] = requestParameters.isOrganizedByPartner;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters["OrderBy"] = requestParameters.orderBy;
        }
        if (requestParameters.sortOrder !== undefined) {
            queryParameters["SortOrder"] = requestParameters.sortOrder;
        }
        if (requestParameters.isDescending !== undefined) {
            queryParameters["IsDescending"] = requestParameters.isDescending;
        }
        if (requestParameters.groupClusters !== undefined) {
            queryParameters["GroupClusters"] = requestParameters.groupClusters;
        }
        if (requestParameters.userLocationLatitude !== undefined) {
            queryParameters["UserLocation.Latitude"] = requestParameters.userLocationLatitude;
        }
        if (requestParameters.userLocationLongitude !== undefined) {
            queryParameters["UserLocation.Longitude"] = requestParameters.userLocationLongitude;
        }
        if (requestParameters.userPostalCode !== undefined) {
            queryParameters["UserPostalCode"] = requestParameters.userPostalCode;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Happenings/my`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            HappeningListItemViewModelPaginatedViewModelFromJSON(jsonValue),
        );
    }
    /**
     */
    async getMyHappenings(
        requestParameters: GetMyHappeningsRequest,
    ): Promise<HappeningListItemViewModelPaginatedViewModel> {
        const response = await this.getMyHappeningsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getMyMapHappeningsRaw(
        requestParameters: GetMyMapHappeningsRequest,
    ): Promise<runtime.ApiResponse<Array<MapItemViewModel>>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getMyMapHappenings.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.purpose !== undefined) {
            queryParameters["Purpose"] = requestParameters.purpose;
        }
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        if (requestParameters.areas && requestParameters.areas.length > 0) {
            queryParameters["Areas"] = requestParameters.areas;
        }
        if (requestParameters.groups && requestParameters.groups.length > 0) {
            queryParameters["Groups"] = requestParameters.groups;
        }
        if (requestParameters.states && requestParameters.states.length > 0) {
            queryParameters["States"] = requestParameters.states;
        }
        if (requestParameters.isInternal !== undefined) {
            queryParameters["IsInternal"] = requestParameters.isInternal;
        }
        if (requestParameters.targetAges && requestParameters.targetAges.length > 0) {
            queryParameters["TargetAges"] = requestParameters.targetAges;
        }
        if (requestParameters.minAge !== undefined) {
            queryParameters["MinAge"] = requestParameters.minAge;
        }
        if (requestParameters.maxAge !== undefined) {
            queryParameters["MaxAge"] = requestParameters.maxAge;
        }
        if (requestParameters.fromPublicationDate !== undefined) {
            queryParameters["FromPublicationDate"] = (requestParameters.fromPublicationDate as any).toUTCString();
        }
        if (requestParameters.toPublicationDate !== undefined) {
            queryParameters["ToPublicationDate"] = (requestParameters.toPublicationDate as any).toUTCString();
        }
        if (requestParameters.fromDate !== undefined) {
            queryParameters["FromDate"] = (requestParameters.fromDate as any).toUTCString();
        }
        if (requestParameters.toDate !== undefined) {
            queryParameters["ToDate"] = (requestParameters.toDate as any).toUTCString();
        }
        if (requestParameters.fromTime !== undefined) {
            queryParameters["FromTime"] = (requestParameters.fromTime as any).toUTCString();
        }
        if (requestParameters.toTime !== undefined) {
            queryParameters["ToTime"] = (requestParameters.toTime as any).toUTCString();
        }
        if (requestParameters.projectLeaders && requestParameters.projectLeaders.length > 0) {
            queryParameters["ProjectLeaders"] = requestParameters.projectLeaders;
        }
        if (requestParameters.happeningTypes && requestParameters.happeningTypes.length > 0) {
            queryParameters["HappeningTypes"] = requestParameters.happeningTypes;
        }
        if (requestParameters.dayOfWeekList && requestParameters.dayOfWeekList.length > 0) {
            queryParameters["DayOfWeekList"] = requestParameters.dayOfWeekList;
        }
        if (requestParameters.free !== undefined) {
            queryParameters["Free"] = requestParameters.free;
        }
        if (requestParameters.full !== undefined) {
            queryParameters["Full"] = requestParameters.full;
        }
        if (requestParameters.locationTypes && requestParameters.locationTypes.length > 0) {
            queryParameters["LocationTypes"] = requestParameters.locationTypes;
        }
        if (requestParameters.popular !== undefined) {
            queryParameters["Popular"] = requestParameters.popular;
        }
        if (requestParameters.maxDistance !== undefined) {
            queryParameters["MaxDistance"] = requestParameters.maxDistance;
        }
        if (requestParameters.onlyFavorites !== undefined) {
            queryParameters["OnlyFavorites"] = requestParameters.onlyFavorites;
        }
        if (requestParameters.intendedHappenings && requestParameters.intendedHappenings.length > 0) {
            queryParameters["IntendedHappenings"] = requestParameters.intendedHappenings;
        }
        if (requestParameters.privateHappenings !== undefined) {
            queryParameters["PrivateHappenings"] = requestParameters.privateHappenings;
        }
        if (requestParameters.externalHappenings !== undefined) {
            queryParameters["ExternalHappenings"] = requestParameters.externalHappenings;
        }
        if (requestParameters.clusters && requestParameters.clusters.length > 0) {
            queryParameters["Clusters"] = requestParameters.clusters;
        }
        if (requestParameters.viewTopLeftLatitude !== undefined) {
            queryParameters["ViewTopLeft.Latitude"] = requestParameters.viewTopLeftLatitude;
        }
        if (requestParameters.viewTopLeftLongitude !== undefined) {
            queryParameters["ViewTopLeft.Longitude"] = requestParameters.viewTopLeftLongitude;
        }
        if (requestParameters.viewBottomRightLatitude !== undefined) {
            queryParameters["ViewBottomRight.Latitude"] = requestParameters.viewBottomRightLatitude;
        }
        if (requestParameters.viewBottomRightLongitude !== undefined) {
            queryParameters["ViewBottomRight.Longitude"] = requestParameters.viewBottomRightLongitude;
        }
        if (requestParameters.isAccessible !== undefined) {
            queryParameters["IsAccessible"] = requestParameters.isAccessible;
        }
        if (requestParameters.targetGroups && requestParameters.targetGroups.length > 0) {
            queryParameters["TargetGroups"] = requestParameters.targetGroups;
        }
        if (requestParameters.isClustered !== undefined) {
            queryParameters["IsClustered"] = requestParameters.isClustered;
        }
        if (requestParameters.isOrganizedByPartner !== undefined) {
            queryParameters["IsOrganizedByPartner"] = requestParameters.isOrganizedByPartner;
        }
        if (requestParameters.userLocationLatitude !== undefined) {
            queryParameters["UserLocation.Latitude"] = requestParameters.userLocationLatitude;
        }
        if (requestParameters.userLocationLongitude !== undefined) {
            queryParameters["UserLocation.Longitude"] = requestParameters.userLocationLongitude;
        }
        if (requestParameters.userPostalCode !== undefined) {
            queryParameters["UserPostalCode"] = requestParameters.userPostalCode;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Happenings/mymap`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MapItemViewModelFromJSON));
    }
    /**
     */
    async getMyMapHappenings(requestParameters: GetMyMapHappeningsRequest): Promise<Array<MapItemViewModel>> {
        const response = await this.getMyMapHappeningsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getTeamRaw(requestParameters: GetTeamRequest): Promise<runtime.ApiResponse<TeamViewModel>> {
        if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
            throw new runtime.RequiredError(
                "happeningId",
                "Required parameter requestParameters.happeningId was null or undefined when calling getTeam.",
            );
        }
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError(
                "teamId",
                "Required parameter requestParameters.teamId was null or undefined when calling getTeam.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getTeam.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Happenings/{happeningId}/team/{teamId}`
                .replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId)))
                .replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => TeamViewModelFromJSON(jsonValue));
    }
    /**
     */
    async getTeam(requestParameters: GetTeamRequest): Promise<TeamViewModel> {
        const response = await this.getTeamRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async moveRegistrationToTeamRaw(
        requestParameters: MoveRegistrationToTeamRequest,
    ): Promise<runtime.ApiResponse<TeamViewModel>> {
        if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
            throw new runtime.RequiredError(
                "happeningId",
                "Required parameter requestParameters.happeningId was null or undefined when calling moveRegistrationToTeam.",
            );
        }
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError(
                "teamId",
                "Required parameter requestParameters.teamId was null or undefined when calling moveRegistrationToTeam.",
            );
        }
        if (requestParameters.registrationId === null || requestParameters.registrationId === undefined) {
            throw new runtime.RequiredError(
                "registrationId",
                "Required parameter requestParameters.registrationId was null or undefined when calling moveRegistrationToTeam.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling moveRegistrationToTeam.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Happenings/{happeningId}/team/{teamId}/registration/{registrationId}`
                .replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId)))
                .replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId)))
                .replace(`{${"registrationId"}}`, encodeURIComponent(String(requestParameters.registrationId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => TeamViewModelFromJSON(jsonValue));
    }
    /**
     */
    async moveRegistrationToTeam(requestParameters: MoveRegistrationToTeamRequest): Promise<TeamViewModel> {
        const response = await this.moveRegistrationToTeamRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async openHappeningRaw(requestParameters: OpenHappeningRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling openHappening.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling openHappening.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.target !== undefined) {
            queryParameters["target"] = requestParameters.target;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Happenings/{id}/open`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.TextApiResponse(response) as any;
    }
    /**
     */
    async openHappening(requestParameters: OpenHappeningRequest): Promise<string> {
        const response = await this.openHappeningRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async reconceptualizeHappeningRaw(
        requestParameters: ReconceptualizeHappeningRequest,
    ): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling reconceptualizeHappening.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling reconceptualizeHappening.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Happenings/{id}/concept`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.TextApiResponse(response) as any;
    }
    /**
     */
    async reconceptualizeHappening(requestParameters: ReconceptualizeHappeningRequest): Promise<string> {
        const response = await this.reconceptualizeHappeningRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async rejectHappeningRaw(requestParameters: RejectHappeningRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling rejectHappening.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling rejectHappening.",
            );
        }
        if (requestParameters.reason === null || requestParameters.reason === undefined) {
            throw new runtime.RequiredError(
                "reason",
                "Required parameter requestParameters.reason was null or undefined when calling rejectHappening.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.reason !== undefined) {
            formParams.append("Reason", requestParameters.reason as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Happenings/{id}/reject`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async rejectHappening(requestParameters: RejectHappeningRequest): Promise<void> {
        await this.rejectHappeningRaw(requestParameters);
    }
    /**
     */
    async removeSelfFromNotifyMeListRaw(
        requestParameters: RemoveSelfFromNotifyMeListRequest,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling removeSelfFromNotifyMeList.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling removeSelfFromNotifyMeList.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Happenings/{id}/doNotNotifyMe`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async removeSelfFromNotifyMeList(requestParameters: RemoveSelfFromNotifyMeListRequest): Promise<void> {
        await this.removeSelfFromNotifyMeListRaw(requestParameters);
    }
    /**
     */
    async removeUserFromNotifyMeListRaw(
        requestParameters: RemoveUserFromNotifyMeListRequest,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling removeUserFromNotifyMeList.",
            );
        }
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError(
                "userId",
                "Required parameter requestParameters.userId was null or undefined when calling removeUserFromNotifyMeList.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling removeUserFromNotifyMeList.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Happenings/{id}/doNotNotifyUser/{userId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async removeUserFromNotifyMeList(requestParameters: RemoveUserFromNotifyMeListRequest): Promise<void> {
        await this.removeUserFromNotifyMeListRaw(requestParameters);
    }
    /**
     */
    async updateHappeningRaw(requestParameters: UpdateHappeningRequest): Promise<runtime.ApiResponse<ReferenceOutput>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling updateHappening.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling updateHappening.",
            );
        }
        if (requestParameters.happeningType === null || requestParameters.happeningType === undefined) {
            throw new runtime.RequiredError(
                "happeningType",
                "Required parameter requestParameters.happeningType was null or undefined when calling updateHappening.",
            );
        }
        if (requestParameters.title === null || requestParameters.title === undefined) {
            throw new runtime.RequiredError(
                "title",
                "Required parameter requestParameters.title was null or undefined when calling updateHappening.",
            );
        }
        if (requestParameters.restrictAge === null || requestParameters.restrictAge === undefined) {
            throw new runtime.RequiredError(
                "restrictAge",
                "Required parameter requestParameters.restrictAge was null or undefined when calling updateHappening.",
            );
        }
        if (requestParameters.state === null || requestParameters.state === undefined) {
            throw new runtime.RequiredError(
                "state",
                "Required parameter requestParameters.state was null or undefined when calling updateHappening.",
            );
        }
        if (requestParameters.isOnline === null || requestParameters.isOnline === undefined) {
            throw new runtime.RequiredError(
                "isOnline",
                "Required parameter requestParameters.isOnline was null or undefined when calling updateHappening.",
            );
        }
        if (
            requestParameters.requiresApprovalFromMarketing === null ||
            requestParameters.requiresApprovalFromMarketing === undefined
        ) {
            throw new runtime.RequiredError(
                "requiresApprovalFromMarketing",
                "Required parameter requestParameters.requiresApprovalFromMarketing was null or undefined when calling updateHappening.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.happeningType !== undefined) {
            formParams.append("HappeningType", requestParameters.happeningType as any);
            hasParams = true;
        }
        if (requestParameters.title !== undefined) {
            formParams.append("Title", requestParameters.title as any);
            hasParams = true;
        }
        if (requestParameters.description !== undefined) {
            formParams.append("Description", requestParameters.description as any);
            hasParams = true;
        }
        if (requestParameters.link !== undefined) {
            formParams.append("Link", requestParameters.link as any);
            hasParams = true;
        }
        if (requestParameters.linkLabel !== undefined) {
            formParams.append("LinkLabel", requestParameters.linkLabel as any);
            hasParams = true;
        }
        if (requestParameters.targetGroup !== undefined) {
            formParams.append("TargetGroup", requestParameters.targetGroup as any);
            hasParams = true;
        }
        if (requestParameters.targetOrganisations) {
            requestParameters.targetOrganisations.forEach((element, index) => {
                appendToParams(formParams, `TargetOrganisations[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.minAge !== undefined) {
            formParams.append("MinAge", requestParameters.minAge as any);
            hasParams = true;
        }
        if (requestParameters.maxAge !== undefined) {
            formParams.append("MaxAge", requestParameters.maxAge as any);
            hasParams = true;
        }
        if (requestParameters.restrictAge !== undefined) {
            formParams.append("RestrictAge", requestParameters.restrictAge as any);
            hasParams = true;
        }
        if (requestParameters.schools) {
            requestParameters.schools.forEach((element, index) => {
                appendToParams(formParams, `Schools[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.askForSchool !== undefined) {
            formParams.append("AskForSchool", requestParameters.askForSchool as any);
            hasParams = true;
        }
        if (requestParameters.state !== undefined) {
            formParams.append("State", requestParameters.state as any);
            hasParams = true;
        }
        if (requestParameters.publicationDate !== undefined) {
            formParams.append("PublicationDate", (requestParameters.publicationDate as any).toUTCString());
            hasParams = true;
        }
        if (requestParameters.tags) {
            requestParameters.tags.forEach((element, index) => {
                appendToParams(formParams, `Tags[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.tagInputs) {
            requestParameters.tagInputs.forEach((element, index) => {
                appendToParams(formParams, `TagInputs[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.infoLevel !== undefined) {
            formParams.append("InfoLevel", requestParameters.infoLevel as any);
            hasParams = true;
        }
        if (requestParameters.maxNumberOfRegistrations !== undefined) {
            formParams.append("MaxNumberOfRegistrations", requestParameters.maxNumberOfRegistrations as any);
            hasParams = true;
        }
        if (requestParameters.isOnline !== undefined) {
            formParams.append("IsOnline", requestParameters.isOnline as any);
            hasParams = true;
        }
        if (requestParameters.locationType !== undefined) {
            formParams.append("LocationType", requestParameters.locationType as any);
            hasParams = true;
        }
        if (requestParameters.location) {
            requestParameters.location.forEach((element, index) => {
                appendToParams(formParams, `Location[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.contact !== undefined) {
            formParams.append("Contact", requestParameters.contact as any);
            hasParams = true;
        }
        if (requestParameters.projectLeader !== undefined) {
            formParams.append("ProjectLeader", requestParameters.projectLeader as any);
            hasParams = true;
        }
        if (requestParameters.projectCoLeaders) {
            requestParameters.projectCoLeaders.forEach((element, index) => {
                appendToParams(formParams, `ProjectCoLeaders[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.projectMembers) {
            requestParameters.projectMembers.forEach((element, index) => {
                appendToParams(formParams, `ProjectMembers[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.price !== undefined) {
            formParams.append("Price", requestParameters.price as any);
            hasParams = true;
        }
        if (requestParameters.images) {
            requestParameters.images.forEach((element, index) => {
                appendToParams(formParams, `Images[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.videos) {
            requestParameters.videos.forEach((element, index) => {
                appendToParams(formParams, `Videos[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.requiresApprovalFromMarketing !== undefined) {
            formParams.append("RequiresApprovalFromMarketing", requestParameters.requiresApprovalFromMarketing as any);
            hasParams = true;
        }
        if (requestParameters.isAccessible !== undefined) {
            formParams.append("IsAccessible", requestParameters.isAccessible as any);
            hasParams = true;
        }
        if (requestParameters.allowedPaymentMethods !== undefined) {
            formParams.append("AllowedPaymentMethods", requestParameters.allowedPaymentMethods as any);
            hasParams = true;
        }
        if (requestParameters.organizer !== undefined) {
            formParams.append("Organizer", requestParameters.organizer as any);
            hasParams = true;
        }
        if (requestParameters.cluster !== undefined) {
            formParams.append("Cluster", requestParameters.cluster as any);
            hasParams = true;
        }
        if (requestParameters.happeningRegistrationType !== undefined) {
            formParams.append("HappeningRegistrationType", requestParameters.happeningRegistrationType as any);
            hasParams = true;
        }
        if (requestParameters.requiresRegistration !== undefined) {
            formParams.append("RequiresRegistration", requestParameters.requiresRegistration as any);
            hasParams = true;
        }
        if (requestParameters.minPeoplePerGroup !== undefined) {
            formParams.append("MinPeoplePerGroup", requestParameters.minPeoplePerGroup as any);
            hasParams = true;
        }
        if (requestParameters.maxPeoplePerGroup !== undefined) {
            formParams.append("MaxPeoplePerGroup", requestParameters.maxPeoplePerGroup as any);
            hasParams = true;
        }
        if (requestParameters.canOnlyRegisterAsGroup !== undefined) {
            formParams.append("CanOnlyRegisterAsGroup", requestParameters.canOnlyRegisterAsGroup as any);
            hasParams = true;
        }
        if (requestParameters.priceForIndividual !== undefined) {
            formParams.append("PriceForIndividual", requestParameters.priceForIndividual as any);
            hasParams = true;
        }
        if (requestParameters.isProRata !== undefined) {
            formParams.append("IsProRata", requestParameters.isProRata as any);
            hasParams = true;
        }
        if (requestParameters.timeAndDates) {
            requestParameters.timeAndDates.forEach((element, index) => {
                appendToParams(formParams, `TimeAndDates[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.recurrence) {
            requestParameters.recurrence.forEach((element, index) => {
                appendToParams(formParams, `Recurrence[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.updateTarget !== undefined) {
            formParams.append("UpdateTarget", requestParameters.updateTarget as any);
            hasParams = true;
        }
        if (requestParameters.extraQuestions) {
            requestParameters.extraQuestions.forEach((element, index) => {
                appendToParams(formParams, `ExtraQuestions[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.registrationStartDate !== undefined) {
            formParams.append("RegistrationStartDate", (requestParameters.registrationStartDate as any).toUTCString());
            hasParams = true;
        }
        if (requestParameters.registrationEndDate !== undefined) {
            formParams.append("RegistrationEndDate", (requestParameters.registrationEndDate as any).toUTCString());
            hasParams = true;
        }
        if (requestParameters.durationInMinutes !== undefined) {
            formParams.append("DurationInMinutes", requestParameters.durationInMinutes as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Happenings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => ReferenceOutputFromJSON(jsonValue));
    }
    /**
     */
    async updateHappening(requestParameters: UpdateHappeningRequest): Promise<ReferenceOutput> {
        const response = await this.updateHappeningRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async updateTeamNameRaw(requestParameters: UpdateTeamNameRequest): Promise<runtime.ApiResponse<TeamViewModel>> {
        if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
            throw new runtime.RequiredError(
                "happeningId",
                "Required parameter requestParameters.happeningId was null or undefined when calling updateTeamName.",
            );
        }
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError(
                "teamId",
                "Required parameter requestParameters.teamId was null or undefined when calling updateTeamName.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling updateTeamName.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters["Content-Type"] = "application/json-patch+json";
        const response = await this.request({
            path: `/v{version}/Happenings/{happeningId}/team/{teamId}/name`
                .replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId)))
                .replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => TeamViewModelFromJSON(jsonValue));
    }
    /**
     */
    async updateTeamName(requestParameters: UpdateTeamNameRequest): Promise<TeamViewModel> {
        const response = await this.updateTeamNameRaw(requestParameters);
        return await response.value();
    }
}
