/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 * - Append nested object fields in array as formdata fields
 * - Always check for nullable in "body: .map" for json patch
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

function appendToParams(formParams: { append(param: string, value: any): any }, path: string, value: any) {
    if (value === undefined) {
        return;
    } else if (Array.isArray(value)) {
        value.forEach((v, i) => appendToParams(formParams, `${path}[${i}]`, v));
    } else if (value instanceof Date) {
        formParams.append(path, value.toUTCString());
    } else if (value instanceof Blob) {
        formParams.append(path, value);
    } else if (typeof value == "object" && !!value) {
        Object.keys(value).forEach((key) => appendToParams(formParams, `${path}.${key}`, value[key]));
    } else {
        formParams.append(path, value);
    }
}

import * as runtime from "../runtime";
import {
    FavoriteOutput,
    FavoriteOutputFromJSON,
    FavoriteOutputToJSON,
    FilterInclusion,
    FilterInclusionFromJSON,
    FilterInclusionToJSON,
    LocationInputModel,
    LocationInputModelFromJSON,
    LocationInputModelToJSON,
    MapItemViewModel,
    MapItemViewModelFromJSON,
    MapItemViewModelToJSON,
    OrganisationExternalLinkInputModel,
    OrganisationExternalLinkInputModelFromJSON,
    OrganisationExternalLinkInputModelToJSON,
    OrganisationInfoAppListViewModelPaginatedViewModel,
    OrganisationInfoAppListViewModelPaginatedViewModelFromJSON,
    OrganisationInfoAppListViewModelPaginatedViewModelToJSON,
    OrganisationInfoAppMapViewModelPaginatedViewModel,
    OrganisationInfoAppMapViewModelPaginatedViewModelFromJSON,
    OrganisationInfoAppMapViewModelPaginatedViewModelToJSON,
    OrganisationInfoBackofficeListViewModelPaginatedViewModel,
    OrganisationInfoBackofficeListViewModelPaginatedViewModelFromJSON,
    OrganisationInfoBackofficeListViewModelPaginatedViewModelToJSON,
    OrganisationInfoOrderBy,
    OrganisationInfoOrderByFromJSON,
    OrganisationInfoOrderByToJSON,
    OrganisationInfoViewModel,
    OrganisationInfoViewModelFromJSON,
    OrganisationInfoViewModelToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
    TagViewModel,
    TagViewModelFromJSON,
    TagViewModelToJSON,
} from "../models";

export interface ActivateOrganisationInfoRequest {
    id: string;
    version: string;
}

export interface CreateOrganisationInfoRequest {
    version: string;
    title: string;
    category: string;
    minimumAge: number;
    maximumAge: number;
    description: string;
    locations: Array<LocationInputModel>;
    contactName: string;
    contactEmail: string;
    contactPhoneNumber: string;
    isActive?: boolean;
    organisationTag?: string;
    sector?: string;
    specificTargetGroup?: string;
    externalLinks?: Array<OrganisationExternalLinkInputModel>;
    images?: Array<number>;
}

export interface DeactivateOrganisationInfoRequest {
    id: string;
    version: string;
}

export interface DeleteOrganisationInfoRequest {
    id: string;
    version: string;
}

export interface FavoriteOrganisationInfoRequest {
    id: string;
    version: string;
}

export interface GetOrganisationInfoRequest {
    id: string;
    version: string;
}

export interface GetOrganisationInfoItemsForAppListRequest {
    version: string;
    page?: number;
    itemsPerPage?: number;
    itemsToSkip?: number;
    query?: string;
    categories?: Array<string>;
    isActive?: FilterInclusion;
    areas?: Array<string>;
    maxDistance?: number;
    targetAges?: Array<number>;
    onlyFavorites?: boolean;
    viewTopLeftLatitude?: number;
    viewTopLeftLongitude?: number;
    viewBottomRightLatitude?: number;
    viewBottomRightLongitude?: number;
    orderBy?: OrganisationInfoOrderBy;
    sortOrder?: SortOrder;
    isDescending?: boolean;
    userLocationLatitude?: number;
    userLocationLongitude?: number;
    userPostalCode?: string;
}

export interface GetOrganisationInfoItemsForAppMapRequest {
    version: string;
    page?: number;
    itemsPerPage?: number;
    itemsToSkip?: number;
    query?: string;
    categories?: Array<string>;
    isActive?: FilterInclusion;
    areas?: Array<string>;
    maxDistance?: number;
    targetAges?: Array<number>;
    onlyFavorites?: boolean;
    viewTopLeftLatitude?: number;
    viewTopLeftLongitude?: number;
    viewBottomRightLatitude?: number;
    viewBottomRightLongitude?: number;
    orderBy?: OrganisationInfoOrderBy;
    sortOrder?: SortOrder;
    isDescending?: boolean;
    userLocationLatitude?: number;
    userLocationLongitude?: number;
    userPostalCode?: string;
}

export interface GetOrganisationInfoMapItemsRequest {
    version: string;
    query?: string;
    categories?: Array<string>;
    isActive?: FilterInclusion;
    areas?: Array<string>;
    maxDistance?: number;
    targetAges?: Array<number>;
    onlyFavorites?: boolean;
    viewTopLeftLatitude?: number;
    viewTopLeftLongitude?: number;
    viewBottomRightLatitude?: number;
    viewBottomRightLongitude?: number;
    userLocationLatitude?: number;
    userLocationLongitude?: number;
    userPostalCode?: string;
}

export interface GetOrganisationInfoOverviewForBackofficeRequest {
    version: string;
    page?: number;
    itemsPerPage?: number;
    itemsToSkip?: number;
    query?: string;
    categories?: Array<string>;
    isActive?: FilterInclusion;
    areas?: Array<string>;
    maxDistance?: number;
    targetAges?: Array<number>;
    onlyFavorites?: boolean;
    viewTopLeftLatitude?: number;
    viewTopLeftLongitude?: number;
    viewBottomRightLatitude?: number;
    viewBottomRightLongitude?: number;
    orderBy?: OrganisationInfoOrderBy;
    sortOrder?: SortOrder;
    isDescending?: boolean;
    userLocationLatitude?: number;
    userLocationLongitude?: number;
    userPostalCode?: string;
}

export interface GetOrganisationTagsWithoutInfoPageRequest {
    version: string;
    current?: string;
}

export interface UpdateOrganisationInfoRequest {
    id: string;
    version: string;
    title: string;
    category: string;
    minimumAge: number;
    maximumAge: number;
    description: string;
    locations: Array<LocationInputModel>;
    contactName: string;
    contactEmail: string;
    contactPhoneNumber: string;
    isActive?: boolean;
    organisationTag?: string;
    sector?: string;
    specificTargetGroup?: string;
    externalLinks?: Array<OrganisationExternalLinkInputModel>;
    images?: Array<number>;
}

/**
 * no description
 */
export class OrganisationInfoApi extends runtime.BaseAPI {
    /**
     */
    async activateOrganisationInfoRaw(
        requestParameters: ActivateOrganisationInfoRequest,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling activateOrganisationInfo.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling activateOrganisationInfo.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/OrganisationInfo/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async activateOrganisationInfo(requestParameters: ActivateOrganisationInfoRequest): Promise<void> {
        await this.activateOrganisationInfoRaw(requestParameters);
    }
    /**
     */
    async createOrganisationInfoRaw(
        requestParameters: CreateOrganisationInfoRequest,
    ): Promise<runtime.ApiResponse<OrganisationInfoViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling createOrganisationInfo.",
            );
        }
        if (requestParameters.title === null || requestParameters.title === undefined) {
            throw new runtime.RequiredError(
                "title",
                "Required parameter requestParameters.title was null or undefined when calling createOrganisationInfo.",
            );
        }
        if (requestParameters.category === null || requestParameters.category === undefined) {
            throw new runtime.RequiredError(
                "category",
                "Required parameter requestParameters.category was null or undefined when calling createOrganisationInfo.",
            );
        }
        if (requestParameters.minimumAge === null || requestParameters.minimumAge === undefined) {
            throw new runtime.RequiredError(
                "minimumAge",
                "Required parameter requestParameters.minimumAge was null or undefined when calling createOrganisationInfo.",
            );
        }
        if (requestParameters.maximumAge === null || requestParameters.maximumAge === undefined) {
            throw new runtime.RequiredError(
                "maximumAge",
                "Required parameter requestParameters.maximumAge was null or undefined when calling createOrganisationInfo.",
            );
        }
        if (requestParameters.description === null || requestParameters.description === undefined) {
            throw new runtime.RequiredError(
                "description",
                "Required parameter requestParameters.description was null or undefined when calling createOrganisationInfo.",
            );
        }
        if (requestParameters.locations === null || requestParameters.locations === undefined) {
            throw new runtime.RequiredError(
                "locations",
                "Required parameter requestParameters.locations was null or undefined when calling createOrganisationInfo.",
            );
        }
        if (requestParameters.contactName === null || requestParameters.contactName === undefined) {
            throw new runtime.RequiredError(
                "contactName",
                "Required parameter requestParameters.contactName was null or undefined when calling createOrganisationInfo.",
            );
        }
        if (requestParameters.contactEmail === null || requestParameters.contactEmail === undefined) {
            throw new runtime.RequiredError(
                "contactEmail",
                "Required parameter requestParameters.contactEmail was null or undefined when calling createOrganisationInfo.",
            );
        }
        if (requestParameters.contactPhoneNumber === null || requestParameters.contactPhoneNumber === undefined) {
            throw new runtime.RequiredError(
                "contactPhoneNumber",
                "Required parameter requestParameters.contactPhoneNumber was null or undefined when calling createOrganisationInfo.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.isActive !== undefined) {
            formParams.append("IsActive", requestParameters.isActive as any);
            hasParams = true;
        }
        if (requestParameters.title !== undefined) {
            formParams.append("Title", requestParameters.title as any);
            hasParams = true;
        }
        if (requestParameters.organisationTag !== undefined) {
            formParams.append("OrganisationTag", requestParameters.organisationTag as any);
            hasParams = true;
        }
        if (requestParameters.category !== undefined) {
            formParams.append("Category", requestParameters.category as any);
            hasParams = true;
        }
        if (requestParameters.sector !== undefined) {
            formParams.append("Sector", requestParameters.sector as any);
            hasParams = true;
        }
        if (requestParameters.minimumAge !== undefined) {
            formParams.append("MinimumAge", requestParameters.minimumAge as any);
            hasParams = true;
        }
        if (requestParameters.maximumAge !== undefined) {
            formParams.append("MaximumAge", requestParameters.maximumAge as any);
            hasParams = true;
        }
        if (requestParameters.specificTargetGroup !== undefined) {
            formParams.append("SpecificTargetGroup", requestParameters.specificTargetGroup as any);
            hasParams = true;
        }
        if (requestParameters.description !== undefined) {
            formParams.append("Description", requestParameters.description as any);
            hasParams = true;
        }
        if (requestParameters.locations) {
            requestParameters.locations.forEach((element, index) => {
                appendToParams(formParams, `Locations[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.externalLinks) {
            requestParameters.externalLinks.forEach((element, index) => {
                appendToParams(formParams, `ExternalLinks[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.contactName !== undefined) {
            formParams.append("Contact.Name", requestParameters.contactName as any);
            hasParams = true;
        }
        if (requestParameters.contactEmail !== undefined) {
            formParams.append("Contact.Email", requestParameters.contactEmail as any);
            hasParams = true;
        }
        if (requestParameters.contactPhoneNumber !== undefined) {
            formParams.append("Contact.PhoneNumber", requestParameters.contactPhoneNumber as any);
            hasParams = true;
        }
        if (requestParameters.images) {
            requestParameters.images.forEach((element, index) => {
                appendToParams(formParams, `Images[${index}]`, element);
                hasParams = true;
            });
        }
        const response = await this.request({
            path: `/v{version}/OrganisationInfo`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationInfoViewModelFromJSON(jsonValue));
    }
    /**
     */
    async createOrganisationInfo(requestParameters: CreateOrganisationInfoRequest): Promise<OrganisationInfoViewModel> {
        const response = await this.createOrganisationInfoRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async deactivateOrganisationInfoRaw(
        requestParameters: DeactivateOrganisationInfoRequest,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling deactivateOrganisationInfo.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling deactivateOrganisationInfo.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/OrganisationInfo/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deactivateOrganisationInfo(requestParameters: DeactivateOrganisationInfoRequest): Promise<void> {
        await this.deactivateOrganisationInfoRaw(requestParameters);
    }
    /**
     */
    async deleteOrganisationInfoRaw(
        requestParameters: DeleteOrganisationInfoRequest,
    ): Promise<runtime.ApiResponse<OrganisationInfoViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling deleteOrganisationInfo.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling deleteOrganisationInfo.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/OrganisationInfo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationInfoViewModelFromJSON(jsonValue));
    }
    /**
     */
    async deleteOrganisationInfo(requestParameters: DeleteOrganisationInfoRequest): Promise<OrganisationInfoViewModel> {
        const response = await this.deleteOrganisationInfoRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async favoriteOrganisationInfoRaw(
        requestParameters: FavoriteOrganisationInfoRequest,
    ): Promise<runtime.ApiResponse<FavoriteOutput>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling favoriteOrganisationInfo.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling favoriteOrganisationInfo.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/OrganisationInfo/{id}/favorite`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => FavoriteOutputFromJSON(jsonValue));
    }
    /**
     */
    async favoriteOrganisationInfo(requestParameters: FavoriteOrganisationInfoRequest): Promise<FavoriteOutput> {
        const response = await this.favoriteOrganisationInfoRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getOrganisationInfoRaw(
        requestParameters: GetOrganisationInfoRequest,
    ): Promise<runtime.ApiResponse<OrganisationInfoViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling getOrganisationInfo.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getOrganisationInfo.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/OrganisationInfo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationInfoViewModelFromJSON(jsonValue));
    }
    /**
     */
    async getOrganisationInfo(requestParameters: GetOrganisationInfoRequest): Promise<OrganisationInfoViewModel> {
        const response = await this.getOrganisationInfoRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getOrganisationInfoItemsForAppListRaw(
        requestParameters: GetOrganisationInfoItemsForAppListRequest,
    ): Promise<runtime.ApiResponse<OrganisationInfoAppListViewModelPaginatedViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getOrganisationInfoItemsForAppList.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.page !== undefined) {
            queryParameters["Page"] = requestParameters.page;
        }
        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters["ItemsPerPage"] = requestParameters.itemsPerPage;
        }
        if (requestParameters.itemsToSkip !== undefined) {
            queryParameters["ItemsToSkip"] = requestParameters.itemsToSkip;
        }
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        if (requestParameters.categories && requestParameters.categories.length > 0) {
            queryParameters["Categories"] = requestParameters.categories;
        }
        if (requestParameters.isActive !== undefined) {
            queryParameters["IsActive"] = requestParameters.isActive;
        }
        if (requestParameters.areas && requestParameters.areas.length > 0) {
            queryParameters["Areas"] = requestParameters.areas;
        }
        if (requestParameters.maxDistance !== undefined) {
            queryParameters["MaxDistance"] = requestParameters.maxDistance;
        }
        if (requestParameters.targetAges && requestParameters.targetAges.length > 0) {
            queryParameters["TargetAges"] = requestParameters.targetAges;
        }
        if (requestParameters.onlyFavorites !== undefined) {
            queryParameters["OnlyFavorites"] = requestParameters.onlyFavorites;
        }
        if (requestParameters.viewTopLeftLatitude !== undefined) {
            queryParameters["ViewTopLeft.Latitude"] = requestParameters.viewTopLeftLatitude;
        }
        if (requestParameters.viewTopLeftLongitude !== undefined) {
            queryParameters["ViewTopLeft.Longitude"] = requestParameters.viewTopLeftLongitude;
        }
        if (requestParameters.viewBottomRightLatitude !== undefined) {
            queryParameters["ViewBottomRight.Latitude"] = requestParameters.viewBottomRightLatitude;
        }
        if (requestParameters.viewBottomRightLongitude !== undefined) {
            queryParameters["ViewBottomRight.Longitude"] = requestParameters.viewBottomRightLongitude;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters["OrderBy"] = requestParameters.orderBy;
        }
        if (requestParameters.sortOrder !== undefined) {
            queryParameters["SortOrder"] = requestParameters.sortOrder;
        }
        if (requestParameters.isDescending !== undefined) {
            queryParameters["IsDescending"] = requestParameters.isDescending;
        }
        if (requestParameters.userLocationLatitude !== undefined) {
            queryParameters["UserLocation.Latitude"] = requestParameters.userLocationLatitude;
        }
        if (requestParameters.userLocationLongitude !== undefined) {
            queryParameters["UserLocation.Longitude"] = requestParameters.userLocationLongitude;
        }
        if (requestParameters.userPostalCode !== undefined) {
            queryParameters["UserPostalCode"] = requestParameters.userPostalCode;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/OrganisationInfo/app/list`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            OrganisationInfoAppListViewModelPaginatedViewModelFromJSON(jsonValue),
        );
    }
    /**
     */
    async getOrganisationInfoItemsForAppList(
        requestParameters: GetOrganisationInfoItemsForAppListRequest,
    ): Promise<OrganisationInfoAppListViewModelPaginatedViewModel> {
        const response = await this.getOrganisationInfoItemsForAppListRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getOrganisationInfoItemsForAppMapRaw(
        requestParameters: GetOrganisationInfoItemsForAppMapRequest,
    ): Promise<runtime.ApiResponse<OrganisationInfoAppMapViewModelPaginatedViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getOrganisationInfoItemsForAppMap.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.page !== undefined) {
            queryParameters["Page"] = requestParameters.page;
        }
        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters["ItemsPerPage"] = requestParameters.itemsPerPage;
        }
        if (requestParameters.itemsToSkip !== undefined) {
            queryParameters["ItemsToSkip"] = requestParameters.itemsToSkip;
        }
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        if (requestParameters.categories && requestParameters.categories.length > 0) {
            queryParameters["Categories"] = requestParameters.categories;
        }
        if (requestParameters.isActive !== undefined) {
            queryParameters["IsActive"] = requestParameters.isActive;
        }
        if (requestParameters.areas && requestParameters.areas.length > 0) {
            queryParameters["Areas"] = requestParameters.areas;
        }
        if (requestParameters.maxDistance !== undefined) {
            queryParameters["MaxDistance"] = requestParameters.maxDistance;
        }
        if (requestParameters.targetAges && requestParameters.targetAges.length > 0) {
            queryParameters["TargetAges"] = requestParameters.targetAges;
        }
        if (requestParameters.onlyFavorites !== undefined) {
            queryParameters["OnlyFavorites"] = requestParameters.onlyFavorites;
        }
        if (requestParameters.viewTopLeftLatitude !== undefined) {
            queryParameters["ViewTopLeft.Latitude"] = requestParameters.viewTopLeftLatitude;
        }
        if (requestParameters.viewTopLeftLongitude !== undefined) {
            queryParameters["ViewTopLeft.Longitude"] = requestParameters.viewTopLeftLongitude;
        }
        if (requestParameters.viewBottomRightLatitude !== undefined) {
            queryParameters["ViewBottomRight.Latitude"] = requestParameters.viewBottomRightLatitude;
        }
        if (requestParameters.viewBottomRightLongitude !== undefined) {
            queryParameters["ViewBottomRight.Longitude"] = requestParameters.viewBottomRightLongitude;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters["OrderBy"] = requestParameters.orderBy;
        }
        if (requestParameters.sortOrder !== undefined) {
            queryParameters["SortOrder"] = requestParameters.sortOrder;
        }
        if (requestParameters.isDescending !== undefined) {
            queryParameters["IsDescending"] = requestParameters.isDescending;
        }
        if (requestParameters.userLocationLatitude !== undefined) {
            queryParameters["UserLocation.Latitude"] = requestParameters.userLocationLatitude;
        }
        if (requestParameters.userLocationLongitude !== undefined) {
            queryParameters["UserLocation.Longitude"] = requestParameters.userLocationLongitude;
        }
        if (requestParameters.userPostalCode !== undefined) {
            queryParameters["UserPostalCode"] = requestParameters.userPostalCode;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/OrganisationInfo/app/map`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            OrganisationInfoAppMapViewModelPaginatedViewModelFromJSON(jsonValue),
        );
    }
    /**
     */
    async getOrganisationInfoItemsForAppMap(
        requestParameters: GetOrganisationInfoItemsForAppMapRequest,
    ): Promise<OrganisationInfoAppMapViewModelPaginatedViewModel> {
        const response = await this.getOrganisationInfoItemsForAppMapRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getOrganisationInfoMapItemsRaw(
        requestParameters: GetOrganisationInfoMapItemsRequest,
    ): Promise<runtime.ApiResponse<Array<MapItemViewModel>>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getOrganisationInfoMapItems.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        if (requestParameters.categories && requestParameters.categories.length > 0) {
            queryParameters["Categories"] = requestParameters.categories;
        }
        if (requestParameters.isActive !== undefined) {
            queryParameters["IsActive"] = requestParameters.isActive;
        }
        if (requestParameters.areas && requestParameters.areas.length > 0) {
            queryParameters["Areas"] = requestParameters.areas;
        }
        if (requestParameters.maxDistance !== undefined) {
            queryParameters["MaxDistance"] = requestParameters.maxDistance;
        }
        if (requestParameters.targetAges && requestParameters.targetAges.length > 0) {
            queryParameters["TargetAges"] = requestParameters.targetAges;
        }
        if (requestParameters.onlyFavorites !== undefined) {
            queryParameters["OnlyFavorites"] = requestParameters.onlyFavorites;
        }
        if (requestParameters.viewTopLeftLatitude !== undefined) {
            queryParameters["ViewTopLeft.Latitude"] = requestParameters.viewTopLeftLatitude;
        }
        if (requestParameters.viewTopLeftLongitude !== undefined) {
            queryParameters["ViewTopLeft.Longitude"] = requestParameters.viewTopLeftLongitude;
        }
        if (requestParameters.viewBottomRightLatitude !== undefined) {
            queryParameters["ViewBottomRight.Latitude"] = requestParameters.viewBottomRightLatitude;
        }
        if (requestParameters.viewBottomRightLongitude !== undefined) {
            queryParameters["ViewBottomRight.Longitude"] = requestParameters.viewBottomRightLongitude;
        }
        if (requestParameters.userLocationLatitude !== undefined) {
            queryParameters["UserLocation.Latitude"] = requestParameters.userLocationLatitude;
        }
        if (requestParameters.userLocationLongitude !== undefined) {
            queryParameters["UserLocation.Longitude"] = requestParameters.userLocationLongitude;
        }
        if (requestParameters.userPostalCode !== undefined) {
            queryParameters["UserPostalCode"] = requestParameters.userPostalCode;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/OrganisationInfo/map`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MapItemViewModelFromJSON));
    }
    /**
     */
    async getOrganisationInfoMapItems(
        requestParameters: GetOrganisationInfoMapItemsRequest,
    ): Promise<Array<MapItemViewModel>> {
        const response = await this.getOrganisationInfoMapItemsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getOrganisationInfoOverviewForBackofficeRaw(
        requestParameters: GetOrganisationInfoOverviewForBackofficeRequest,
    ): Promise<runtime.ApiResponse<OrganisationInfoBackofficeListViewModelPaginatedViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getOrganisationInfoOverviewForBackoffice.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.page !== undefined) {
            queryParameters["Page"] = requestParameters.page;
        }
        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters["ItemsPerPage"] = requestParameters.itemsPerPage;
        }
        if (requestParameters.itemsToSkip !== undefined) {
            queryParameters["ItemsToSkip"] = requestParameters.itemsToSkip;
        }
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        if (requestParameters.categories && requestParameters.categories.length > 0) {
            queryParameters["Categories"] = requestParameters.categories;
        }
        if (requestParameters.isActive !== undefined) {
            queryParameters["IsActive"] = requestParameters.isActive;
        }
        if (requestParameters.areas && requestParameters.areas.length > 0) {
            queryParameters["Areas"] = requestParameters.areas;
        }
        if (requestParameters.maxDistance !== undefined) {
            queryParameters["MaxDistance"] = requestParameters.maxDistance;
        }
        if (requestParameters.targetAges && requestParameters.targetAges.length > 0) {
            queryParameters["TargetAges"] = requestParameters.targetAges;
        }
        if (requestParameters.onlyFavorites !== undefined) {
            queryParameters["OnlyFavorites"] = requestParameters.onlyFavorites;
        }
        if (requestParameters.viewTopLeftLatitude !== undefined) {
            queryParameters["ViewTopLeft.Latitude"] = requestParameters.viewTopLeftLatitude;
        }
        if (requestParameters.viewTopLeftLongitude !== undefined) {
            queryParameters["ViewTopLeft.Longitude"] = requestParameters.viewTopLeftLongitude;
        }
        if (requestParameters.viewBottomRightLatitude !== undefined) {
            queryParameters["ViewBottomRight.Latitude"] = requestParameters.viewBottomRightLatitude;
        }
        if (requestParameters.viewBottomRightLongitude !== undefined) {
            queryParameters["ViewBottomRight.Longitude"] = requestParameters.viewBottomRightLongitude;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters["OrderBy"] = requestParameters.orderBy;
        }
        if (requestParameters.sortOrder !== undefined) {
            queryParameters["SortOrder"] = requestParameters.sortOrder;
        }
        if (requestParameters.isDescending !== undefined) {
            queryParameters["IsDescending"] = requestParameters.isDescending;
        }
        if (requestParameters.userLocationLatitude !== undefined) {
            queryParameters["UserLocation.Latitude"] = requestParameters.userLocationLatitude;
        }
        if (requestParameters.userLocationLongitude !== undefined) {
            queryParameters["UserLocation.Longitude"] = requestParameters.userLocationLongitude;
        }
        if (requestParameters.userPostalCode !== undefined) {
            queryParameters["UserPostalCode"] = requestParameters.userPostalCode;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/OrganisationInfo`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            OrganisationInfoBackofficeListViewModelPaginatedViewModelFromJSON(jsonValue),
        );
    }
    /**
     */
    async getOrganisationInfoOverviewForBackoffice(
        requestParameters: GetOrganisationInfoOverviewForBackofficeRequest,
    ): Promise<OrganisationInfoBackofficeListViewModelPaginatedViewModel> {
        const response = await this.getOrganisationInfoOverviewForBackofficeRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getOrganisationTagsWithoutInfoPageRaw(
        requestParameters: GetOrganisationTagsWithoutInfoPageRequest,
    ): Promise<runtime.ApiResponse<Array<TagViewModel>>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getOrganisationTagsWithoutInfoPage.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.current !== undefined) {
            queryParameters["current"] = requestParameters.current;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/OrganisationInfo/available-organisation-tags`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TagViewModelFromJSON));
    }
    /**
     */
    async getOrganisationTagsWithoutInfoPage(
        requestParameters: GetOrganisationTagsWithoutInfoPageRequest,
    ): Promise<Array<TagViewModel>> {
        const response = await this.getOrganisationTagsWithoutInfoPageRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async updateOrganisationInfoRaw(
        requestParameters: UpdateOrganisationInfoRequest,
    ): Promise<runtime.ApiResponse<OrganisationInfoViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling updateOrganisationInfo.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling updateOrganisationInfo.",
            );
        }
        if (requestParameters.title === null || requestParameters.title === undefined) {
            throw new runtime.RequiredError(
                "title",
                "Required parameter requestParameters.title was null or undefined when calling updateOrganisationInfo.",
            );
        }
        if (requestParameters.category === null || requestParameters.category === undefined) {
            throw new runtime.RequiredError(
                "category",
                "Required parameter requestParameters.category was null or undefined when calling updateOrganisationInfo.",
            );
        }
        if (requestParameters.minimumAge === null || requestParameters.minimumAge === undefined) {
            throw new runtime.RequiredError(
                "minimumAge",
                "Required parameter requestParameters.minimumAge was null or undefined when calling updateOrganisationInfo.",
            );
        }
        if (requestParameters.maximumAge === null || requestParameters.maximumAge === undefined) {
            throw new runtime.RequiredError(
                "maximumAge",
                "Required parameter requestParameters.maximumAge was null or undefined when calling updateOrganisationInfo.",
            );
        }
        if (requestParameters.description === null || requestParameters.description === undefined) {
            throw new runtime.RequiredError(
                "description",
                "Required parameter requestParameters.description was null or undefined when calling updateOrganisationInfo.",
            );
        }
        if (requestParameters.locations === null || requestParameters.locations === undefined) {
            throw new runtime.RequiredError(
                "locations",
                "Required parameter requestParameters.locations was null or undefined when calling updateOrganisationInfo.",
            );
        }
        if (requestParameters.contactName === null || requestParameters.contactName === undefined) {
            throw new runtime.RequiredError(
                "contactName",
                "Required parameter requestParameters.contactName was null or undefined when calling updateOrganisationInfo.",
            );
        }
        if (requestParameters.contactEmail === null || requestParameters.contactEmail === undefined) {
            throw new runtime.RequiredError(
                "contactEmail",
                "Required parameter requestParameters.contactEmail was null or undefined when calling updateOrganisationInfo.",
            );
        }
        if (requestParameters.contactPhoneNumber === null || requestParameters.contactPhoneNumber === undefined) {
            throw new runtime.RequiredError(
                "contactPhoneNumber",
                "Required parameter requestParameters.contactPhoneNumber was null or undefined when calling updateOrganisationInfo.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.isActive !== undefined) {
            formParams.append("IsActive", requestParameters.isActive as any);
            hasParams = true;
        }
        if (requestParameters.title !== undefined) {
            formParams.append("Title", requestParameters.title as any);
            hasParams = true;
        }
        if (requestParameters.organisationTag !== undefined) {
            formParams.append("OrganisationTag", requestParameters.organisationTag as any);
            hasParams = true;
        }
        if (requestParameters.category !== undefined) {
            formParams.append("Category", requestParameters.category as any);
            hasParams = true;
        }
        if (requestParameters.sector !== undefined) {
            formParams.append("Sector", requestParameters.sector as any);
            hasParams = true;
        }
        if (requestParameters.minimumAge !== undefined) {
            formParams.append("MinimumAge", requestParameters.minimumAge as any);
            hasParams = true;
        }
        if (requestParameters.maximumAge !== undefined) {
            formParams.append("MaximumAge", requestParameters.maximumAge as any);
            hasParams = true;
        }
        if (requestParameters.specificTargetGroup !== undefined) {
            formParams.append("SpecificTargetGroup", requestParameters.specificTargetGroup as any);
            hasParams = true;
        }
        if (requestParameters.description !== undefined) {
            formParams.append("Description", requestParameters.description as any);
            hasParams = true;
        }
        if (requestParameters.locations) {
            requestParameters.locations.forEach((element, index) => {
                appendToParams(formParams, `Locations[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.externalLinks) {
            requestParameters.externalLinks.forEach((element, index) => {
                appendToParams(formParams, `ExternalLinks[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.contactName !== undefined) {
            formParams.append("Contact.Name", requestParameters.contactName as any);
            hasParams = true;
        }
        if (requestParameters.contactEmail !== undefined) {
            formParams.append("Contact.Email", requestParameters.contactEmail as any);
            hasParams = true;
        }
        if (requestParameters.contactPhoneNumber !== undefined) {
            formParams.append("Contact.PhoneNumber", requestParameters.contactPhoneNumber as any);
            hasParams = true;
        }
        if (requestParameters.images) {
            requestParameters.images.forEach((element, index) => {
                appendToParams(formParams, `Images[${index}]`, element);
                hasParams = true;
            });
        }
        const response = await this.request({
            path: `/v{version}/OrganisationInfo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationInfoViewModelFromJSON(jsonValue));
    }
    /**
     */
    async updateOrganisationInfo(requestParameters: UpdateOrganisationInfoRequest): Promise<OrganisationInfoViewModel> {
        const response = await this.updateOrganisationInfoRaw(requestParameters);
        return await response.value();
    }
}
