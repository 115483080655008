/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum TagType {
    HappeningGroup = "HappeningGroup",
    HappeningType = "HappeningType",
    TargetGroup = "TargetGroup",
    Municipality = "Municipality",
    District = "District",
    Partner = "Partner",
    ServiceGroup = "ServiceGroup",
    Other = "Other",
    Neighbourhood = "Neighbourhood",
    Category = "Category",
    Industry = "Industry",
    Internal = "Internal",
    MediaCategory = "MediaCategory",
    MediaTag = "MediaTag",
}

export function TagTypeFromJSON(json: any): TagType {
    return TagTypeFromJSONTyped(json, false);
}

export function TagTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagType {
    return json as TagType;
}

export function TagTypeToJSON(value?: TagType | null): any {
    return value as any;
}
