import React, { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import HappeningListFilter from "./HappeningListFilter";
import { useFilters, useResetFilters } from "../../hooks/useRouterHooks";
import { HappeningFilter } from "../../hooks/useHappeningsApiHooks";

type Props = {
    className: string;
};

const HappeningListFilterContainer = (props: Props) => {
    const [filters, setFilters] = useFilters();
    const [newFilters, setNewFilters] = useState<HappeningFilter>(filters);
    const resetFilters = useResetFilters();

    const debouncedOnChange = useDebouncedCallback((input: HappeningFilter) => {
        return setFilters(input);
    }, 300);

    const onApplyFilters = useCallback(() => {
        if (newFilters) debouncedOnChange(newFilters);
    }, [debouncedOnChange, newFilters]);

    const onReset = useCallback(() => {
        setNewFilters({});
        resetFilters();
    }, [resetFilters]);

    useEffect(() => {
        setNewFilters(filters);
    }, [filters]);

    return (
        <HappeningListFilter
            {...props}
            filters={newFilters}
            onApply={onApplyFilters}
            onChange={setNewFilters}
            onReset={onReset}
        />
    );
};

HappeningListFilterContainer.defaultProps = {
    className: "",
};

export default HappeningListFilterContainer;
