/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PaymentStatus {
    Unknown = "Unknown",
    Open = "Open",
    Canceled = "Canceled",
    Pending = "Pending",
    Authorized = "Authorized",
    Expired = "Expired",
    Failed = "Failed",
    Paid = "Paid",
    Refunded = "Refunded",
}

export function PaymentStatusFromJSON(json: any): PaymentStatus {
    return PaymentStatusFromJSONTyped(json, false);
}

export function PaymentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentStatus {
    return json as PaymentStatus;
}

export function PaymentStatusToJSON(value?: PaymentStatus | null): any {
    return value as any;
}
