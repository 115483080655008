/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import { PaymentRequest, PaymentRequestFromJSON, PaymentRequestFromJSONTyped, PaymentRequestToJSON } from "./";

/**
 *
 * @export
 * @interface HappeningRegistrationResult
 */
export interface HappeningRegistrationResult {
    /**
     *
     * @type {PaymentRequest}
     * @memberof HappeningRegistrationResult
     */
    request?: PaymentRequest;
    /**
     *
     * @type {Array<string>}
     * @memberof HappeningRegistrationResult
     */
    participantIds?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof HappeningRegistrationResult
     */
    registrationIds?: Array<string>;
}

/**
 * Check if a given object implements the HappeningRegistrationResult interface.
 */
export function instanceOfHappeningRegistrationResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HappeningRegistrationResultFromJSON(json: any): HappeningRegistrationResult {
    return HappeningRegistrationResultFromJSONTyped(json, false);
}

export function HappeningRegistrationResultFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): HappeningRegistrationResult {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        request: !exists(json, "request") ? (undefined as any) : PaymentRequestFromJSON(json["request"]),
        participantIds: !exists(json, "participantIds") ? (undefined as any) : json["participantIds"],
        registrationIds: !exists(json, "registrationIds") ? (undefined as any) : json["registrationIds"],
    };
}

export function HappeningRegistrationResultToJSON(value?: HappeningRegistrationResult | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
