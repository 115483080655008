import React, { useCallback, useEffect, useRef } from "react";
import styles from "./HappeningDetail.module.scss";
import classNames from "classnames";
import { HappeningViewModel } from "../../openapi/backend";
import HappeningIntro from "../HappeningIntro/HappeningIntro";
import HappeningDetailInfoSection from "../HappeningDetailInfoSection/HappeningDetailInfoSectionContainer";
import PracticalInfoCard from "../PracticalInfoCard/PracticalInfoCard";
import HappeningContact from "../HappeningContact/HappeningContact";
import OrganisationFooter from "../OrganisationFooter/OrganisationFooter";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HappeningOrganisers from "../HappeningOrganisers/HappeningOrganisers";

export type ReusableProps = {
    onResize?: (height: number, width: number) => void;
};

type Props = ReusableProps & {
    className: string;
    happening: HappeningViewModel;
    embed?: boolean;
    hideFooter?: boolean;
};

const HappeningDetail = ({ className, happening, embed, hideFooter, onResize }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const goBack = useCallback(() => navigate(-1), [navigate]);
    const { happeningGroup } = happening;
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!containerRef.current || !onResize) {
            return;
        }

        const resizeAction = () => {
            onResize(containerRef.current?.clientHeight || 0, containerRef.current?.clientWidth || 0);
        };

        resizeAction();

        const resizeObserver = new ResizeObserver(resizeAction);
        resizeObserver.observe(containerRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [onResize]);

    return (
        <div className={classNames(styles.container, className)} ref={containerRef}>
            <div className={styles.grid}>
                <div className={styles.main}>
                    {!embed && (
                        <Button
                            className={styles.back}
                            displayType="navigation"
                            onClick={goBack}
                            label={t("navigation.back.overview")}
                        />
                    )}
                    <HappeningIntro className={styles.intro} happening={happening} />
                    <HappeningDetailInfoSection className={styles.details} happening={happening} />
                </div>
                <PracticalInfoCard className={styles.sidebar} happening={happening} />
                <HappeningContact className={styles.contact} happening={happening} />
                <HappeningOrganisers className={styles.organisers} happening={happening} />
            </div>
            {happeningGroup && !hideFooter && (
                <OrganisationFooter className={styles.footer} organisation={happeningGroup} />
            )}
        </div>
    );
};

HappeningDetail.defaultProps = {
    className: "",
};

export default HappeningDetail;
