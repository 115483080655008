/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    AnswerOutput,
    AnswerOutputFromJSON,
    AnswerOutputFromJSONTyped,
    AnswerOutputToJSON,
    AppointmentViewModel,
    AppointmentViewModelFromJSON,
    AppointmentViewModelFromJSONTyped,
    AppointmentViewModelToJSON,
    AttendencyViewModel,
    AttendencyViewModelFromJSON,
    AttendencyViewModelFromJSONTyped,
    AttendencyViewModelToJSON,
    ImageViewModel,
    ImageViewModelFromJSON,
    ImageViewModelFromJSONTyped,
    ImageViewModelToJSON,
    PaymentViewModel,
    PaymentViewModelFromJSON,
    PaymentViewModelFromJSONTyped,
    PaymentViewModelToJSON,
    RegistrationStatus,
    RegistrationStatusFromJSON,
    RegistrationStatusFromJSONTyped,
    RegistrationStatusToJSON,
} from "./";

/**
 *
 * @export
 * @interface HappeningRegistrationOutput
 */
export interface HappeningRegistrationOutput {
    /**
     *
     * @type {string}
     * @memberof HappeningRegistrationOutput
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof HappeningRegistrationOutput
     */
    happeningId: string;
    /**
     *
     * @type {string}
     * @memberof HappeningRegistrationOutput
     */
    happeningTitle: string;
    /**
     *
     * @type {string}
     * @memberof HappeningRegistrationOutput
     */
    participantId: string;
    /**
     *
     * @type {string}
     * @memberof HappeningRegistrationOutput
     */
    participantName: string;
    /**
     *
     * @type {string}
     * @memberof HappeningRegistrationOutput
     */
    contactPersonName?: string;
    /**
     *
     * @type {string}
     * @memberof HappeningRegistrationOutput
     */
    email?: string;
    /**
     *
     * @type {Date}
     * @memberof HappeningRegistrationOutput
     */
    createdOn: Date;
    /**
     *
     * @type {Date}
     * @memberof HappeningRegistrationOutput
     */
    cancelledOn?: Date;
    /**
     *
     * @type {RegistrationStatus}
     * @memberof HappeningRegistrationOutput
     */
    status: RegistrationStatus;
    /**
     *
     * @type {number}
     * @memberof HappeningRegistrationOutput
     */
    age: number;
    /**
     *
     * @type {Array<AttendencyViewModel>}
     * @memberof HappeningRegistrationOutput
     */
    attendencies: Array<AttendencyViewModel>;
    /**
     *
     * @type {Array<AppointmentViewModel>}
     * @memberof HappeningRegistrationOutput
     */
    appointments: Array<AppointmentViewModel>;
    /**
     *
     * @type {boolean}
     * @memberof HappeningRegistrationOutput
     */
    approvedForMarketing: boolean;
    /**
     *
     * @type {boolean}
     * @memberof HappeningRegistrationOutput
     */
    requiresPayment: boolean;
    /**
     *
     * @type {string}
     * @memberof HappeningRegistrationOutput
     */
    paymentId?: string;
    /**
     *
     * @type {string}
     * @memberof HappeningRegistrationOutput
     */
    paymentExternalId?: string;
    /**
     *
     * @type {boolean}
     * @memberof HappeningRegistrationOutput
     */
    paidCash?: boolean;
    /**
     *
     * @type {PaymentViewModel}
     * @memberof HappeningRegistrationOutput
     */
    payment?: PaymentViewModel;
    /**
     *
     * @type {string}
     * @memberof HappeningRegistrationOutput
     */
    notes?: string;
    /**
     *
     * @type {string}
     * @memberof HappeningRegistrationOutput
     */
    medicalNotes?: string;
    /**
     *
     * @type {boolean}
     * @memberof HappeningRegistrationOutput
     */
    isRegistrationForPaidHappening: boolean;
    /**
     *
     * @type {ImageViewModel}
     * @memberof HappeningRegistrationOutput
     */
    avatar?: ImageViewModel;
    /**
     *
     * @type {Array<AnswerOutput>}
     * @memberof HappeningRegistrationOutput
     */
    extraQuestionAnswers?: Array<AnswerOutput>;
}

/**
 * Check if a given object implements the HappeningRegistrationOutput interface.
 */
export function instanceOfHappeningRegistrationOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "happeningId" in value;
    isInstance = isInstance && "happeningTitle" in value;
    isInstance = isInstance && "participantId" in value;
    isInstance = isInstance && "participantName" in value;
    isInstance = isInstance && "createdOn" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "age" in value;
    isInstance = isInstance && "attendencies" in value;
    isInstance = isInstance && "appointments" in value;
    isInstance = isInstance && "approvedForMarketing" in value;
    isInstance = isInstance && "requiresPayment" in value;
    isInstance = isInstance && "isRegistrationForPaidHappening" in value;

    return isInstance;
}

export function HappeningRegistrationOutputFromJSON(json: any): HappeningRegistrationOutput {
    return HappeningRegistrationOutputFromJSONTyped(json, false);
}

export function HappeningRegistrationOutputFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): HappeningRegistrationOutput {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        happeningId: !exists(json, "happeningId") ? (undefined as any) : json["happeningId"],
        happeningTitle: !exists(json, "happeningTitle") ? (undefined as any) : json["happeningTitle"],
        participantId: !exists(json, "participantId") ? (undefined as any) : json["participantId"],
        participantName: !exists(json, "participantName") ? (undefined as any) : json["participantName"],
        contactPersonName: !exists(json, "contactPersonName") ? (undefined as any) : json["contactPersonName"],
        email: !exists(json, "email") ? (undefined as any) : json["email"],
        createdOn: !exists(json, "createdOn") ? (undefined as any) : new Date(json["createdOn"]),
        cancelledOn: !exists(json, "cancelledOn") ? (undefined as any) : new Date(json["cancelledOn"]),
        status: !exists(json, "status") ? (undefined as any) : RegistrationStatusFromJSON(json["status"]),
        age: !exists(json, "age") ? (undefined as any) : json["age"],
        attendencies: !exists(json, "attendencies")
            ? (undefined as any)
            : ((json["attendencies"] as Array<any>) ?? []).map(AttendencyViewModelFromJSON),
        appointments: !exists(json, "appointments")
            ? (undefined as any)
            : ((json["appointments"] as Array<any>) ?? []).map(AppointmentViewModelFromJSON),
        approvedForMarketing: !exists(json, "approvedForMarketing") ? (undefined as any) : json["approvedForMarketing"],
        requiresPayment: !exists(json, "requiresPayment") ? (undefined as any) : json["requiresPayment"],
        paymentId: !exists(json, "paymentId") ? (undefined as any) : json["paymentId"],
        paymentExternalId: !exists(json, "paymentExternalId") ? (undefined as any) : json["paymentExternalId"],
        paidCash: !exists(json, "paidCash") ? (undefined as any) : json["paidCash"],
        payment: !exists(json, "payment") ? (undefined as any) : PaymentViewModelFromJSON(json["payment"]),
        notes: !exists(json, "notes") ? (undefined as any) : json["notes"],
        medicalNotes: !exists(json, "medicalNotes") ? (undefined as any) : json["medicalNotes"],
        isRegistrationForPaidHappening: !exists(json, "isRegistrationForPaidHappening")
            ? (undefined as any)
            : json["isRegistrationForPaidHappening"],
        avatar: !exists(json, "avatar") ? (undefined as any) : ImageViewModelFromJSON(json["avatar"]),
        extraQuestionAnswers: !exists(json, "extraQuestionAnswers")
            ? (undefined as any)
            : ((json["extraQuestionAnswers"] as Array<any>) ?? []).map(AnswerOutputFromJSON),
    };
}

export function HappeningRegistrationOutputToJSON(value?: HappeningRegistrationOutput | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
