/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum UsagePurpose {
    Any = "Any",
    Management = "Management",
    Participation = "Participation",
}

export function UsagePurposeFromJSON(json: any): UsagePurpose {
    return UsagePurposeFromJSONTyped(json, false);
}

export function UsagePurposeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsagePurpose {
    return json as UsagePurpose;
}

export function UsagePurposeToJSON(value?: UsagePurpose | null): any {
    return value as any;
}
