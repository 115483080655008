import React, { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import OrganisationListFilter from "./OrganisationListFilter";
import { useOrganisationFilters, useResetFilters } from "../../hooks/useRouterHooks";
import { OrganisationFilter } from "../../hooks/useOrganisationApiHooks";

type Props = {
    className: string;
};

const OrganisationListFilterContainer = (props: Props) => {
    const [filters, setFilters] = useOrganisationFilters();
    const [newFilters, setNewFilters] = useState<OrganisationFilter>(filters);
    const resetFilters = useResetFilters();

    const debouncedOnChange = useDebouncedCallback((input: OrganisationFilter) => {
        return setFilters(input);
    }, 300);

    const onApplyFilters = useCallback(() => {
        if (newFilters) debouncedOnChange(newFilters);
    }, [debouncedOnChange, newFilters]);

    const onReset = useCallback(() => {
        setNewFilters({});
        resetFilters();
    }, [resetFilters]);

    useEffect(() => {
        setNewFilters(filters);
    }, [filters]);

    return (
        <OrganisationListFilter
            {...props}
            filters={newFilters}
            onApply={onApplyFilters}
            onChange={setNewFilters}
            onReset={onReset}
        />
    );
};

OrganisationListFilterContainer.defaultProps = {
    className: "",
};

export default OrganisationListFilterContainer;
