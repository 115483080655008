import { useState, useEffect } from "react";
import { InitialRegion } from "../constants/LocationConstants";

interface Location {
    latitude: number;
    longitude: number;
}

const useGeolocation = () => {
    const [coords, setCoords] = useState<Location>({
        latitude: InitialRegion.latitude,
        longitude: InitialRegion.longitude,
    });

    useEffect(() => {
        if (!navigator.geolocation) {
            setCoords({ latitude: InitialRegion.latitude, longitude: InitialRegion.longitude });
            return;
        }

        const successCallback = (position: GeolocationPosition) => {
            setCoords({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            });
        };

        const errorCallback = () => {
            setCoords({ latitude: InitialRegion.latitude, longitude: InitialRegion.longitude });
        };
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback, { timeout: 30000 });
    }, []);

    return coords;
};

export default useGeolocation;
