import { useParams, useSearchParams } from "react-router-dom";
import {
    MAX_AGE_KEY,
    MIN_AGE_KEY,
    DATE_END_KEY,
    DATE_START_KEY,
    EMBED_KEY,
    SHARE_KEY,
    GROUP_KEY,
    PAGE_KEY,
    SEARCH_KEY,
    SHOW_FILTERS_KEY,
    HOST_KEY,
    HIDE_FULL_KEY,
    ACCESSIBLE_ONLY_KEY,
    ORGANISATION_KEY,
    MAP_KEY,
    CATEGORY_KEY,
    POSTAL_CODE_KEY,
    MAX_DISTANCE_KEY,
    TARGET_AGES_KEY,
    DETAILS_KEY,
} from "../utils/RouterConstants";
import { Dispatch, SetStateAction, useCallback, useMemo, useRef } from "react";
import { formatDateISO } from "../utils/DateUtils";
import { HappeningFilter } from "./useHappeningsApiHooks";
import { OrganisationFilter } from "./useOrganisationApiHooks";

function arrayToComparableValue<T>(array: Array<T>) {
    return array.sort().join(",").toLowerCase();
}

function isEqualArray<T>(arr1: Array<T> | undefined, arr2: Array<T> | undefined) {
    if (!arr1 || !arr2) {
        return false;
    }

    return arrayToComparableValue(arr1) === arrayToComparableValue(arr2);
}

const numberMap = (value: string | null) => (!value || Number.isNaN(Number(value)) ? undefined : Number(value));
const dateMap = (value: string | null) => (!value || Number.isNaN(Date.parse(value)) ? undefined : Date.parse(value));
const boolMap = (value: string | null) => value === "1";

/**
 * Fetches a search parameter and maps it to the desired type.
 * To avoid dependency problems, map to primitive values so React can recognize actual differences.
 * @param key - The key for which the parameter should be found
 * @param map - A mapping function that parses the value. Should map to number, bool or string.
 * @returns
 */
function useMappedParam<T>(key: string, map: (value: string | null) => T): T {
    const [searchParams] = useSearchParams();
    return map(searchParams.get(key));
}
const useDefaultMappedParam = (key: string) => useMappedParam(key, (value) => value || undefined);
const useNumberParam = (key: string) => useMappedParam(key, numberMap);
const useDateParam = (key: string) => useMappedParam(key, dateMap);
const useBoolParam = (key: string) => useMappedParam(key, boolMap);

export function useShowFilters() {
    return useMappedParam(SHOW_FILTERS_KEY, (value) => value === "1");
}

export function useHappeningId() {
    const { happeningId } = useParams<{ happeningId: string | undefined }>();

    return happeningId;
}
export function useOrganisationId() {
    const { organisationId } = useParams<{ organisationId: string | undefined }>();
    return organisationId;
}

export function useClusterId() {
    const { clusterId } = useParams<{ clusterId: string | undefined }>();

    return clusterId;
}

export const useEmbed = () => useBoolParam(EMBED_KEY);
export const useShare = () => useBoolParam(SHARE_KEY);
export const useIsIframe = () => useMappedParam(HOST_KEY, (value) => value === "iframe");
export const useOrganisation = () => useBoolParam(ORGANISATION_KEY);
export const useMap = () => useBoolParam(MAP_KEY);
export const useModalHappeningId = () => useDefaultMappedParam(DETAILS_KEY);
export const useModalOrganisationId = () => useDefaultMappedParam(DETAILS_KEY);

type FilterSearchParam = {
    key: string;
    value?: Date | string | number;
};

export function useFilters(): [HappeningFilter, (newFilters: HappeningFilter) => void] {
    const [, setSearchParams] = useSearchParams();

    const fromDate = useDateParam(DATE_START_KEY);
    const toDate = useDateParam(DATE_END_KEY);
    const query = useDefaultMappedParam(SEARCH_KEY);
    const minAge = useNumberParam(MIN_AGE_KEY);
    const maxAge = useNumberParam(MAX_AGE_KEY);
    const full = useBoolParam(HIDE_FULL_KEY);
    const isAccessible = useBoolParam(ACCESSIBLE_ONLY_KEY);

    const filters: HappeningFilter = useMemo(
        () => ({
            query,
            fromDate: fromDate ? new Date(fromDate) : undefined,
            toDate: toDate ? new Date(toDate) : undefined,
            minAge,
            maxAge,
            full,
            isAccessible,
        }),
        [query, fromDate, toDate, minAge, maxAge, full, isAccessible],
    );

    const updateSearchParams = useCallback(
        (newFilters: FilterSearchParam[]) => {
            setSearchParams((params) => {
                newFilters.forEach((f) => {
                    if (!f.value) {
                        params.delete(f.key);
                    } else if (f.value instanceof Date) {
                        params.set(f.key, f.value ? formatDateISO(f.value as Date) ?? "" : "");
                    } else {
                        params.set(f.key, f.value?.toString() ?? undefined);
                    }
                });
                params.delete(PAGE_KEY);
                return params;
            });
        },
        [setSearchParams],
    );

    const setFiltersState = useCallback(
        ({ query, fromDate, toDate, minAge, maxAge, full, isAccessible }: HappeningFilter) => {
            const localTo = toDate ? new Date(toDate?.toISOString()) : undefined;
            localTo?.setHours(23, 59, 59); // toDate has to be at midnight to include activities on that day

            updateSearchParams([
                { key: DATE_START_KEY, value: fromDate },
                { key: DATE_END_KEY, value: localTo },
                { key: SEARCH_KEY, value: query },
                { key: MIN_AGE_KEY, value: minAge },
                { key: MAX_AGE_KEY, value: maxAge },
                { key: HIDE_FULL_KEY, value: full ? "1" : undefined },
                { key: ACCESSIBLE_ONLY_KEY, value: isAccessible ? "1" : undefined },
            ]);
        },
        [updateSearchParams],
    );

    return useMemo(() => [filters, setFiltersState], [filters, setFiltersState]);
}
export function useOrganisationFilters(): [OrganisationFilter, (newFilters: OrganisationFilter) => void] {
    const [, setSearchParams] = useSearchParams();

    const query = useDefaultMappedParam(SEARCH_KEY);
    const targetAges = useDefaultMappedParam(TARGET_AGES_KEY);
    const maxDistance = useNumberParam(MAX_DISTANCE_KEY);
    const categories = useDefaultMappedParam(CATEGORY_KEY);
    const userPostalCode = useDefaultMappedParam(POSTAL_CODE_KEY);

    const filters: OrganisationFilter = useMemo(
        () => ({
            query,
            targetAges: targetAges ? targetAges.split(",").map(Number) : undefined,
            maxDistance,
            categories: categories ? categories.split(",") : undefined,
            userPostalCode,
        }),
        [query, targetAges, maxDistance, categories, userPostalCode],
    );

    const updateSearchParams = useCallback(
        (newFilters: Array<{ key: string; value: any }>) => {
            setSearchParams((params) => {
                newFilters.forEach((f) => {
                    if (!f.value) {
                        params.delete(f.key);
                    } else if (Array.isArray(f.value)) {
                        params.set(f.key, f.value.join(","));
                    } else {
                        params.set(f.key, f.value.toString());
                    }
                });
                params.delete(PAGE_KEY); // if applicable
                return params;
            });
        },
        [setSearchParams],
    );

    const setFiltersState = useCallback(
        ({ query, targetAges, maxDistance, categories, userPostalCode }: OrganisationFilter) => {
            updateSearchParams([
                { key: SEARCH_KEY, value: query },
                { key: TARGET_AGES_KEY, value: targetAges },
                { key: MAX_DISTANCE_KEY, value: maxDistance },
                { key: CATEGORY_KEY, value: categories },
                { key: POSTAL_CODE_KEY, value: userPostalCode },
            ]);
        },
        [updateSearchParams],
    );

    return useMemo(() => [filters, setFiltersState], [filters, setFiltersState]);
}

export function useResetFilters() {
    const [, setSearchParams] = useSearchParams();

    const resetFilters = useCallback(() => {
        setSearchParams((params) => {
            params.delete(DATE_START_KEY);
            params.delete(DATE_END_KEY);
            params.delete(SEARCH_KEY);
            params.delete(MIN_AGE_KEY);
            params.delete(MAX_AGE_KEY);
            params.delete(HIDE_FULL_KEY);
            params.delete(ACCESSIBLE_ONLY_KEY);
            params.delete(PAGE_KEY);
            params.delete(POSTAL_CODE_KEY);
            params.delete(MAX_DISTANCE_KEY);
            params.delete(MIN_AGE_KEY);
            params.delete(MAX_AGE_KEY);
            params.delete(TARGET_AGES_KEY);
            params.delete(CATEGORY_KEY);
            return params;
        });
    }, [setSearchParams]);

    return resetFilters;
}

export function useGroupsFilter() {
    const [searchParams] = useSearchParams();
    const filter = useRef<Array<string>>([]);

    return useMemo(() => {
        const next = searchParams.getAll(GROUP_KEY);

        if (isEqualArray(filter.current, next)) {
            return filter.current;
        }

        filter.current = next;

        return next;
    }, [searchParams]);
}

export function usePage(): [number, Dispatch<SetStateAction<number>>] {
    const [, setSearchParams] = useSearchParams();

    const temp = useNumberParam(PAGE_KEY);
    // Should always return 1 or higher
    const page = Math.max(temp || 1, 1);

    const setPageState = useCallback<Dispatch<SetStateAction<number>>>(
        (s) => {
            const nextValue = Math.max(typeof s === "number" ? s : s(page), 1);

            if (page !== nextValue) {
                setSearchParams((params) => {
                    params.set(PAGE_KEY, nextValue.toString());

                    return params;
                });
            }
        },
        [setSearchParams, page],
    );

    return useMemo(() => [page, setPageState], [page, setPageState]);
}
