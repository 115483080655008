/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AttendencyViewModel
 */
export interface AttendencyViewModel {
    /**
     *
     * @type {number}
     * @memberof AttendencyViewModel
     */
    sessionId: number;
    /**
     *
     * @type {boolean}
     * @memberof AttendencyViewModel
     */
    present: boolean;
    /**
     *
     * @type {string}
     * @memberof AttendencyViewModel
     */
    presentReason?: string;
}

/**
 * Check if a given object implements the AttendencyViewModel interface.
 */
export function instanceOfAttendencyViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sessionId" in value;
    isInstance = isInstance && "present" in value;

    return isInstance;
}

export function AttendencyViewModelFromJSON(json: any): AttendencyViewModel {
    return AttendencyViewModelFromJSONTyped(json, false);
}

export function AttendencyViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttendencyViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        sessionId: !exists(json, "sessionId") ? (undefined as any) : json["sessionId"],
        present: !exists(json, "present") ? (undefined as any) : json["present"],
        presentReason: !exists(json, "presentReason") ? (undefined as any) : json["presentReason"],
    };
}

export function AttendencyViewModelToJSON(value?: AttendencyViewModel | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
