import React, { useCallback, ChangeEvent, useState, useEffect } from "react";
import classNames from "classnames";

import styles from "./SearchInput.module.scss";
import InputField from "../Input/InputField";
import { SearchIcon } from "../../assets/images/Images";

type Props = {
    id?: string;
    className: string;
    defaultValue?: string;
    onChange: (value?: string) => void;
    onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

const SearchInput = ({ id, className, defaultValue, onChange, onKeyUp }: Props) => {
    const [input, setInput] = useState<string | undefined>(defaultValue);

    const onChangeInput = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            const newInput = e.currentTarget.value;
            setInput(newInput);
            onChange(newInput);
        },
        [onChange],
    );

    useEffect(() => {
        setInput(defaultValue);
    }, [defaultValue]);

    return (
        <InputField
            id={id}
            className={classNames(styles.input, className)}
            value={input}
            type="text"
            icon={SearchIcon}
            onChange={onChangeInput}
            onKeyUp={onKeyUp}
        ></InputField>
    );
};

SearchInput.defaultProps = {
    className: "",
};

export default SearchInput;
