import React from "react";
import styles from "./HappeningContact.module.scss";
import classNames from "classnames";
import { HappeningViewModel } from "../../openapi/backend";
import Text from "../Text/Text";
import { useTranslation } from "react-i18next";
import ProfileIcon from "../ProfileIcon/ProfileIcon";
import { getFullName } from "../../utils/Profile";
import CopyContentButton from "../CopyContentButton/CopyContentButton";

type Props = {
    className: string;
    happening: HappeningViewModel;
};

const HappeningContact = ({ className, happening }: Props) => {
    const { t } = useTranslation();
    const { happeningGroup, contact } = happening;

    return (
        <div className={classNames(styles.container, className)}>
            <Text className={styles.description} label={t("contact.description", { name: happeningGroup?.name })} />
            {contact && (
                <div className={styles.contactContainer}>
                    <ProfileIcon className={styles.icon} profile={contact} size={60} />
                    <div className={styles.details}>
                        <Text type="bold" label={getFullName(contact)} />
                        <CopyContentButton value={contact.email} type="email">
                            <Text className={styles.email} type="inline-link" label={contact.email} />
                        </CopyContentButton>
                        {contact.phoneNumber && (
                            <CopyContentButton value={contact.phoneNumber} type="phone">
                                <Text type="body" className={styles.phoneNumber} label={contact.phoneNumber} />
                            </CopyContentButton>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

HappeningContact.defaultProps = {
    className: "",
};

export default HappeningContact;
