import React, { PropsWithChildren, useCallback } from "react";
import styles from "./CopyContentButton.module.scss";
import classNames from "classnames";
import { toastSuccess } from "../../utils/Toaster";
import { useTranslation } from "react-i18next";

type CopyType = "default" | "email" | "phone";

type Props = {
    className: string;
    value: string;
    type: CopyType;
};

const CopyContentButton = ({ className, value, type, children }: PropsWithChildren<Props>) => {
    const { t } = useTranslation();
    const onClick = useCallback(async () => {
        await navigator.clipboard.writeText(value);
        toastSuccess(t(`copy.success.${type}`));
    }, [value, t, type]);

    return (
        <button className={classNames(styles.container, className)} type="button" onClick={onClick}>
            {children}
        </button>
    );
};

CopyContentButton.defaultProps = {
    className: "",
    type: "default",
};

export default CopyContentButton;
