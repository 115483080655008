import React, { useMemo } from "react";
import styles from "./HappeningIntro.module.scss";
import classNames from "classnames";
import Title from "../Title/Title";
import { HappeningType, HappeningViewModel } from "../../openapi/backend";
import Text from "../Text/Text";
import { formatRegisterDate, formatTime, isInTheFuture, isInThePast } from "../../utils/DateUtils";
import { useTranslation } from "react-i18next";
import LinkButton from "../LinkButton/LinkButton";

type Props = {
    className: string;
    happening: HappeningViewModel;
};

const HappeningIntro = ({ className, happening }: Props) => {
    const { title, description, link, linkLabel } = happening;
    const { t } = useTranslation();
    const happeningRegistrationStartDate = happening.registrationStartDate;
    const happeningRegistrationEndDate = happening.registrationEndDate;
    const isExternal = useMemo(() => happening.type === HappeningType.ExternalHappening, [happening]);

    return (
        <div className={classNames(styles.container, className)}>
            <Title className={styles.title} heading="h1" label={title} />
            <Text className={styles.description} label={description} />

            {link && !isExternal && (
                <LinkButton
                    className={styles.link}
                    label={linkLabel || t("intro.link")}
                    to={link}
                    displayType="navigation"
                    target="_blank"
                    rel="noopener noreferrer"
                />
            )}

            {happeningRegistrationStartDate &&
                happeningRegistrationEndDate &&
                isInThePast(happeningRegistrationStartDate) &&
                isInTheFuture(happeningRegistrationEndDate) && (
                    <Text
                        className={styles.description}
                        label={t("registration.attentionRegister", {
                            date: formatRegisterDate(happeningRegistrationEndDate),
                            time: formatTime(happeningRegistrationEndDate),
                        })}
                    />
                )}
        </div>
    );
};

HappeningIntro.defaultProps = {
    className: "",
};

export default HappeningIntro;
