import React, { useCallback, useEffect, useRef, useState } from "react";
import NearbyMapContainer from "../../components/PerformanceMap/NearbyMapContainer";
import { useIsIframe, useMap, useModalHappeningId, useShowFilters } from "../../hooks/useRouterHooks";
import OrganisationDetailModalContainer from "../../components/OrganisationDetailModal/OrganisationDetailModalContainer";
import OrganisationListFilterContainer from "../../components/OrganisationListFilter/OrganisationListFilterContainer";
import NearbyListContainer from "../../components/NearbyList/NearbyListContainer";
import styles from "./NearByOverviewPage.module.scss";
import classNames from "classnames";
import { lazyBeforeLoad } from "../../utils/Workarounds";
type Props = {
    className?: string;
};
const OrganisationFocusFilters = () => {
    document.getElementById("search-field")?.focus();
};
function NearByOverviewPage({ className }: Props) {
    const showFilters = useShowFilters();
    const OrganisationId = useModalHappeningId();
    const [modalHeight, setModalHeight] = useState(0);
    const onModalResize = useCallback((height: number) => {
        // Picked 125 because it worked on all resizings.
        setModalHeight(height + 125);
    }, []);
    const isMap = useMap();
    const isIframe = useIsIframe();
    const containerRef = useRef<HTMLDivElement>(null);
    const isInit = useRef(false); // Used for preventing iframe scrolling to top
    const onInit = useCallback(() => (isInit.current = true), []);

    useEffect(() => {
        lazyBeforeLoad();
    }, []);

    useEffect(() => {
        if (!containerRef.current || !isIframe) {
            return;
        }

        const resizeAction = () => {
            const isDetailOpen = !!OrganisationId;
            const clientHeight = containerRef.current?.clientHeight || 300;
            window.parent.postMessage(
                {
                    height: isMap ? "100%" : Math.max(clientHeight, isDetailOpen ? modalHeight : 0),
                    details: !!OrganisationId,
                    isInit: isInit.current,
                },
                "*",
            );
        };

        resizeAction();

        const resizeObserver = new ResizeObserver(resizeAction);
        resizeObserver.observe(containerRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [OrganisationId, isIframe, modalHeight, isMap]);

    return (
        <>
            <div ref={containerRef} className={classNames(styles.container, isMap && styles.mapContainer, className)}>
                {showFilters && <OrganisationListFilterContainer className={styles.filters} />}
                {isMap ? (
                    <NearbyMapContainer className={styles.map} />
                ) : (
                    <NearbyListContainer
                        onInit={onInit}
                        focusFilters={OrganisationFocusFilters}
                        className={styles.list}
                    />
                )}
                <OrganisationDetailModalContainer fillPage={!isIframe} onResize={onModalResize} />
            </div>
        </>
    );
}
NearByOverviewPage.defaultProps = {
    className: "",
};

export default NearByOverviewPage;
