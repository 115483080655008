import React, { useCallback, useEffect, useMemo, useRef } from "react";
import HappeningList from "./HappeningList";
import { useGetHappeningsOverview } from "../../hooks/useHappeningsApiHooks";
import { RequestState } from "../../types/RequestState";
import { useClusterId, useFilters, useGroupsFilter, usePage } from "../../hooks/useRouterHooks";
import FriendlyError from "../FriendlyError/FriendlyError";

type Props = {
    className: string;
    focusFilters: () => void;
    onInit?: () => void;
    showBackButton?: boolean;
};

const HappeningListContainer = ({ onInit, ...props }: Props) => {
    const paramClusterId = useClusterId();
    const [{ state, value, error }, fetchRequest] = useGetHappeningsOverview(paramClusterId);
    const isInit = useRef(false);
    const [filters] = useFilters();
    const groups = useGroupsFilter();
    const [page, setPage] = usePage();
    const fetch = useCallback(
        (p: number) => fetchRequest(p, { ...filters, groups }, 20),
        [filters, fetchRequest, groups],
    );

    const onChangePage = useCallback(
        (event: { selected: number }) => {
            setPage(event.selected + 1);
        },
        [setPage],
    );

    const isLoading = useMemo(() => state === RequestState.LOADING, [state]);

    const fetchPage = useCallback(() => fetch(page), [fetch, page]);

    useEffect(() => {
        fetchPage();
    }, [fetchPage]);

    // @NOTE(Lejun) This hook is entirely used as a hack for our iframe implementation
    // This acts as a wait before enabling auto scroll on each change reported to the parent frame.
    useEffect(() => {
        if (!onInit || isInit.current) {
            return;
        }

        if ([RequestState.ERROR, RequestState.LOADING].includes(state)) {
            isInit.current = true;
            // Delay calling, because the drawing events will happen after this.
            // Therefore wait a little bit before calling.
            setTimeout(() => {
                onInit();
            }, 300);
        }
    }, [onInit, state]);

    return (
        <FriendlyError state={state} error={error} retry={fetchPage}>
            <HappeningList
                {...props}
                loading={isLoading}
                list={value?.items}
                getPage={onChangePage}
                pageCount={value?.meta.totalPages}
                page={page - 1}
            />
        </FriendlyError>
    );
};

HappeningListContainer.defaultProps = {
    className: "",
};

export default HappeningListContainer;
