import React from "react";
import styles from "./InlineLoading.module.scss";

type Props = {
    className: string;
};

const InlineLoading = ({ className }: Props) => {
    return (
        <div className={`${styles.container} ${className}`}>
            <div className={styles.loadEllipsis}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

InlineLoading.defaultProps = {
    className: "",
};

export default InlineLoading;
