import { useCallback, useContext, useMemo } from "react";
import { TagApiContext } from "../contexts/TagApiContext";
import { OrganizerViewModel, TagCollection } from "../openapi/backend";
import { Env } from "../utils/Env";
import { ApiCallState, useApiCall, useApiCallback } from "./useApiCall";

export const useTagCollection = (): [ApiCallState<TagCollection>, () => void] => {
    const api = useContext(TagApiContext);
    const callback = useCallback(
        () => api.getTagCollection({ version: Env.REACT_APP_BACKEND_VERSION, compactOnly: true }),
        [api],
    );
    const [{ state, error, value }, call] = useApiCallback(callback);

    const collection = useMemo<TagCollection | undefined>(() => value, [value]);

    return [{ state, error, value: collection } as any, call];
};

export const useOrganizers = (): ApiCallState<OrganizerViewModel[]> => {
    const api = useContext(TagApiContext);
    const callback = useCallback(() => api.getOrganizers({ version: Env.REACT_APP_BACKEND_VERSION }), [api]);

    return useApiCall(callback);
};
