import React, { useCallback, useEffect, useMemo } from "react";
import { useGetHappeningsMap } from "../../hooks/useHappeningsApiHooks";
import { useClusterId, useFilters, useGroupsFilter } from "../../hooks/useRouterHooks";
import PerformanceMap from "./PerformanceMap";
import { RequestState } from "../../types/RequestState";
import FriendlyError from "../FriendlyError/FriendlyError";
import { APIProvider } from "@vis.gl/react-google-maps";
import { Env } from "../../utils/Env";
interface Props {
    className?: string;
}

const HappeningMapContainer = ({ className }: Props) => {
    const paramClusterId = useClusterId();
    const [{ state, value, error }, fetchRequest] = useGetHappeningsMap(paramClusterId);

    const [filters] = useFilters();
    const groups = useGroupsFilter();

    const isLoading = useMemo(() => state === RequestState.LOADING, [state]);
    const fetch = useCallback(() => fetchRequest({ ...filters, groups }), [filters, fetchRequest, groups]);
    useEffect(() => {
        fetch();
    }, [fetch]);

    const mapId = Env.REACT_APP_GOOGLE_MAP_ID;
    return (
        <FriendlyError state={state} error={error} retry={fetch}>
            <APIProvider apiKey={Env.REACT_APP_GOOGLE_API_KEY}>
                <PerformanceMap
                    className={className}
                    loading={isLoading}
                    points={value}
                    type="happening"
                    mapId={mapId}
                />
            </APIProvider>
        </FriendlyError>
    );
};

export default HappeningMapContainer;
