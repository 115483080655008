/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AnswerOutput
 */
export interface AnswerOutput {
    /**
     *
     * @type {number}
     * @memberof AnswerOutput
     */
    questionId: number;
    /**
     *
     * @type {string}
     * @memberof AnswerOutput
     */
    question: string;
    /**
     *
     * @type {string}
     * @memberof AnswerOutput
     */
    value: string;
}

/**
 * Check if a given object implements the AnswerOutput interface.
 */
export function instanceOfAnswerOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "questionId" in value;
    isInstance = isInstance && "question" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function AnswerOutputFromJSON(json: any): AnswerOutput {
    return AnswerOutputFromJSONTyped(json, false);
}

export function AnswerOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnswerOutput {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        questionId: !exists(json, "questionId") ? (undefined as any) : json["questionId"],
        question: !exists(json, "question") ? (undefined as any) : json["question"],
        value: !exists(json, "value") ? (undefined as any) : json["value"],
    };
}

export function AnswerOutputToJSON(value?: AnswerOutput | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
