import { Settings, DateTime } from "luxon";

Settings.defaultLocale = "nl-NL";

export function formatDateMed(date: Date) {
    return DateTime.fromJSDate(date).toFormat("ccc, d LLL");
}

export function formatDateShort(date: Date) {
    return DateTime.fromJSDate(date).toFormat("dd-LL");
}

export function formatDate(date: Date) {
    return DateTime.fromJSDate(date).toFormat("cccc d LLLL");
}

export function formatRegisterDate(date: Date) {
    return DateTime.fromJSDate(date).toFormat("ccc d LLLL yyyy");
}

export function formatRegisterDateMonthShort(date: Date) {
    return DateTime.fromJSDate(date).toFormat("ccc d LLL. yyyy");
}

export function formatDateISO(date: Date) {
    return DateTime.fromJSDate(date).toISODate();
}

export function formatTime(date: Date) {
    return DateTime.fromJSDate(date).toFormat("HH:mm");
}

export function formatSessionTime(from: Date, to: Date) {
    return `${formatTime(from)} - ${formatTime(to)}`;
}

export function isInThePast(date: Date) {
    return DateTime.fromJSDate(date) < DateTime.now();
}

export function isNowOrInThePast(date: Date) {
    return DateTime.fromJSDate(date) <= DateTime.now();
}

export function isInTheFuture(date: Date) {
    return DateTime.fromJSDate(date) > DateTime.now();
}

export function isNowOrInTheFuture(date: Date) {
    return DateTime.fromJSDate(date) >= DateTime.now();
}

export function toEndOfDay(date: Date | undefined) {
    if (!date) {
        return undefined;
    }

    return DateTime.fromJSDate(date).endOf("day").toJSDate();
}
