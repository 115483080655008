import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronRight, PlayIcon } from "../../assets/images/Images";
import { useMemo } from "react";
import classNames from "classnames";
import styles from "./Carousel.module.scss";
import { MediaPreviewType } from "../../types/MediaPreview";
import { getVideoThumbnail } from "../../utils/VideoThumbnail";
import Loading from "../Loading/Loading";
import { lazyBeforeLoad } from "../../utils/Workarounds";

type PrevNextButtonProps = {
    enabled: boolean;
    onClick: () => void;
    direction: "previous" | "next";
    className?: string;
};

export const PrevNextButton = ({ enabled, onClick, direction, className }: PrevNextButtonProps) => {
    const { t } = useTranslation();

    const altLabel = useMemo(
        () => (direction === "previous" ? t("carousel.previous") : t("carousel.next")),
        [t, direction],
    );
    return (
        <button
            className={classNames(styles.prevNextButton, className)}
            onClick={onClick}
            disabled={!enabled}
            type="button"
            aria-label={altLabel}
        >
            <img
                src={ChevronRight}
                className={classNames(styles.arrowImage, direction === "previous" && styles.previous)}
                alt={altLabel}
            />
        </button>
    );
};

type DotButtonProps = {
    selected: boolean;
    onClick: () => void;
};

export const DotButton = ({ selected, onClick }: DotButtonProps) => {
    return (
        <button
            className={classNames(styles.dot, selected && styles.dotSelected)}
            type="button"
            onClick={onClick}
            aria-label="Slide"
            data-testid="DotButton"
        />
    );
};

type PlayButtonProps = {
    href: string;
    className?: string;
};

export const PlayButton = ({ href, className }: PlayButtonProps) => {
    const { t } = useTranslation();

    return (
        <div className={classNames(styles.playContainer, className)}>
            <a
                className={classNames(styles.playButton)}
                href={href}
                target="_blank"
                rel="noreferrer"
                aria-label={t("carousel.play")}
            >
                <img src={PlayIcon} className={styles.play} alt={t("carousel.play")} />
            </a>
        </div>
    );
};

const PLACEHOLDER_SRC = `data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==`;

type PropType = {
    media: MediaPreviewType;
    inView: boolean;
};

export const Slide = ({ media, inView }: PropType) => {
    const [hasLoaded, setHasLoaded] = useState(false);
    const src = useMemo(() => (media.isVideo ? getVideoThumbnail(media.href) : media.href), [media]);

    const setLoaded = useCallback(() => {
        if (inView) {
            setHasLoaded(true);
        }
    }, [inView]);

    useEffect(() => {
        lazyBeforeLoad();
    }, []);

    return (
        <div className={styles.slide}>
            {!hasLoaded && <Loading className={styles.loading} />}
            <img
                className={classNames(styles.slideContent, hasLoaded ? styles.show : styles.hide)}
                onLoad={setLoaded}
                src={inView ? src : PLACEHOLDER_SRC}
                alt={media.altText}
                data-src={src}
            />
            {media.isVideo && inView && (
                <PlayButton href={media.href} className={classNames(hasLoaded ? styles.show : styles.hide)} />
            )}
        </div>
    );
};
