import React, { useCallback, useEffect, useMemo } from "react";
import HappeningList from "./HappeningList";
import { GeoViewport } from "../../utils/MapUtils";
import { useGetHappeningClusterListCallback } from "../../hooks/useHappeningsApiHooks";
import { useClusterId, useFilters, useGroupsFilter } from "../../hooks/useRouterHooks";
import { RequestState } from "../../types/RequestState";
import { useParseBackendError } from "../../hooks/useBackendError";
import { toastError } from "../../utils/Toaster";

interface Props {
    viewport: GeoViewport;
}

export default function HappeningListClusterContainer({ viewport }: Props) {
    const paramClusterId = useClusterId();
    const [filters] = useFilters();
    const groups = useGroupsFilter();
    const [{ value, state, error }, getHappenings] = useGetHappeningClusterListCallback(viewport, paramClusterId);
    const fetch = useCallback(() => getHappenings({ ...filters, groups }), [filters, getHappenings, groups]);
    const { title, message } = useParseBackendError(error);

    if (state === RequestState.ERROR) {
        toastError(`${title} \n ${message}`);
    }

    useEffect(() => {
        fetch();
    }, [fetch]);

    const isLoading = useMemo(() => state === RequestState.LOADING, [state]);

    return <HappeningList list={value?.items} loading={isLoading} showOnMap />;
}
