/**
 * Source: https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/src/hooks/UseApiCall.ts
 *
 * @TODO(Lejun) Extract to npm for shared usage if this works handy. Together with `BackendApiContext`
 */

import { useCallback, useEffect, useState } from "react";
import { RequestState } from "../types/RequestState";
import { toastBusy } from "../utils/Toaster";
import { t } from "i18next";

export type ApiCallNoResult = {
    state: RequestState.IDLE | RequestState.LOADING;
    value?: never;
    error?: never;
};

export type ApiCallResolved<T> = {
    state: RequestState.DONE;
    value: T;
    error?: never;
};

export type ApiCallFailed = {
    state: RequestState.ERROR;
    value?: never;
    error?: Response;
};

const userPatienceLimit = 10000;

export type ApiCallState<T> = ApiCallNoResult | ApiCallResolved<T> | ApiCallFailed;

export type ApiFunction<T, A extends Array<any>> = (...args: A) => Promise<T>;

export const readyForRequest = (state: RequestState): boolean => state !== RequestState.LOADING;
export const readyForSingleRequest = (state: RequestState): boolean =>
    readyForRequest(state) && state !== RequestState.DONE;

export function useApiCall<T>(apiCall: () => Promise<T>): ApiCallState<T> {
    const [callState, go] = useApiCallback(apiCall);

    useEffect(() => {
        go();
    }, [go]);

    return callState;
}

export function useApiCallback<T, A extends Array<any>>(
    apiCall: ApiFunction<T, A>,
    shouldThrow?: boolean,
): [ApiCallState<T>, ApiFunction<T | undefined, A>] {
    const [callState, setCallState] = useState<ApiCallState<T>>(() => ({ state: RequestState.IDLE }));

    const callback = useCallback(
        async (...args: A) => {
            setCallState({ state: RequestState.LOADING });
            let timerId: NodeJS.Timeout | undefined;

            try {
                timerId = setTimeout(() => toastBusy(t("error.toast.busy")), userPatienceLimit);
                const response = await apiCall(...args);

                clearTimeout(timerId);
                setCallState({ state: RequestState.DONE, value: response });
                return response;
            } catch (error: any) {
                clearTimeout(timerId);

                if (!(error instanceof Response)) {
                    setCallState({ state: RequestState.ERROR, error: undefined });
                    throw error;
                }

                setCallState({ state: RequestState.ERROR, error: error });
                if (shouldThrow) {
                    throw error;
                }
            }
        },
        [apiCall, shouldThrow],
    );

    return [callState, callback];
}
