import React, { useCallback, useEffect, useMemo, useRef } from "react";
import NearbyList from "./NearbyList";
import { useGetOrganisationsListCallback } from "../../hooks/useOrganisationApiHooks";
import FriendlyError from "../FriendlyError/FriendlyError";
import { useOrganisationFilters, usePage } from "../../hooks/useRouterHooks";
import { RequestState } from "../../types/RequestState";
interface Props {
    className?: string;
    onInit?: () => void;
    focusFilters: () => void;
}
function NearbyListContainer({ onInit, ...props }: Props) {
    const [filters] = useOrganisationFilters();
    const [{ value, state, error }, fetchRequest] = useGetOrganisationsListCallback();
    const [page, setPage] = usePage();
    const fetch = useCallback((p: number) => fetchRequest(20, p, { ...filters }), [filters, fetchRequest]);
    const isInit = useRef(false);
    const onChangePage = useCallback(
        (event: { selected: number }) => {
            setPage(event.selected + 1);
        },
        [setPage],
    );

    const isLoading = useMemo(() => state === RequestState.LOADING, [state]);

    const fetchPage = useCallback(() => fetch(page), [fetch, page]);

    //@NOTE(Lejun) This hook is entirely used as a hack for our iframe implementation
    // This acts as a wait before enabling auto scroll on each change reported to the parent frame.
    useEffect(() => {
        if (!onInit || isInit.current) {
            return;
        }

        if ([RequestState.ERROR, RequestState.LOADING].includes(state)) {
            isInit.current = true;
            // Delay calling, because the drawing events will happen after this.
            // Therefore wait a little bit before calling.
            setTimeout(() => {
                onInit();
            }, 300);
        }
    }, [onInit, state]);

    useEffect(() => {
        fetchPage();
    }, [fetchPage]);
    return (
        <FriendlyError state={state} error={error} retry={fetchPage}>
            <NearbyList
                {...props}
                loading={isLoading}
                list={value?.items}
                page={page - 1}
                getPage={onChangePage}
                pageCount={value?.meta.totalPages}
            />
        </FriendlyError>
    );
}

export default NearbyListContainer;
