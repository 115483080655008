import React, { useEffect, useMemo } from "react";
import HappeningPreview from "./HappeningPreview";
import { useTranslation } from "react-i18next";
import { useGetHappeningCard } from "../../hooks/useHappeningsApiHooks";
import { RequestState } from "../../types/RequestState";
import { useHappeningId } from "../../hooks/useRouterHooks";
import FriendlyError from "../FriendlyError/FriendlyError";
import { CustomErrorMessage } from "../../types/CustomErrorMessage";
import Loading from "../Loading/Loading";
import styles from "./HappeningPreview.module.scss";

type Props = {
    className: string;
};

const HappeningPreviewContainer = (props: Props) => {
    const { t } = useTranslation();
    const [{ state, value: happening, error }, fetchRequest] = useGetHappeningCard();
    const happeningId = useHappeningId();

    const customErrorMessages = useMemo<Array<CustomErrorMessage>>(
        () => [
            {
                status: 403,
                message: t("error.message.singleHappening403"),
            },
        ],
        [t],
    );

    useEffect(() => {
        if (happeningId) {
            fetchRequest(happeningId);
        }
    }, [fetchRequest, happeningId]);

    if ([RequestState.IDLE, RequestState.LOADING].includes(state)) {
        return <Loading className={styles.loading} />;
    }

    return (
        <FriendlyError state={state} error={error} custom={customErrorMessages}>
            {happening && <HappeningPreview {...props} happening={happening} />}
        </FriendlyError>
    );
};

HappeningPreviewContainer.defaultProps = {
    className: "",
};

export default HappeningPreviewContainer;
