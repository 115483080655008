import LocalizedStrings from "react-localization";
export const strings = new LocalizedStrings({
    nl: {
        ageAllAgesDescription: "Alle leeftijden",
        ageRangeDescription: "{0} t/m {1} jaar",
        ageMinimumAndUpDescription: "{0}+ jaar",
        ageElderDescription: "{0}+ jaar",
        clusterText: "cluster van {0} markers",
        unlimited: "Onbepert",
        openOnMap: "Bekijken op kaart",
        socials: "Website & socials",
        contactPerson: "Contactpersoon",
        multipleLocations: "Meerdere locaties",
    },
});
