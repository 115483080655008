import React from "react";
import styles from "./Loading.module.scss";
import classNames from "classnames";
import { LoadingIcon } from "../../assets/images/Images";
import Text from "../Text/Text";
import { useTranslation } from "react-i18next";

type Props = {
    className: string;
};

const Loading = ({ className }: Props) => {
    const { t } = useTranslation();

    return (
        <div className={classNames(styles.container, className)}>
            <img src={LoadingIcon} className={styles.spinner} alt="Icon" />
            <Text className={styles.text} type="footer" label={t("loading.description")} />
        </div>
    );
};

Loading.defaultProps = {
    className: "",
};

export default Loading;
