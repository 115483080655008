import { useEffect } from "react";

// Solution based on https://stackoverflow.com/a/76216986
// See top comments for context
export function useIgnoreWebpackOverlayErrors() {
    useEffect(() => {
        const cb = (e: ErrorEvent) => {
            if (e.message === "ResizeObserver loop limit exceeded" || e.message === "Script error.") {
                const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
                const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute("style", "display: none");
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute("style", "display: none");
                }
            }
        };

        window.addEventListener("error", cb);

        return () => window.removeEventListener("error", cb);
    }, []);
}
