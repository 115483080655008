import React from "react";
import styles from "./Paginate.module.scss";
import classNames from "classnames";
import ReactPaginate, { ReactPaginateProps } from "react-paginate";
import { ChevronRight } from "../../assets/images/Images";
import { useTranslation } from "react-i18next";

type Props = ReactPaginateProps & {
    className: string;
};

const ARROW_SIZE = 16;

const Previous = () => {
    const { t } = useTranslation();
    return (
        <img
            src={ChevronRight}
            className={styles.previous}
            alt={t("paginate.previous")}
            width={ARROW_SIZE}
            height={ARROW_SIZE}
        />
    );
};

const Next = () => {
    const { t } = useTranslation();
    return (
        <img
            src={ChevronRight}
            className={styles.next}
            alt={t("paginate.next")}
            width={ARROW_SIZE}
            height={ARROW_SIZE}
        />
    );
};

const Paginate = ({ className, ...props }: Props) => {
    return (
        <ReactPaginate
            renderOnZeroPageCount={null}
            containerClassName={classNames(styles.container, className)}
            disabledClassName={styles.disabled}
            activeClassName={styles.active}
            pageLinkClassName={styles.pageNumber}
            previousLabel={<Previous />}
            nextLabel={<Next />}
            {...props}
        />
    );
};

Paginate.defaultProps = {
    className: "",
};

export default Paginate;
