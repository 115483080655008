import classNames from "classnames";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Button from "../Button/Button";
import SearchInput from "../SearchInput/SearchInput";
import Text from "../Text/Text";
import styles from "./OrganisationListFilter.module.scss";
import DropdownField from "../Dropdown/DropdownField";
import { ChevronDown } from "../../assets/images/Images";
import { OrganisationFilter } from "../../hooks/useOrganisationApiHooks";
import { TagsContext } from "../../contexts/TagsContext";
import PostalCode from "../PostalCode/PostalCode";
import MultiRangeSlider from "../MultiRangeSlider/MultiRangeSlider";
import Slider from "../Slider/Slider";
import { strings } from "../../Localization/strings";
import { DEFAULT_AGE_RANGE, FilterConstants } from "../../constants/FilterContstants";
import { TagViewModel } from "../../openapi/backend";

type Props = {
    className: string;
    filters: OrganisationFilter;
    onChange: (filters: OrganisationFilter) => void;
    onApply: () => void;
    onReset: () => void;
};

const getCategoryNamesByIds = (ids: string[], categories: TagViewModel[]): string[] => {
    return ids
        .map((id) => {
            const category = categories.find((category) => category.id === id);
            return category ? category.name : null;
        })
        .filter((name): name is string => name !== null);
};

const OrganisationListFilter = ({ className, filters, onChange, onApply, onReset }: Props) => {
    const { t } = useTranslation();
    const { categories } = useContext(TagsContext);
    const initialCategory = useMemo(
        () => (filters?.categories && filters.categories.length > 0 ? filters.categories : []),
        [filters?.categories],
    );
    const initialCategoryNames = useMemo(
        () => getCategoryNamesByIds(initialCategory, categories),
        [initialCategory, categories],
    );
    const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
    useEffect(() => {
        if (initialCategoryNames.length > 0) {
            setSelectedCategory(initialCategoryNames);
        }
    }, [initialCategoryNames]);
    const maxDistanceText =
        !filters?.maxDistance || filters.maxDistance > FilterConstants.MAXIMUM_DISTANCE_KILOMETERS
            ? `${strings.unlimited}`
            : `${filters.maxDistance}`;
    // Media queries taken from mixins.cardGrid
    const isTripleColumn = useMediaQuery({ minWidth: 901, maxWidth: 1399 });
    const isSingleColumn = useMediaQuery({ minWidth: 0, maxWidth: 617 });

    const SearchButton = useCallback(
        () => (
            <Button
                className={classNames(styles.applyButton)}
                displayType="default"
                label={t("overview.filters.button")}
                onClick={onApply}
            />
        ),
        [onApply, t],
    );

    const categoryOptions = useMemo(
        () =>
            categories.map((category) => ({
                id: category.id,
                value: category.name,
            })),
        [categories],
    );

    const handleCategoryChange = useCallback(
        (selectedValues: string[]) => {
            const updatedCategories = selectedValues
                .map((value) => {
                    const selectedCategory = categoryOptions.find((option) => option.value === value);
                    return selectedCategory ? selectedCategory.id : null;
                })
                .filter((id): id is string => id !== null); // Type guard to ensure id is a string

            onChange({ ...filters, categories: updatedCategories });
        },
        [categoryOptions, filters, onChange],
    );

    const handleReset = useCallback(() => {
        // Reset filters to default values
        const defaultFilters: OrganisationFilter = {
            ...filters,
            targetAges: DEFAULT_AGE_RANGE,
            categories: [],
            maxDistance: undefined,
            userPostalCode: undefined,
            query: "",
        };
        onChange(defaultFilters);
        setSelectedCategory([]);
        onReset();
    }, [filters, onChange, onReset]);

    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.row}>
                <div className={styles.filter}>
                    <Text className={styles.filterName} label={`${t("overview.filters.chooseCategory")}:`} />
                    <DropdownField
                        options={categoryOptions}
                        icon={ChevronDown}
                        selectedValues={selectedCategory}
                        onChange={handleCategoryChange}
                    />
                </div>
                <div className={styles.filter}>
                    <Text className={styles.filterName} label={`${t("overview.filters.postalCode")}:`} />
                    <PostalCode
                        defaultValue={filters?.userPostalCode ?? ""}
                        onChange={(value) => onChange({ ...filters, userPostalCode: value })}
                    />
                </div>
                <div className={styles.filter}>
                    <Text className={styles.filterName} label={`${t("overview.filters.distance")}:`} />
                    <Slider
                        maxRange={FilterConstants.MAXIMUM_DISTANCE_KILOMETERS + 1}
                        onChange={(value) => onChange({ ...filters, maxDistance: value })}
                        maxDistanceText={maxDistanceText}
                    />
                </div>
                <div className={styles.filter}>
                    <Text className={styles.filterName} label={`${t("overview.filters.age")}:`} />
                    <MultiRangeSlider
                        targetAges={filters?.targetAges ?? DEFAULT_AGE_RANGE}
                        minRange={DEFAULT_AGE_RANGE[0]}
                        maxRange={DEFAULT_AGE_RANGE[1]}
                        onChange={(value) => onChange({ ...filters, ...value })}
                    />
                </div>
                <div className={classNames(!isSingleColumn && !isTripleColumn && styles.duo, styles.filter)}>
                    <Text className={styles.filterName} label={`${t("overview.filters.search")}:`} />
                    <div className={classNames(styles.searchAction)}>
                        <SearchInput
                            id="search-field"
                            className={styles.single}
                            defaultValue={filters?.query ?? ""}
                            onChange={(value) => onChange({ ...filters, query: value })}
                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    onApply();
                                }
                            }}
                        />
                        {!isSingleColumn && <SearchButton />}
                    </div>
                </div>
            </div>
            <div className={styles.row}>
                <Button
                    className={styles.resetButton}
                    displayType="link"
                    label={t("overview.filters.reset")}
                    onClick={handleReset}
                />
            </div>
            {isSingleColumn && (
                <div className={classNames(styles.row, styles.endAligned)}>
                    <SearchButton />
                </div>
            )}
        </div>
    );
};

OrganisationListFilter.defaultProps = {
    className: "",
};

export default OrganisationListFilter;
