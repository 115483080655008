import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./DropdownField.module.scss";

export interface DropdownOption {
    id: string;
    value: string;
}

export interface DropdownFieldProps {
    options: DropdownOption[];
    icon?: string;
    selectedValues?: string[];
    onChange: (value: string[]) => void;
}

const DropdownField = ({ options, icon, selectedValues, onChange }: DropdownFieldProps) => {
    const [currentSelection, setCurrentSelection] = useState<string[]>(selectedValues || []);
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        setCurrentSelection(selectedValues || []);
    }, [selectedValues]);
    const handleTagClick = (value: string) => {
        const updatedSelections = currentSelection.includes(value)
            ? currentSelection.filter((option) => option !== value)
            : [...currentSelection, value];
        setCurrentSelection(updatedSelections);
        onChange(updatedSelections);
        setShowDropdown(false);
    };

    return (
        <div className={classNames(styles.container)}>
            <div className={styles.inputField} onClick={() => setShowDropdown(!showDropdown)}>
                {currentSelection.map((value) => (
                    <span key={value} className={styles.dropdownTag}>
                        {value}{" "}
                        <span
                            className={styles.removeTag}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleTagClick(value);
                            }}
                        >
                            x
                        </span>
                    </span>
                ))}
                <img src={icon} alt="Dropdown icon" className={styles.dropdownArrow} />
            </div>
            {showDropdown && (
                <div className={styles.dropdownList}>
                    {options.map((option) => (
                        <div
                            key={option.id}
                            className={classNames(styles.dropdownItem, {
                                [styles.selected]: currentSelection.includes(option.value),
                            })}
                            onClick={() => handleTagClick(option.value)}
                        >
                            {option.value}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DropdownField;
