/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import { PaymentStatus, PaymentStatusFromJSON, PaymentStatusFromJSONTyped, PaymentStatusToJSON } from "./";

/**
 *
 * @export
 * @interface PaymentViewModel
 */
export interface PaymentViewModel {
    /**
     *
     * @type {string}
     * @memberof PaymentViewModel
     */
    id: string;
    /**
     *
     * @type {PaymentStatus}
     * @memberof PaymentViewModel
     */
    status: PaymentStatus;
    /**
     *
     * @type {number}
     * @memberof PaymentViewModel
     */
    amount: number;
    /**
     *
     * @type {boolean}
     * @memberof PaymentViewModel
     */
    isCash: boolean;
    /**
     *
     * @type {string}
     * @memberof PaymentViewModel
     */
    externalId?: string;
}

/**
 * Check if a given object implements the PaymentViewModel interface.
 */
export function instanceOfPaymentViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "isCash" in value;

    return isInstance;
}

export function PaymentViewModelFromJSON(json: any): PaymentViewModel {
    return PaymentViewModelFromJSONTyped(json, false);
}

export function PaymentViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        status: !exists(json, "status") ? (undefined as any) : PaymentStatusFromJSON(json["status"]),
        amount: !exists(json, "amount") ? (undefined as any) : json["amount"],
        isCash: !exists(json, "isCash") ? (undefined as any) : json["isCash"],
        externalId: !exists(json, "externalId") ? (undefined as any) : json["externalId"],
    };
}

export function PaymentViewModelToJSON(value?: PaymentViewModel | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
