import React from "react";
import { useModalHappeningId } from "../../hooks/useRouterHooks";
import HappeningDetailModal, { Props as ModalProps } from "./HappeningDetailModal";
import { ReusableProps as HappeningDetailProps } from "../HappeningDetail/HappeningDetail";

type Props = Pick<ModalProps, "fillPage" | "onAfterOpen"> & HappeningDetailProps;

const HappeningDetailModalContainer = (props: Props) => {
    const happeningId = useModalHappeningId();

    return <HappeningDetailModal isOpen={!!happeningId} happeningId={happeningId} {...props} />;
};

HappeningDetailModalContainer.defaultProps = {
    className: "",
    fillPage: true,
};

export default HappeningDetailModalContainer;
