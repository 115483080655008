/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    ProfileShortOutput,
    ProfileShortOutputFromJSON,
    ProfileShortOutputFromJSONTyped,
    ProfileShortOutputToJSON,
} from "./";

/**
 *
 * @export
 * @interface HappeningTimeAndDateViewModel
 */
export interface HappeningTimeAndDateViewModel {
    /**
     *
     * @type {number}
     * @memberof HappeningTimeAndDateViewModel
     */
    id: number;
    /**
     *
     * @type {Date}
     * @memberof HappeningTimeAndDateViewModel
     */
    sessionStart: Date;
    /**
     *
     * @type {Date}
     * @memberof HappeningTimeAndDateViewModel
     */
    sessionEnd: Date;
    /**
     *
     * @type {boolean}
     * @memberof HappeningTimeAndDateViewModel
     */
    cancelled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof HappeningTimeAndDateViewModel
     */
    allMyProfilesAbsent: boolean;
    /**
     *
     * @type {number}
     * @memberof HappeningTimeAndDateViewModel
     */
    anonymousAttendees?: number;
    /**
     *
     * @type {ProfileShortOutput}
     * @memberof HappeningTimeAndDateViewModel
     */
    appointer?: ProfileShortOutput;
    /**
     *
     * @type {string}
     * @memberof HappeningTimeAndDateViewModel
     */
    notes?: string;
}

/**
 * Check if a given object implements the HappeningTimeAndDateViewModel interface.
 */
export function instanceOfHappeningTimeAndDateViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sessionStart" in value;
    isInstance = isInstance && "sessionEnd" in value;
    isInstance = isInstance && "allMyProfilesAbsent" in value;

    return isInstance;
}

export function HappeningTimeAndDateViewModelFromJSON(json: any): HappeningTimeAndDateViewModel {
    return HappeningTimeAndDateViewModelFromJSONTyped(json, false);
}

export function HappeningTimeAndDateViewModelFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): HappeningTimeAndDateViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        sessionStart: !exists(json, "sessionStart") ? (undefined as any) : new Date(json["sessionStart"]),
        sessionEnd: !exists(json, "sessionEnd") ? (undefined as any) : new Date(json["sessionEnd"]),
        cancelled: !exists(json, "cancelled") ? (undefined as any) : json["cancelled"],
        allMyProfilesAbsent: !exists(json, "allMyProfilesAbsent") ? (undefined as any) : json["allMyProfilesAbsent"],
        anonymousAttendees: !exists(json, "anonymousAttendees") ? (undefined as any) : json["anonymousAttendees"],
        appointer: !exists(json, "appointer") ? (undefined as any) : ProfileShortOutputFromJSON(json["appointer"]),
        notes: !exists(json, "notes") ? (undefined as any) : json["notes"],
    };
}

export function HappeningTimeAndDateViewModelToJSON(value?: HappeningTimeAndDateViewModel | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
