import React, { PropsWithChildren, useCallback } from "react";
import styles from "./Checkbox.module.scss";
import classNames from "classnames";
import Text from "../Text/Text";

type Props = {
    className: string;
    id: string;
    name: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
    label: string;
};

const Checkbox = ({ className, label, id, onChange, children, ...props }: PropsWithChildren<Props>) => {
    const doChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
        (e) => {
            onChange(e.target.checked);
        },
        [onChange],
    );

    return (
        <fieldset className={classNames(styles.container, className)}>
            <input id={id} type="checkbox" {...props} onChange={doChange} className={styles.checkbox} />
            <label className={styles.label} htmlFor={id}>
                <Text label={label} type="label-for-input" />
            </label>
            {children}
        </fieldset>
    );
};

Checkbox.defaultProps = {
    className: "",
};

export default Checkbox;
