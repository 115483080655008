/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    AttendencyViewModel,
    AttendencyViewModelFromJSON,
    AttendencyViewModelFromJSONTyped,
    AttendencyViewModelToJSON,
    HappeningRegistrationOutput,
    HappeningRegistrationOutputFromJSON,
    HappeningRegistrationOutputFromJSONTyped,
    HappeningRegistrationOutputToJSON,
    QuestionOutput,
    QuestionOutputFromJSON,
    QuestionOutputFromJSONTyped,
    QuestionOutputToJSON,
    TeamMemberViewModel,
    TeamMemberViewModelFromJSON,
    TeamMemberViewModelFromJSONTyped,
    TeamMemberViewModelToJSON,
} from "./";

/**
 *
 * @export
 * @interface TeamViewModel
 */
export interface TeamViewModel {
    /**
     *
     * @type {string}
     * @memberof TeamViewModel
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof TeamViewModel
     */
    happeningId: string;
    /**
     *
     * @type {string}
     * @memberof TeamViewModel
     */
    happeningTitle: string;
    /**
     *
     * @type {QuestionOutput}
     * @memberof TeamViewModel
     */
    extraQuestion: QuestionOutput;
    /**
     *
     * @type {string}
     * @memberof TeamViewModel
     */
    teamName: string;
    /**
     *
     * @type {Array<TeamMemberViewModel>}
     * @memberof TeamViewModel
     */
    members: Array<TeamMemberViewModel>;
    /**
     *
     * @type {boolean}
     * @memberof TeamViewModel
     */
    approvedForMarketing: boolean;
    /**
     *
     * @type {boolean}
     * @memberof TeamViewModel
     */
    requiresPayment: boolean;
    /**
     *
     * @type {Array<AttendencyViewModel>}
     * @memberof TeamViewModel
     */
    attendencies: Array<AttendencyViewModel>;
    /**
     *
     * @type {Array<HappeningRegistrationOutput>}
     * @memberof TeamViewModel
     */
    registrations: Array<HappeningRegistrationOutput>;
}

/**
 * Check if a given object implements the TeamViewModel interface.
 */
export function instanceOfTeamViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "happeningId" in value;
    isInstance = isInstance && "happeningTitle" in value;
    isInstance = isInstance && "extraQuestion" in value;
    isInstance = isInstance && "teamName" in value;
    isInstance = isInstance && "members" in value;
    isInstance = isInstance && "approvedForMarketing" in value;
    isInstance = isInstance && "requiresPayment" in value;
    isInstance = isInstance && "attendencies" in value;
    isInstance = isInstance && "registrations" in value;

    return isInstance;
}

export function TeamViewModelFromJSON(json: any): TeamViewModel {
    return TeamViewModelFromJSONTyped(json, false);
}

export function TeamViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        happeningId: !exists(json, "happeningId") ? (undefined as any) : json["happeningId"],
        happeningTitle: !exists(json, "happeningTitle") ? (undefined as any) : json["happeningTitle"],
        extraQuestion: !exists(json, "extraQuestion")
            ? (undefined as any)
            : QuestionOutputFromJSON(json["extraQuestion"]),
        teamName: !exists(json, "teamName") ? (undefined as any) : json["teamName"],
        members: !exists(json, "members")
            ? (undefined as any)
            : ((json["members"] as Array<any>) ?? []).map(TeamMemberViewModelFromJSON),
        approvedForMarketing: !exists(json, "approvedForMarketing") ? (undefined as any) : json["approvedForMarketing"],
        requiresPayment: !exists(json, "requiresPayment") ? (undefined as any) : json["requiresPayment"],
        attendencies: !exists(json, "attendencies")
            ? (undefined as any)
            : ((json["attendencies"] as Array<any>) ?? []).map(AttendencyViewModelFromJSON),
        registrations: !exists(json, "registrations")
            ? (undefined as any)
            : ((json["registrations"] as Array<any>) ?? []).map(HappeningRegistrationOutputFromJSON),
    };
}

export function TeamViewModelToJSON(value?: TeamViewModel | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
