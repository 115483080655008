import { useContext, useCallback } from "react";
import { useApiCallback } from "./useApiCall";
import { GetOrganisationInfoMapItemsRequest, MapItemViewModel } from "../openapi/backend";
import { Env } from "../utils/Env";
import { OrganisationInfoApiContext } from "../contexts/OrganisationApiContext";
import { getCorrectedAgeRange } from "../utils/FilterUtils";
import { GeoViewport } from "../utils/MapUtils";

export type OrganisationFilter = Pick<
    GetOrganisationInfoMapItemsRequest,
    "query" | "targetAges" | "maxDistance" | "areas" | "categories" | "userPostalCode"
>;
export const useGetOrganisationsMapCallback = () => {
    const api = useContext(OrganisationInfoApiContext);
    const callback = useCallback(
        (filters: OrganisationFilter) => {
            return api.getOrganisationInfoMapItems({
                ...filters,
                targetAges: getCorrectedAgeRange(filters.targetAges),
                version: Env.REACT_APP_BACKEND_VERSION,
            }) as unknown as Promise<MapItemViewModel[]>;
        },
        [api],
    );

    return useApiCallback(callback);
};
export const useGetOrganisationsClusterListCallback = (viewport: GeoViewport) => {
    const api = useContext(OrganisationInfoApiContext);
    const callback = useCallback(
        (filters: OrganisationFilter, itemsPerPage?: number, page?: number) => {
            return api.getOrganisationInfoItemsForAppList({
                itemsPerPage,
                page,
                ...filters,
                targetAges: getCorrectedAgeRange(filters.targetAges),
                ...viewport,
                version: Env.REACT_APP_BACKEND_VERSION,
            });
        },
        [api, viewport],
    );

    return useApiCallback(callback);
};

export const useGetOrganisationsListCallback = () => {
    const api = useContext(OrganisationInfoApiContext);
    const callback = useCallback(
        (
            itemsPerPage: number,
            page: number,
            filters: OrganisationFilter,
            userLocationLatitude?: number,
            userLocationLongitude?: number,
        ) => {
            return api.getOrganisationInfoItemsForAppList({
                itemsPerPage,
                page,
                ...filters,
                targetAges: getCorrectedAgeRange(filters.targetAges),
                userLocationLatitude,
                userLocationLongitude,
                version: Env.REACT_APP_BACKEND_VERSION,
            });
        },
        [api],
    );

    return useApiCallback(callback);
};

export const useGetOrganisationInfoCallback = () => {
    const api = useContext(OrganisationInfoApiContext);
    const callback = useCallback(
        (id: string) => {
            return api.getOrganisationInfo({ id, version: Env.REACT_APP_BACKEND_VERSION });
        },
        [api],
    );
    return useApiCallback(callback);
};
