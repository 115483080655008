import { useCallback, useContext } from "react";
import { RegistrationsApiContext } from "../contexts/RegistrationsApiContext";
import { Env } from "../utils/Env";
import { useApiCallback } from "./useApiCall";

const useRegistrationsApi = () => useContext(RegistrationsApiContext);

export function useIntentParticipation() {
    const api = useRegistrationsApi();

    const callback = useCallback(
        (happeningId: string) => api.intentParticipation({ version: Env.REACT_APP_BACKEND_VERSION, happeningId }),
        [api],
    );

    return useApiCallback(callback);
}
