/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    MapGeo,
    MapGeoFromJSON,
    MapGeoFromJSONTyped,
    MapGeoToJSON,
    MapItemProperties,
    MapItemPropertiesFromJSON,
    MapItemPropertiesFromJSONTyped,
    MapItemPropertiesToJSON,
} from "./";

/**
 *
 * @export
 * @interface MapItemViewModel
 */
export interface MapItemViewModel {
    /**
     *
     * @type {string}
     * @memberof MapItemViewModel
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof MapItemViewModel
     */
    type: string;
    /**
     *
     * @type {MapGeo}
     * @memberof MapItemViewModel
     */
    geometry: MapGeo;
    /**
     *
     * @type {MapItemProperties}
     * @memberof MapItemViewModel
     */
    properties: MapItemProperties;
}

/**
 * Check if a given object implements the MapItemViewModel interface.
 */
export function instanceOfMapItemViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "geometry" in value;
    isInstance = isInstance && "properties" in value;

    return isInstance;
}

export function MapItemViewModelFromJSON(json: any): MapItemViewModel {
    return MapItemViewModelFromJSONTyped(json, false);
}

export function MapItemViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MapItemViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        type: !exists(json, "type") ? (undefined as any) : json["type"],
        geometry: !exists(json, "geometry") ? (undefined as any) : MapGeoFromJSON(json["geometry"]),
        properties: !exists(json, "properties") ? (undefined as any) : MapItemPropertiesFromJSON(json["properties"]),
    };
}

export function MapItemViewModelToJSON(value?: MapItemViewModel | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
