/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import { ImageViewModel, ImageViewModelFromJSON, ImageViewModelFromJSONTyped, ImageViewModelToJSON } from "./";

/**
 *
 * @export
 * @interface UserShortOutput
 */
export interface UserShortOutput {
    /**
     *
     * @type {string}
     * @memberof UserShortOutput
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof UserShortOutput
     */
    givenName: string;
    /**
     *
     * @type {string}
     * @memberof UserShortOutput
     */
    familyName: string;
    /**
     *
     * @type {string}
     * @memberof UserShortOutput
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof UserShortOutput
     */
    phoneNumber?: string;
    /**
     *
     * @type {ImageViewModel}
     * @memberof UserShortOutput
     */
    avatar?: ImageViewModel;
}

/**
 * Check if a given object implements the UserShortOutput interface.
 */
export function instanceOfUserShortOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "givenName" in value;
    isInstance = isInstance && "familyName" in value;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function UserShortOutputFromJSON(json: any): UserShortOutput {
    return UserShortOutputFromJSONTyped(json, false);
}

export function UserShortOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserShortOutput {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        givenName: !exists(json, "givenName") ? (undefined as any) : json["givenName"],
        familyName: !exists(json, "familyName") ? (undefined as any) : json["familyName"],
        email: !exists(json, "email") ? (undefined as any) : json["email"],
        phoneNumber: !exists(json, "phoneNumber") ? (undefined as any) : json["phoneNumber"],
        avatar: !exists(json, "avatar") ? (undefined as any) : ImageViewModelFromJSON(json["avatar"]),
    };
}

export function UserShortOutputToJSON(value?: UserShortOutput | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
