import React from "react";
import styles from "./PartnerIcon.module.scss";
import classNames from "classnames";
import { Props as AvatarProps } from "../AvatarIcon/AvatarIcon";
import { OrganizerViewModel } from "../../openapi/backend";
import OrganisationIcon from "../OrganisationIcon/OrganisationIcon";
import Text from "../Text/Text";

type Props = Omit<AvatarProps, "src" | "backgroundColor" | "name"> & {
    partner: OrganizerViewModel;
};

const PartnerIcon = ({ className, partner, ...props }: Props) => {
    return (
        <div className={classNames(styles.container, className)}>
            <OrganisationIcon organisation={partner} {...props} />
            <Text label={partner.name} />
        </div>
    );
};

PartnerIcon.defaultProps = {
    className: "",
};

export default PartnerIcon;
