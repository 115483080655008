import React, { useMemo } from "react";
import styles from "./DateSessionLine.module.scss";
import classNames from "classnames";
import { HappeningTimeAndDateViewModel } from "../../openapi/backend";
import Text from "../Text/Text";
import { useTranslation } from "react-i18next";
import { formatDate, formatSessionTime, isInThePast } from "../../utils/DateUtils";
import { capitalize } from "../../utils/StringUtils";

type Props = {
    className: string;
    session: HappeningTimeAndDateViewModel;
};

const DateSessionLine = ({ className, session }: Props) => {
    const { t } = useTranslation();

    const statusStyle = useMemo(() => {
        switch (true) {
            case session.cancelled:
                return styles.cancelled;
            case isInThePast(session.sessionEnd):
                return styles.past;
            default:
                return "";
        }
    }, [session]);

    return (
        <div className={classNames(styles.container, statusStyle, className)}>
            <Text
                className={classNames(styles.text, styles.date)}
                type="bold"
                label={`${capitalize(formatDate(session.sessionStart))} `}
            />
            <Text
                className={classNames(styles.text, styles.time)}
                label={formatSessionTime(session.sessionStart, session.sessionEnd)}
            />
            {session.cancelled && (
                <Text className={classNames(styles.text, styles.suffix)} label={` ${t("sessions.cancelledSuffix")}`} />
            )}
        </div>
    );
};

DateSessionLine.defaultProps = {
    className: "",
};

export default DateSessionLine;
