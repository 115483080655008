import React, { useMemo } from "react";
import styles from "./Map.module.scss";
import classNames from "classnames";
import { MapContainer, Marker, TileLayer, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css"; // need to import this somewhere or the map will look wack
import { LocationMarkerIcon } from "../../assets/images/Images";
import L, { LatLngExpression } from "leaflet";
import { LocationViewModel } from "../../openapi/backend";

const width = 20;
const height = 30;

let DefaultIcon = L.icon({
    iconUrl: LocationMarkerIcon,
    iconSize: [width, height],
    iconAnchor: [width / 2, height],
    tooltipAnchor: [0, 0],
});

type Props = {
    className: string;
    location: LocationViewModel;
};

function TooltipContent({ address }: { address: string }) {
    const lines = useMemo(() => address.split(", "), [address]);

    return (
        <p className={styles.tooltipText}>
            {lines.map((line, index) => (
                <React.Fragment key={line}>
                    {line} {index !== lines.length - 1 && <br />}
                </React.Fragment>
            ))}
        </p>
    );
}

const Map = ({ className, location }: Props) => {
    const center = useMemo(() => [location.latitude, location.longitude] as LatLngExpression, [location]);

    return (
        <MapContainer
            className={classNames(styles.container, className)}
            center={center}
            zoom={15}
            attributionControl={false}
            zoomControl={true}
            scrollWheelZoom={true}
        >
            <TileLayer
                url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                subdomains={["mt0", "mt1", "mt2", "mt3"]}
                maxZoom={20}
                crossOrigin="anonymous"
            />
            <Marker position={center} icon={DefaultIcon} alt={`Marker_${location.address.split(", ")[0]}`}>
                <Tooltip direction="right" offset={[width / 2 + 2, (-height * 3) / 5]}>
                    <TooltipContent address={location.address} />
                </Tooltip>
            </Marker>
        </MapContainer>
    );
};

Map.defaultProps = {
    className: "",
};

export default Map;
