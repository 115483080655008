import React from "react";
import styles from "./LinkButton.module.scss";
import classNames from "classnames";
import { Link, LinkProps } from "react-router-dom";

type ButtonType = "default" | "hollow" | "navigation";

export type Props = LinkProps & {
    label: string;
    displayType: ButtonType;
    disabled?: boolean;
};

const LinkButton = ({ className, label, displayType, disabled, ...props }: Props) => {
    return (
        <Link
            className={classNames(styles.container, styles[displayType], disabled && styles.disabled, className)}
            data-testid="LinkButton"
            aria-disabled={disabled ? "true" : "false"}
            {...props}
        >
            {label}
        </Link>
    );
};

LinkButton.defaultProps = {
    className: "",
    displayType: "default",
};

export default LinkButton;
