import React from "react";
import { ToastContainer as Container, ToastContainerProps, toast } from "react-toastify";

export function toastSuccess(message: string) {
    toast.success(message);
}

export function toastBusy(message: string) {
    toast.warn(message, {
        toastId: "busy",
        autoClose: 5000,
    });
}

export function toastError(message: string) {
    toast.error(message, { toastId: "error", autoClose: 5000 });
}

export function clearToasts() {
    toast.dismiss();
}

type Props = ToastContainerProps;

export function ToastContainer(props: Props) {
    return (
        <Container
            position="bottom-center"
            autoClose={3000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
            toastClassName="toast__base-text"
            {...props}
        />
    );
}
