/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface LocationViewModel
 */
export interface LocationViewModel {
    /**
     *
     * @type {number}
     * @memberof LocationViewModel
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof LocationViewModel
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof LocationViewModel
     */
    address: string;
    /**
     *
     * @type {number}
     * @memberof LocationViewModel
     */
    longitude: number;
    /**
     *
     * @type {number}
     * @memberof LocationViewModel
     */
    latitude: number;
}

/**
 * Check if a given object implements the LocationViewModel interface.
 */
export function instanceOfLocationViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "longitude" in value;
    isInstance = isInstance && "latitude" in value;

    return isInstance;
}

export function LocationViewModelFromJSON(json: any): LocationViewModel {
    return LocationViewModelFromJSONTyped(json, false);
}

export function LocationViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        name: !exists(json, "name") ? (undefined as any) : json["name"],
        address: !exists(json, "address") ? (undefined as any) : json["address"],
        longitude: !exists(json, "longitude") ? (undefined as any) : json["longitude"],
        latitude: !exists(json, "latitude") ? (undefined as any) : json["latitude"],
    };
}

export function LocationViewModelToJSON(value?: LocationViewModel | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
