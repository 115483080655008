import React, { useMemo } from "react";
import styles from "./AttentionCardLabel.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Text from "../Text/Text";
import { isInThePast } from "../../utils/DateUtils";

type Props = {
    className: string;
    spotsLeft: number;
    maxNumberOfRegistrations: number;
    isGroupHappening?: boolean;
    hasSpotInTeamForIndividuals: boolean;
    registrationEndDate?: Date;
};

const AttentionCardLabel = ({
    className,
    spotsLeft,
    maxNumberOfRegistrations,
    isGroupHappening,
    hasSpotInTeamForIndividuals,
    registrationEndDate,
}: Props) => {
    const { t } = useTranslation();
    const { shouldShow, style, label } = useMemo(() => {
        let style,
            label = "";

        switch (true) {
            case maxNumberOfRegistrations === 0:
            case spotsLeft > 10: // we don't care about the number of spots left
            case registrationEndDate && isInThePast(registrationEndDate):
                break;
            case spotsLeft > 0:
                label = t(isGroupHappening ? "attentionCardLabel.spotsLeftGroup" : "attentionCardLabel.spotsLeft", {
                    count: spotsLeft,
                });
                style = styles.limitedSpots;
                break;
            case hasSpotInTeamForIndividuals:
                label = t("attentionCardLabel.spotsLeftGroupIndividual");
                style = styles.limitedSpots;
                break;
            case spotsLeft <= 0:
                label = t("attentionCardLabel.reserveList");
                style = styles.reserveList;
                break;
        }

        const shouldShow = !!label;
        return { shouldShow, style, label };
    }, [t, maxNumberOfRegistrations, spotsLeft, isGroupHappening, hasSpotInTeamForIndividuals, registrationEndDate]);

    if (!shouldShow) {
        return null;
    }

    return (
        <div className={classNames(styles.container, style, className)}>
            <Text label={label} type="extra-small-bold" />
        </div>
    );
};

AttentionCardLabel.defaultProps = {
    className: "",
};

export default AttentionCardLabel;
