/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum UserSubset {
    Participants = "Participants",
    AllOrganisations = "AllOrganisations",
    InternalOrganisations = "InternalOrganisations",
    PartnerOrganisations = "PartnerOrganisations",
    SelectedOrganisations = "SelectedOrganisations",
    NotVisibleForParticipation = "NotVisibleForParticipation",
}

export function UserSubsetFromJSON(json: any): UserSubset {
    return UserSubsetFromJSONTyped(json, false);
}

export function UserSubsetFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSubset {
    return json as UserSubset;
}

export function UserSubsetToJSON(value?: UserSubset | null): any {
    return value as any;
}
