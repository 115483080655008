enum VideoStreamType {
    Youtube,
    Vimeo,
    Unknown,
}

type VideoItem = {
    id?: string;
    type: VideoStreamType;
};

const youtubeRegex =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/; // eslint-disable-line

const vimeoRegex =
    /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/; // eslint-disable-line

const processVideoUrl = (url: string): VideoItem => {
    if (youtubeRegex.test(url)) {
        const match = youtubeRegex.exec(url);
        return { id: match![5], type: VideoStreamType.Youtube };
    } else if (vimeoRegex.test(url)) {
        const match = vimeoRegex.exec(url);
        return { id: match![4], type: VideoStreamType.Vimeo };
    }

    return { type: VideoStreamType.Unknown };
};

const generateVideoThumbnail = (video: VideoItem) => {
    if (!video.id) {
        return "";
    }

    switch (video.type) {
        case VideoStreamType.Youtube:
            return `https://i.ytimg.com/vi/${video.id}/hqdefault.jpg`;
        case VideoStreamType.Vimeo:
            return `https://vumbnail.com/${video.id}.jpg`;
        default:
            return "";
    }
};

export const getVideoThumbnail = (url: string): string => {
    const videoType = processVideoUrl(url);

    if (videoType.type !== VideoStreamType.Unknown) {
        return generateVideoThumbnail(videoType);
    }

    return "";
};
