import React from "react";
import styles from "./GoToFiltersButton.module.scss";
import classNames from "classnames";
import Button from "../Button/Button";
import Text from "../Text/Text";
import { useTranslation } from "react-i18next";

type Props = {
    className: string;
    focusFilters: () => void;
};

const GoToFiltersButton = ({ className, focusFilters }: Props) => {
    const { t } = useTranslation();

    return (
        <div className={classNames(styles.container, className)}>
            <Text label={t("list.goToFilters1")} type="small-bold" />
            <Button displayType="link" label={t("list.goToFilters2")} onClick={focusFilters} />
        </div>
    );
};

GoToFiltersButton.defaultProps = {
    className: "",
    focusFilters: () => {},
};

export default GoToFiltersButton;
