import React from "react";
import classNames from "classnames";
import styles from "./InputField.module.scss";
import { HtmlInputProps } from "../../types/ReusableTypes";

export interface InputFieldProps extends HtmlInputProps {
    invalid?: boolean;
    icon?: string;
}

const InputField = ({ className, readOnly, invalid, type, icon, ...props }: InputFieldProps) => {
    return (
        <div className={classNames(styles.container, className, invalid && styles.invalid)}>
            <input
                className={classNames(styles.inputField)}
                disabled={!!readOnly}
                data-testid="InputField"
                type={type}
                {...props}
                autoComplete="off"
            />

            {icon ? <img className={styles.icon} src={icon} /> : undefined}
        </div>
    );
};
export default InputField;
