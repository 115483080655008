import React, { useCallback, useEffect, useState, useRef } from "react";
import styles from "./MultiRangeSlider.module.scss";

type TargetAges = {
    targetAges: number[];
};

interface MultiRangeSliderProps {
    targetAges: number[];
    minRange: number;
    maxRange: number;
    onChange: (targetAges: TargetAges) => void;
}

const MultiRangeSlider: React.FC<MultiRangeSliderProps> = ({ minRange, maxRange, targetAges, onChange }) => {
    const [minVal, setMinVal] = useState<number>(minRange);
    const [maxVal, setMaxVal] = useState<number>(maxRange);
    const range = useRef<HTMLDivElement>(null);
    const onChangeRef = useRef(onChange);

    // Sync internal state with prop changes
    useEffect(() => {
        if (targetAges.length === 2) {
            setMinVal(targetAges[0]);
            setMaxVal(targetAges[1]);
        }
    }, [targetAges]);
    const getPercent = useCallback(
        (value: number) => ((value - minRange) / (maxRange - minRange)) * 100,
        [minRange, maxRange],
    );

    const updateRangeStyle = useCallback(() => {
        const minPercent = getPercent(minVal);
        const maxPercent = getPercent(maxVal);

        if (range.current) {
            range.current.style.left = `${minPercent}%`;
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [minVal, maxVal, getPercent]);

    useEffect(() => {
        updateRangeStyle();
    }, [minVal, maxVal, updateRangeStyle]);

    // Call onChange when minVal or maxVal changes
    useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);

    const handleSelect = useCallback(() => {
        onChangeRef.current({ targetAges: [minVal, maxVal] });
    }, [minVal, maxVal]);

    useEffect(() => {
        handleSelect();
    }, [minVal, maxVal, handleSelect]);

    const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Math.min(Number(e.currentTarget.value), maxVal - 1);
        setMinVal(value);
    };

    const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Math.max(Number(e.currentTarget.value), minVal + 1);
        setMaxVal(value);
    };

    return (
        <div className={styles.container}>
            <input
                type="range"
                min={minRange}
                max={maxRange}
                value={minVal}
                onChange={handleMinChange}
                className={`${styles.thumb} ${styles["thumb--left"]}`}
                style={{ zIndex: minVal > maxVal - 100 ? 5 : undefined }}
            />
            <input
                type="range"
                min={minRange}
                max={maxRange}
                value={maxVal}
                onChange={handleMaxChange}
                className={`${styles.thumb} ${styles["thumb--right"]}`}
            />

            <div className={styles.slider}>
                <div className={styles.slider__track} />
                <div ref={range} className={styles.slider__range} />
                <div className={styles.slider__leftValue}>{minVal}</div>
                <div className={styles.slider__rightValue}>{maxVal}</div>
            </div>
        </div>
    );
};

export default MultiRangeSlider;
