/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    ImageViewModel,
    ImageViewModelFromJSON,
    ImageViewModelFromJSONTyped,
    ImageViewModelToJSON,
    LocationViewModel,
    LocationViewModelFromJSON,
    LocationViewModelFromJSONTyped,
    LocationViewModelToJSON,
    OrganisationExternalLinkViewModel,
    OrganisationExternalLinkViewModelFromJSON,
    OrganisationExternalLinkViewModelFromJSONTyped,
    OrganisationExternalLinkViewModelToJSON,
    TagViewModel,
    TagViewModelFromJSON,
    TagViewModelFromJSONTyped,
    TagViewModelToJSON,
} from "./";

/**
 *
 * @export
 * @interface OrganisationInfoAppListViewModel
 */
export interface OrganisationInfoAppListViewModel {
    /**
     *
     * @type {string}
     * @memberof OrganisationInfoAppListViewModel
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof OrganisationInfoAppListViewModel
     */
    title: string;
    /**
     *
     * @type {TagViewModel}
     * @memberof OrganisationInfoAppListViewModel
     */
    organisationTag?: TagViewModel;
    /**
     *
     * @type {TagViewModel}
     * @memberof OrganisationInfoAppListViewModel
     */
    category: TagViewModel;
    /**
     *
     * @type {number}
     * @memberof OrganisationInfoAppListViewModel
     */
    minimumAge: number;
    /**
     *
     * @type {number}
     * @memberof OrganisationInfoAppListViewModel
     */
    maximumAge: number;
    /**
     *
     * @type {Array<ImageViewModel>}
     * @memberof OrganisationInfoAppListViewModel
     */
    images: Array<ImageViewModel>;
    /**
     *
     * @type {Array<OrganisationExternalLinkViewModel>}
     * @memberof OrganisationInfoAppListViewModel
     */
    externalLinks: Array<OrganisationExternalLinkViewModel>;
    /**
     *
     * @type {LocationViewModel}
     * @memberof OrganisationInfoAppListViewModel
     */
    nearestLocation?: LocationViewModel;
    /**
     *
     * @type {boolean}
     * @memberof OrganisationInfoAppListViewModel
     */
    isFavorited: boolean;
}

/**
 * Check if a given object implements the OrganisationInfoAppListViewModel interface.
 */
export function instanceOfOrganisationInfoAppListViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "minimumAge" in value;
    isInstance = isInstance && "maximumAge" in value;
    isInstance = isInstance && "images" in value;
    isInstance = isInstance && "externalLinks" in value;
    isInstance = isInstance && "isFavorited" in value;

    return isInstance;
}

export function OrganisationInfoAppListViewModelFromJSON(json: any): OrganisationInfoAppListViewModel {
    return OrganisationInfoAppListViewModelFromJSONTyped(json, false);
}

export function OrganisationInfoAppListViewModelFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): OrganisationInfoAppListViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        title: !exists(json, "title") ? (undefined as any) : json["title"],
        organisationTag: !exists(json, "organisationTag")
            ? (undefined as any)
            : TagViewModelFromJSON(json["organisationTag"]),
        category: !exists(json, "category") ? (undefined as any) : TagViewModelFromJSON(json["category"]),
        minimumAge: !exists(json, "minimumAge") ? (undefined as any) : json["minimumAge"],
        maximumAge: !exists(json, "maximumAge") ? (undefined as any) : json["maximumAge"],
        images: !exists(json, "images")
            ? (undefined as any)
            : ((json["images"] as Array<any>) ?? []).map(ImageViewModelFromJSON),
        externalLinks: !exists(json, "externalLinks")
            ? (undefined as any)
            : ((json["externalLinks"] as Array<any>) ?? []).map(OrganisationExternalLinkViewModelFromJSON),
        nearestLocation: !exists(json, "nearestLocation")
            ? (undefined as any)
            : LocationViewModelFromJSON(json["nearestLocation"]),
        isFavorited: !exists(json, "isFavorited") ? (undefined as any) : json["isFavorited"],
    };
}

export function OrganisationInfoAppListViewModelToJSON(value?: OrganisationInfoAppListViewModel | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
