import React from "react";
import styles from "./OrganisationDetailInfoSection.module.scss";
import classNames from "classnames";
import { OrganisationInfoViewModel } from "../../openapi/backend";
import DetailLine from "../DetailLine/DetailLine";
import Text from "../Text/Text";
import { Link } from "react-router-dom";
import Title from "../Title/Title";
import { strings } from "../../Localization/strings";
import { truncateAddressLabel } from "../../utils/TruncateString";
import { useAgeLabel } from "../../hooks/useAgeLabel";
import { externalGoogleMapsLink } from "../../utils/MapUtils";

type Props = {
    className: string;
    organisation: OrganisationInfoViewModel;
};

const OrganisationDetailInfoSection = ({ className, organisation }: Props) => {
    const { locations } = organisation;
    const getAgeRangeText = useAgeLabel(organisation.minimumAge, organisation.maximumAge);

    return (
        <div className={classNames(styles.container, className)}>
            <DetailLine type="location" iconClassName={styles.icon}>
                {locations.map((location, index) => (
                    <Link
                        to={externalGoogleMapsLink(location.latitude, location.longitude, location.address)}
                        target="_blank"
                        className={classNames(styles.location, index === 0 && styles.firstLocation)}
                        key={location.id}
                    >
                        <Title heading="h2" label={location.name} />
                        <Text label={truncateAddressLabel(location.address)} />
                        <Text type="details-link" label={strings.openOnMap} />
                    </Link>
                ))}
            </DetailLine>
            <DetailLine className={styles.ageText} type="targetGroupGroup" iconClassName={styles.icon}>
                <Text type="info-details" label={getAgeRangeText} />
            </DetailLine>
            {organisation.externalLinks.length > 0 && (
                <DetailLine type="price">
                    <Title heading="h2" label={strings.socials} />
                    <>
                        {organisation.externalLinks.map((link, index) => (
                            <Link to={link.url} key={index}>
                                <Text type="details-link" label={link.name} />
                            </Link>
                        ))}
                    </>
                </DetailLine>
            )}
        </div>
    );
};

OrganisationDetailInfoSection.defaultProps = {
    className: "",
};

export default OrganisationDetailInfoSection;
