import React from "react";
import styles from "./HappeningCardPage.module.scss";
import classNames from "classnames";
import HappeningPreviewContainer from "../../components/HappeningPreview/HappeningPreviewContainer";

type Props = {
    className: string;
};

const HappeningCardPage = ({ className }: Props) => {
    return (
        <div className={classNames(styles.container, className)}>
            <HappeningPreviewContainer />
        </div>
    );
};

HappeningCardPage.defaultProps = {
    className: "",
};

export default HappeningCardPage;
