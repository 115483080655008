import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export function getAgeLabel(minAge: number, maxAge: number) {
    switch (true) {
        case minAge > 64:
            return "targetAge.elder";
        case minAge > 1 && maxAge >= 99:
            return "targetAge.mininumAndUp";
        case minAge >= 0 && maxAge >= 99:
            return "targetAge.all";
        default:
            return "targetAge.range";
    }
}
export function useAgeLabel(minAge: number, maxAge: number) {
    const { t } = useTranslation();
    return useMemo(() => t(getAgeLabel(minAge, maxAge), { from: minAge, to: maxAge }), [minAge, maxAge, t]);
}
