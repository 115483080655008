import React from "react";
import styles from "./HintButton.module.scss";
import classNames from "classnames";
import { Tooltip, ITooltip } from "react-tooltip";
import { HintIcon } from "../../assets/images/Images";
import { useTranslation } from "react-i18next";

type Props = Omit<ITooltip, "className" | "html"> & {
    className: string;
    unsafeHtml?: string;
};

const HintButton = ({ className, unsafeHtml, ...props }: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <a
                className={classNames(styles.container, className)}
                data-tooltip-id={props.id}
                tabIndex={0}
                data-tooltip-html={unsafeHtml}
            >
                <img src={HintIcon} className={styles.icon} alt={t("icon.hint")} />
            </a>
            <Tooltip {...props} className={styles.tooltip} />
        </>
    );
};

HintButton.defaultProps = {
    className: "",
};

export default HintButton;
