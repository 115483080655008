/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    DayOfWeek,
    DayOfWeekFromJSON,
    DayOfWeekFromJSONTyped,
    DayOfWeekToJSON,
    MonthlyRecurrenceType,
    MonthlyRecurrenceTypeFromJSON,
    MonthlyRecurrenceTypeFromJSONTyped,
    MonthlyRecurrenceTypeToJSON,
    RecurrenceType,
    RecurrenceTypeFromJSON,
    RecurrenceTypeFromJSONTyped,
    RecurrenceTypeToJSON,
} from "./";

/**
 *
 * @export
 * @interface RecurrenceOutput
 */
export interface RecurrenceOutput {
    /**
     *
     * @type {RecurrenceType}
     * @memberof RecurrenceOutput
     */
    recurrenceType: RecurrenceType;
    /**
     *
     * @type {Date}
     * @memberof RecurrenceOutput
     */
    startDate: Date;
    /**
     *
     * @type {number}
     * @memberof RecurrenceOutput
     */
    repeatFrequency: number;
    /**
     *
     * @type {Date}
     * @memberof RecurrenceOutput
     */
    endDate?: Date;
    /**
     *
     * @type {number}
     * @memberof RecurrenceOutput
     */
    repeatMax?: number;
    /**
     *
     * @type {MonthlyRecurrenceType}
     * @memberof RecurrenceOutput
     */
    monthlyRecurrenceType?: MonthlyRecurrenceType;
    /**
     *
     * @type {Array<DayOfWeek>}
     * @memberof RecurrenceOutput
     */
    weeklyDays?: Array<DayOfWeek>;
}

/**
 * Check if a given object implements the RecurrenceOutput interface.
 */
export function instanceOfRecurrenceOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "recurrenceType" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "repeatFrequency" in value;

    return isInstance;
}

export function RecurrenceOutputFromJSON(json: any): RecurrenceOutput {
    return RecurrenceOutputFromJSONTyped(json, false);
}

export function RecurrenceOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecurrenceOutput {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        recurrenceType: !exists(json, "recurrenceType")
            ? (undefined as any)
            : RecurrenceTypeFromJSON(json["recurrenceType"]),
        startDate: !exists(json, "startDate") ? (undefined as any) : new Date(json["startDate"]),
        repeatFrequency: !exists(json, "repeatFrequency") ? (undefined as any) : json["repeatFrequency"],
        endDate: !exists(json, "endDate") ? (undefined as any) : new Date(json["endDate"]),
        repeatMax: !exists(json, "repeatMax") ? (undefined as any) : json["repeatMax"],
        monthlyRecurrenceType: !exists(json, "monthlyRecurrenceType")
            ? (undefined as any)
            : MonthlyRecurrenceTypeFromJSON(json["monthlyRecurrenceType"]),
        weeklyDays: !exists(json, "weeklyDays")
            ? (undefined as any)
            : ((json["weeklyDays"] as Array<any>) ?? []).map(DayOfWeekFromJSON),
    };
}

export function RecurrenceOutputToJSON(value?: RecurrenceOutput | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
