import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./AgeRange.module.scss";
import InputField from "../Input/InputField";
import Text from "../Text/Text";
import { asNumber, asRoundedNumber } from "../../utils/Formatting";

type AgeRangeType = {
    minAge?: number;
    maxAge?: number;
};

type Props = AgeRangeType & {
    className: string;
    onChange: (ageRange: AgeRangeType) => void;
};

const toPositiveInteger = (value?: number) => (value ? (value > 0 ? asRoundedNumber(value) : 0) : undefined);

const AgeRange = ({ className, minAge, maxAge, onChange }: Props) => {
    const { t } = useTranslation();

    return (
        <div className={classNames(styles.container, className)}>
            <InputField
                className={classNames(styles.input)}
                step={1}
                value={minAge ?? ""}
                type="number"
                onChange={(e) => {
                    onChange({
                        minAge: toPositiveInteger(e.currentTarget && asNumber(e.currentTarget.value)),
                        maxAge: maxAge,
                    });
                }}
            />

            <Text className={styles.between} label={t("targetAge.until")} />

            <InputField
                className={classNames(styles.input)}
                step={1}
                value={maxAge ?? ""}
                type="number"
                onChange={(e) => {
                    onChange({
                        maxAge: toPositiveInteger(e.currentTarget && asNumber(e.currentTarget.value)),
                        minAge: minAge,
                    });
                }}
                invalid={!!minAge && !!maxAge && minAge > maxAge}
            />
        </div>
    );
};

AgeRange.defaultProps = {
    className: "",
};

export default AgeRange;
