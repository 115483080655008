/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface HappeningListItemMetaData
 */
export interface HappeningListItemMetaData {
    /**
     *
     * @type {boolean}
     * @memberof HappeningListItemMetaData
     */
    readonly isCluster: boolean;
    /**
     *
     * @type {string}
     * @memberof HappeningListItemMetaData
     */
    clusterId?: string;
    /**
     *
     * @type {boolean}
     * @memberof HappeningListItemMetaData
     */
    hasMultipleDates: boolean;
    /**
     *
     * @type {boolean}
     * @memberof HappeningListItemMetaData
     */
    hasMultipleTimes: boolean;
    /**
     *
     * @type {boolean}
     * @memberof HappeningListItemMetaData
     */
    hasMultipleLocations: boolean;
}

/**
 * Check if a given object implements the HappeningListItemMetaData interface.
 */
export function instanceOfHappeningListItemMetaData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isCluster" in value;
    isInstance = isInstance && "hasMultipleDates" in value;
    isInstance = isInstance && "hasMultipleTimes" in value;
    isInstance = isInstance && "hasMultipleLocations" in value;

    return isInstance;
}

export function HappeningListItemMetaDataFromJSON(json: any): HappeningListItemMetaData {
    return HappeningListItemMetaDataFromJSONTyped(json, false);
}

export function HappeningListItemMetaDataFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): HappeningListItemMetaData {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        isCluster: !exists(json, "isCluster") ? (undefined as any) : json["isCluster"],
        clusterId: !exists(json, "clusterId") ? (undefined as any) : json["clusterId"],
        hasMultipleDates: !exists(json, "hasMultipleDates") ? (undefined as any) : json["hasMultipleDates"],
        hasMultipleTimes: !exists(json, "hasMultipleTimes") ? (undefined as any) : json["hasMultipleTimes"],
        hasMultipleLocations: !exists(json, "hasMultipleLocations") ? (undefined as any) : json["hasMultipleLocations"],
    };
}

export function HappeningListItemMetaDataToJSON(value?: HappeningListItemMetaData | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
