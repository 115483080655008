import React, { PropsWithChildren } from "react";
import { Configuration, TagsApi } from "../openapi/backend";
import { CreateApi, ApiContextProvider } from "./BackendApiContext";

const createApi: CreateApi<TagsApi> = (params) => new TagsApi(new Configuration(params));

export const TagApiContext = React.createContext(createApi({}));

export const TagApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={TagApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
