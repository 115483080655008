import React, { useCallback } from "react";
import styles from "./OrganisationDetailModal.module.scss";
import classNames from "classnames";
import Modal from "react-modal";
import { ReusableProps as OrganisationDetailProps } from "../OrganisationDetail/OrganisationDetail";
import { useNavigate } from "react-router-dom";
import IconButton from "../IconButton/IconButton";
import { CloseIcon } from "../../assets/images/Images";
import { useTranslation } from "react-i18next";
import OrganisationDetailContainer from "../OrganisationDetail/OrganisationDetailContainer";

export type Props = Omit<Modal.Props, "overlayClassName" | "className"> &
    OrganisationDetailProps & {
        className: string;
        organisationId?: string | null;
        fillPage: boolean;
    };

const OrganisationDetailModal = ({ className, organisationId, fillPage, onResize, ...props }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const goBack = useCallback(() => navigate(-1), [navigate]);

    return (
        <Modal
            className={classNames(styles.container, fillPage ? styles.fillPage : styles.modal, className)}
            testId="NearbyDetailModal"
            {...props}
            overlayClassName={styles.overlay}
            shouldCloseOnOverlayClick={!fillPage}
            onRequestClose={goBack}
        >
            {!fillPage && (
                <div className={styles.bar}>
                    <IconButton
                        icon={CloseIcon}
                        onClick={goBack}
                        displayType="transparent"
                        alt={t("navigation.back.organisationsOverview")}
                    />
                </div>
            )}
            {organisationId && (
                <OrganisationDetailContainer
                    className={styles.content}
                    id={organisationId}
                    onResize={onResize}
                    modal={!fillPage}
                />
            )}
        </Modal>
    );
};

OrganisationDetailModal.defaultProps = {
    className: "",
    fillPage: true,
};

export default OrganisationDetailModal;
