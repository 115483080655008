import { GetOrganisationInfoItemsForAppListRequest } from "../openapi/backend";

export type GeoViewport = Pick<
    GetOrganisationInfoItemsForAppListRequest,
    "viewTopLeftLatitude" | "viewTopLeftLongitude" | "viewBottomRightLatitude" | "viewBottomRightLongitude"
>;

export const getGeoViewportFromLatLngBoundLiteral = (bounds: google.maps.LatLngBounds | undefined) => {
    const viewTopLeftLatitude = bounds?.getNorthEast().lat();
    const viewTopLeftLongitude = bounds?.getSouthWest().lng();
    const viewBottomRightLatitude = bounds?.getSouthWest().lat();
    const viewBottomRightLongitude = bounds?.getNorthEast().lng();

    return {
        viewTopLeftLatitude,
        viewTopLeftLongitude,
        viewBottomRightLatitude,
        viewBottomRightLongitude,
    };
};
export const getGeoViewportFromPosition = (lat: number, lng: number): GeoViewport => {
    return {
        viewTopLeftLongitude: lng,
        viewBottomRightLongitude: lng,
        viewTopLeftLatitude: lat,
        viewBottomRightLatitude: lat,
    };
};
export const externalGoogleMapsLink = (lat: number, lng: number, address: string) =>
    `http://maps.google.com/?q=${address.replace(/\s/g, "+")}&ll=${lat},${lng}`;
