import React from "react";
import styles from "./HappeningDetailPage.module.scss";
import classNames from "classnames";
import HappeningDetailContainer from "../../components/HappeningDetail/HappeningDetailContainer";

type Props = {
    className: string;
};

const HappeningDetailPage = ({ className }: Props) => {
    return <HappeningDetailContainer className={classNames(styles.container, className)} />;
};

HappeningDetailPage.defaultProps = {
    className: "",
};

export default HappeningDetailPage;
