import React, { useCallback, useMemo, useState, useEffect } from "react";
import styles from "./PracticalInfoCard.module.scss";
import classNames from "classnames";
import AttentionCardLabel from "../AttentionCardLabel/AttentionCardLabel";
import { HappeningViewModel, LocationType } from "../../openapi/backend";
import OrganisationIcon from "../OrganisationIcon/OrganisationIcon";
import Title from "../Title/Title";
import { MediaPreviewType } from "../../types/MediaPreview";
import Carousel from "../Carousel/Carousel";
import Text from "../Text/Text";
import { useTranslation } from "react-i18next";
import Map from "../Map/Map";
import IconButton from "../IconButton/IconButton";
import { ShareIcon } from "../../assets/images/Images";
import { toastSuccess } from "../../utils/Toaster";
import { happeningDetailLink } from "../../utils/Urls";
import { useLocationLabel } from "../../hooks/useLocationLabel";
import { isGroupRegistration } from "../../utils/HappeningUtils";

type Props = {
    className: string;
    happening: HappeningViewModel;
};

const PracticalInfoCard = ({ className, happening }: Props) => {
    const { t } = useTranslation();
    const {
        images,
        videos,
        freeSpots,
        happeningGroup,
        locationType,
        title,
        location,
        maxNumberOfRegistrations,
        happeningRegistrationType,
        hasSpotInTeamForIndividuals,
        registrationEndDate,
    } = happening;
    const locationLabel = useLocationLabel(locationType);

    const media = useMemo(
        () =>
            [
                ...images.map((img) => ({ ...img, isVideo: false })),
                ...videos.map((vid) => ({ ...vid, isVideo: true })),
            ].filter((m) => !!m.altText && !!m.href) as Array<MediaPreviewType>,
        [images, videos],
    );

    const onShareClick = useCallback(async () => {
        await navigator.clipboard.writeText(`${happeningDetailLink(happening)}?embed=1`);

        toastSuccess(t("copy.success.link"));
    }, [happening, t]);

    const [writePermission, setWritePermission] = useState<boolean>(false);
    useEffect(() => {
        navigator.permissions.query({ name: "clipboard-write" as PermissionName }).then((result) => {
            setWritePermission(result.state === "granted");
        });
    }, []);

    const address = useMemo(() => (location ? location.address.replaceAll(", ", "\n") : ""), [location]);
    const isGroupHappening = useMemo(() => isGroupRegistration(happeningRegistrationType), [happeningRegistrationType]);
    return (
        <div
            className={classNames(styles.container, happening.isInternal ? styles.internal : styles.public, className)}
        >
            {writePermission && (
                <div className={classNames(styles.shareButton)}>
                    <IconButton icon={ShareIcon} alt={t("icon.share")} onClick={onShareClick} />
                </div>
            )}
            <div className={styles.carouselContainer}>
                <Carousel className={styles.carousel} viewportClassName={styles.viewport} slides={media}>
                    <AttentionCardLabel
                        className={styles.attention}
                        spotsLeft={freeSpots}
                        maxNumberOfRegistrations={maxNumberOfRegistrations}
                        isGroupHappening={isGroupHappening}
                        hasSpotInTeamForIndividuals={hasSpotInTeamForIndividuals}
                        registrationEndDate={registrationEndDate}
                    />
                </Carousel>
                <OrganisationIcon className={styles.organisation} size={40} organisation={happeningGroup!} />
            </div>
            <div className={styles.locationInfo}>
                <Title className={styles.title} heading="h2" label={title} />
                <Text className={styles.location} type="small-bold" label={locationLabel} />
                {locationType === LocationType.OnLocation && location && (
                    <>
                        <Text className={styles.locationName} type="small" label={location.name} />
                        <Text className={styles.address} type="small" label={address} />
                        <Map className={styles.map} location={location} />
                    </>
                )}
            </div>
        </div>
    );
};

PracticalInfoCard.defaultProps = {
    className: "",
};

export default PracticalInfoCard;
