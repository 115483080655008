/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 * - Append nested object fields in array as formdata fields
 * - Always check for nullable in "body: .map" for json patch
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

function appendToParams(formParams: { append(param: string, value: any): any }, path: string, value: any) {
    if (value === undefined) {
        return;
    } else if (Array.isArray(value)) {
        value.forEach((v, i) => appendToParams(formParams, `${path}[${i}]`, v));
    } else if (value instanceof Date) {
        formParams.append(path, value.toUTCString());
    } else if (value instanceof Blob) {
        formParams.append(path, value);
    } else if (typeof value == "object" && !!value) {
        Object.keys(value).forEach((key) => appendToParams(formParams, `${path}.${key}`, value[key]));
    } else {
        formParams.append(path, value);
    }
}

import * as runtime from "../runtime";
import {
    ImageInputModel,
    ImageInputModelFromJSON,
    ImageInputModelToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
    TagCollection,
    TagCollectionFromJSON,
    TagCollectionToJSON,
    TagOrderBy,
    TagOrderByFromJSON,
    TagOrderByToJSON,
    TagType,
    TagTypeFromJSON,
    TagTypeToJSON,
    TagViewModel,
    TagViewModelFromJSON,
    TagViewModelToJSON,
    TagViewModelPaginatedViewModel,
    TagViewModelPaginatedViewModelFromJSON,
    TagViewModelPaginatedViewModelToJSON,
} from "../models";

export interface ActivateTagRequest {
    id: string;
    version: string;
}

export interface CreateTagRequest {
    version: string;
    name: string;
    type: TagType;
    description?: string;
    parentTags?: Array<string>;
    childTags?: Array<string>;
    color?: string;
    newLogo?: Array<ImageInputModel>;
    minAge?: number;
    maxAge?: number;
    paymentApiKey?: string;
}

export interface DeactivateTagRequest {
    id: string;
    version: string;
}

export interface GetOrganizersRequest {
    version: string;
}

export interface GetTagRequest {
    id: string;
    version: string;
}

export interface GetTagCollectionRequest {
    version: string;
    filterInactive?: boolean;
    filterInternal?: boolean;
    compactOnly?: boolean;
}

export interface GetTagsRequest {
    version: string;
    page?: number;
    itemsPerPage?: number;
    itemsToSkip?: number;
    type?: TagType;
    parentTags?: Array<string>;
    query?: string;
    filterInactive?: boolean;
    filterInternal?: boolean;
    compactOnly?: boolean;
    orderBy?: TagOrderBy;
    sortOrder?: SortOrder;
    isDescending?: boolean;
}

export interface GetTagsHierarchyRequest {
    version: string;
    type?: TagType;
    parentTags?: Array<string>;
    query?: string;
    filterInactive?: boolean;
    filterInternal?: boolean;
    compactOnly?: boolean;
    orderBy?: TagOrderBy;
    sortOrder?: SortOrder;
    isDescending?: boolean;
}

export interface UpdateTagRequest {
    id: string;
    version: string;
    name: string;
    type: TagType;
    description?: string;
    parentTags?: Array<string>;
    childTags?: Array<string>;
    color?: string;
    newLogo?: Array<ImageInputModel>;
    minAge?: number;
    maxAge?: number;
    paymentApiKey?: string;
}

/**
 * no description
 */
export class TagsApi extends runtime.BaseAPI {
    /**
     */
    async activateTagRaw(requestParameters: ActivateTagRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling activateTag.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling activateTag.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Tags/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async activateTag(requestParameters: ActivateTagRequest): Promise<void> {
        await this.activateTagRaw(requestParameters);
    }
    /**
     */
    async createTagRaw(requestParameters: CreateTagRequest): Promise<runtime.ApiResponse<TagViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling createTag.",
            );
        }
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError(
                "name",
                "Required parameter requestParameters.name was null or undefined when calling createTag.",
            );
        }
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError(
                "type",
                "Required parameter requestParameters.type was null or undefined when calling createTag.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.name !== undefined) {
            formParams.append("Name", requestParameters.name as any);
            hasParams = true;
        }
        if (requestParameters.description !== undefined) {
            formParams.append("Description", requestParameters.description as any);
            hasParams = true;
        }
        if (requestParameters.type !== undefined) {
            formParams.append("Type", requestParameters.type as any);
            hasParams = true;
        }
        if (requestParameters.parentTags) {
            requestParameters.parentTags.forEach((element, index) => {
                appendToParams(formParams, `ParentTags[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.childTags) {
            requestParameters.childTags.forEach((element, index) => {
                appendToParams(formParams, `ChildTags[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.color !== undefined) {
            formParams.append("Color", requestParameters.color as any);
            hasParams = true;
        }
        if (requestParameters.newLogo) {
            requestParameters.newLogo.forEach((element, index) => {
                appendToParams(formParams, `NewLogo[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.minAge !== undefined) {
            formParams.append("MinAge", requestParameters.minAge as any);
            hasParams = true;
        }
        if (requestParameters.maxAge !== undefined) {
            formParams.append("MaxAge", requestParameters.maxAge as any);
            hasParams = true;
        }
        if (requestParameters.paymentApiKey !== undefined) {
            formParams.append("PaymentApiKey", requestParameters.paymentApiKey as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Tags`.replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => TagViewModelFromJSON(jsonValue));
    }
    /**
     */
    async createTag(requestParameters: CreateTagRequest): Promise<TagViewModel> {
        const response = await this.createTagRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async deactivateTagRaw(requestParameters: DeactivateTagRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling deactivateTag.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling deactivateTag.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Tags/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deactivateTag(requestParameters: DeactivateTagRequest): Promise<void> {
        await this.deactivateTagRaw(requestParameters);
    }
    /**
     */
    async getOrganizersRaw(requestParameters: GetOrganizersRequest): Promise<runtime.ApiResponse<Array<TagViewModel>>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getOrganizers.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Tags/organizers`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TagViewModelFromJSON));
    }
    /**
     */
    async getOrganizers(requestParameters: GetOrganizersRequest): Promise<Array<TagViewModel>> {
        const response = await this.getOrganizersRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getTagRaw(requestParameters: GetTagRequest): Promise<runtime.ApiResponse<TagViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling getTag.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getTag.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => TagViewModelFromJSON(jsonValue));
    }
    /**
     */
    async getTag(requestParameters: GetTagRequest): Promise<TagViewModel> {
        const response = await this.getTagRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getTagCollectionRaw(requestParameters: GetTagCollectionRequest): Promise<runtime.ApiResponse<TagCollection>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getTagCollection.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.filterInactive !== undefined) {
            queryParameters["FilterInactive"] = requestParameters.filterInactive;
        }
        if (requestParameters.filterInternal !== undefined) {
            queryParameters["FilterInternal"] = requestParameters.filterInternal;
        }
        if (requestParameters.compactOnly !== undefined) {
            queryParameters["CompactOnly"] = requestParameters.compactOnly;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Tags/collection`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => TagCollectionFromJSON(jsonValue));
    }
    /**
     */
    async getTagCollection(requestParameters: GetTagCollectionRequest): Promise<TagCollection> {
        const response = await this.getTagCollectionRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getTagsRaw(requestParameters: GetTagsRequest): Promise<runtime.ApiResponse<TagViewModelPaginatedViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getTags.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.page !== undefined) {
            queryParameters["Page"] = requestParameters.page;
        }
        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters["ItemsPerPage"] = requestParameters.itemsPerPage;
        }
        if (requestParameters.itemsToSkip !== undefined) {
            queryParameters["ItemsToSkip"] = requestParameters.itemsToSkip;
        }
        if (requestParameters.type !== undefined) {
            queryParameters["Type"] = requestParameters.type;
        }
        if (requestParameters.parentTags && requestParameters.parentTags.length > 0) {
            queryParameters["ParentTags"] = requestParameters.parentTags;
        }
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        if (requestParameters.filterInactive !== undefined) {
            queryParameters["FilterInactive"] = requestParameters.filterInactive;
        }
        if (requestParameters.filterInternal !== undefined) {
            queryParameters["FilterInternal"] = requestParameters.filterInternal;
        }
        if (requestParameters.compactOnly !== undefined) {
            queryParameters["CompactOnly"] = requestParameters.compactOnly;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters["OrderBy"] = requestParameters.orderBy;
        }
        if (requestParameters.sortOrder !== undefined) {
            queryParameters["SortOrder"] = requestParameters.sortOrder;
        }
        if (requestParameters.isDescending !== undefined) {
            queryParameters["IsDescending"] = requestParameters.isDescending;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Tags`.replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => TagViewModelPaginatedViewModelFromJSON(jsonValue));
    }
    /**
     */
    async getTags(requestParameters: GetTagsRequest): Promise<TagViewModelPaginatedViewModel> {
        const response = await this.getTagsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getTagsHierarchyRaw(
        requestParameters: GetTagsHierarchyRequest,
    ): Promise<runtime.ApiResponse<Array<TagViewModel>>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getTagsHierarchy.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.type !== undefined) {
            queryParameters["Type"] = requestParameters.type;
        }
        if (requestParameters.parentTags && requestParameters.parentTags.length > 0) {
            queryParameters["ParentTags"] = requestParameters.parentTags;
        }
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        if (requestParameters.filterInactive !== undefined) {
            queryParameters["FilterInactive"] = requestParameters.filterInactive;
        }
        if (requestParameters.filterInternal !== undefined) {
            queryParameters["FilterInternal"] = requestParameters.filterInternal;
        }
        if (requestParameters.compactOnly !== undefined) {
            queryParameters["CompactOnly"] = requestParameters.compactOnly;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters["OrderBy"] = requestParameters.orderBy;
        }
        if (requestParameters.sortOrder !== undefined) {
            queryParameters["SortOrder"] = requestParameters.sortOrder;
        }
        if (requestParameters.isDescending !== undefined) {
            queryParameters["IsDescending"] = requestParameters.isDescending;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Tags/hierarchy`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TagViewModelFromJSON));
    }
    /**
     */
    async getTagsHierarchy(requestParameters: GetTagsHierarchyRequest): Promise<Array<TagViewModel>> {
        const response = await this.getTagsHierarchyRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async updateTagRaw(requestParameters: UpdateTagRequest): Promise<runtime.ApiResponse<TagViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling updateTag.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling updateTag.",
            );
        }
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError(
                "name",
                "Required parameter requestParameters.name was null or undefined when calling updateTag.",
            );
        }
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError(
                "type",
                "Required parameter requestParameters.type was null or undefined when calling updateTag.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.name !== undefined) {
            formParams.append("Name", requestParameters.name as any);
            hasParams = true;
        }
        if (requestParameters.description !== undefined) {
            formParams.append("Description", requestParameters.description as any);
            hasParams = true;
        }
        if (requestParameters.type !== undefined) {
            formParams.append("Type", requestParameters.type as any);
            hasParams = true;
        }
        if (requestParameters.parentTags) {
            requestParameters.parentTags.forEach((element, index) => {
                appendToParams(formParams, `ParentTags[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.childTags) {
            requestParameters.childTags.forEach((element, index) => {
                appendToParams(formParams, `ChildTags[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.color !== undefined) {
            formParams.append("Color", requestParameters.color as any);
            hasParams = true;
        }
        if (requestParameters.newLogo) {
            requestParameters.newLogo.forEach((element, index) => {
                appendToParams(formParams, `NewLogo[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.minAge !== undefined) {
            formParams.append("MinAge", requestParameters.minAge as any);
            hasParams = true;
        }
        if (requestParameters.maxAge !== undefined) {
            formParams.append("MaxAge", requestParameters.maxAge as any);
            hasParams = true;
        }
        if (requestParameters.paymentApiKey !== undefined) {
            formParams.append("PaymentApiKey", requestParameters.paymentApiKey as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => TagViewModelFromJSON(jsonValue));
    }
    /**
     */
    async updateTag(requestParameters: UpdateTagRequest): Promise<TagViewModel> {
        const response = await this.updateTagRaw(requestParameters);
        return await response.value();
    }
}
