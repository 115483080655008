/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    ClusterViewModel,
    ClusterViewModelFromJSON,
    ClusterViewModelFromJSONTyped,
    ClusterViewModelToJSON,
    HappeningRegistrationOutput,
    HappeningRegistrationOutputFromJSON,
    HappeningRegistrationOutputFromJSONTyped,
    HappeningRegistrationOutputToJSON,
    HappeningRegistrationType,
    HappeningRegistrationTypeFromJSON,
    HappeningRegistrationTypeFromJSONTyped,
    HappeningRegistrationTypeToJSON,
    HappeningState,
    HappeningStateFromJSON,
    HappeningStateFromJSONTyped,
    HappeningStateToJSON,
    HappeningTimeAndDateViewModel,
    HappeningTimeAndDateViewModelFromJSON,
    HappeningTimeAndDateViewModelFromJSONTyped,
    HappeningTimeAndDateViewModelToJSON,
    HappeningType,
    HappeningTypeFromJSON,
    HappeningTypeFromJSONTyped,
    HappeningTypeToJSON,
    ImageViewModel,
    ImageViewModelFromJSON,
    ImageViewModelFromJSONTyped,
    ImageViewModelToJSON,
    InfoLevel,
    InfoLevelFromJSON,
    InfoLevelFromJSONTyped,
    InfoLevelToJSON,
    LocationType,
    LocationTypeFromJSON,
    LocationTypeFromJSONTyped,
    LocationTypeToJSON,
    LocationViewModel,
    LocationViewModelFromJSON,
    LocationViewModelFromJSONTyped,
    LocationViewModelToJSON,
    OrganizerViewModel,
    OrganizerViewModelFromJSON,
    OrganizerViewModelFromJSONTyped,
    OrganizerViewModelToJSON,
    PaymentMethod,
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
    Permissions,
    PermissionsFromJSON,
    PermissionsFromJSONTyped,
    PermissionsToJSON,
    PresenceStatistic,
    PresenceStatisticFromJSON,
    PresenceStatisticFromJSONTyped,
    PresenceStatisticToJSON,
    ProfileOutput,
    ProfileOutputFromJSON,
    ProfileOutputFromJSONTyped,
    ProfileOutputToJSON,
    QuestionOutput,
    QuestionOutputFromJSON,
    QuestionOutputFromJSONTyped,
    QuestionOutputToJSON,
    RecurrenceOutput,
    RecurrenceOutputFromJSON,
    RecurrenceOutputFromJSONTyped,
    RecurrenceOutputToJSON,
    RegistrationRuleViewModel,
    RegistrationRuleViewModelFromJSON,
    RegistrationRuleViewModelFromJSONTyped,
    RegistrationRuleViewModelToJSON,
    TagViewModel,
    TagViewModelFromJSON,
    TagViewModelFromJSONTyped,
    TagViewModelToJSON,
    TargetGroupViewModel,
    TargetGroupViewModelFromJSON,
    TargetGroupViewModelFromJSONTyped,
    TargetGroupViewModelToJSON,
    TeamViewModel,
    TeamViewModelFromJSON,
    TeamViewModelFromJSONTyped,
    TeamViewModelToJSON,
    UserShortOutput,
    UserShortOutputFromJSON,
    UserShortOutputFromJSONTyped,
    UserShortOutputToJSON,
    UserSubset,
    UserSubsetFromJSON,
    UserSubsetFromJSONTyped,
    UserSubsetToJSON,
    Video,
    VideoFromJSON,
    VideoFromJSONTyped,
    VideoToJSON,
} from "./";

/**
 *
 * @export
 * @interface HappeningViewModel
 */
export interface HappeningViewModel {
    /**
     *
     * @type {number}
     * @memberof HappeningViewModel
     */
    currentNumberOfRegistrations: number;
    /**
     *
     * @type {number}
     * @memberof HappeningViewModel
     */
    maxNumberOfRegistrations: number;
    /**
     *
     * @type {OrganizerViewModel}
     * @memberof HappeningViewModel
     */
    happeningGroup?: OrganizerViewModel;
    /**
     *
     * @type {number}
     * @memberof HappeningViewModel
     */
    readonly freeSpots: number;
    /**
     *
     * @type {string}
     * @memberof HappeningViewModel
     */
    id: string;
    /**
     *
     * @type {HappeningType}
     * @memberof HappeningViewModel
     */
    type: HappeningType;
    /**
     *
     * @type {string}
     * @memberof HappeningViewModel
     */
    title: string;
    /**
     *
     * @type {string}
     * @memberof HappeningViewModel
     */
    description: string;
    /**
     *
     * @type {string}
     * @memberof HappeningViewModel
     */
    link?: string;
    /**
     *
     * @type {string}
     * @memberof HappeningViewModel
     */
    linkLabel?: string;
    /**
     *
     * @type {HappeningState}
     * @memberof HappeningViewModel
     */
    state: HappeningState;
    /**
     *
     * @type {boolean}
     * @memberof HappeningViewModel
     */
    isInternal: boolean;
    /**
     *
     * @type {Date}
     * @memberof HappeningViewModel
     */
    publicationDate?: Date;
    /**
     *
     * @type {HappeningState}
     * @memberof HappeningViewModel
     */
    displayState: HappeningState;
    /**
     *
     * @type {UserSubset}
     * @memberof HappeningViewModel
     */
    targetGroup: UserSubset;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof HappeningViewModel
     */
    targetOrganisations: Array<TagViewModel>;
    /**
     *
     * @type {number}
     * @memberof HappeningViewModel
     */
    minAge: number;
    /**
     *
     * @type {number}
     * @memberof HappeningViewModel
     */
    maxAge: number;
    /**
     *
     * @type {boolean}
     * @memberof HappeningViewModel
     */
    restrictAge: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof HappeningViewModel
     */
    schools: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof HappeningViewModel
     */
    askForSchool: boolean;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof HappeningViewModel
     */
    tags: Array<TagViewModel>;
    /**
     *
     * @type {InfoLevel}
     * @memberof HappeningViewModel
     */
    infoLevel: InfoLevel;
    /**
     *
     * @type {Date}
     * @memberof HappeningViewModel
     */
    registrationStartDate?: Date;
    /**
     *
     * @type {Date}
     * @memberof HappeningViewModel
     */
    registrationEndDate?: Date;
    /**
     *
     * @type {boolean}
     * @memberof HappeningViewModel
     */
    isFull: boolean;
    /**
     *
     * @type {Array<HappeningRegistrationOutput>}
     * @memberof HappeningViewModel
     */
    registrations: Array<HappeningRegistrationOutput>;
    /**
     *
     * @type {Array<ProfileOutput>}
     * @memberof HappeningViewModel
     */
    reserveList: Array<ProfileOutput>;
    /**
     *
     * @type {number}
     * @memberof HappeningViewModel
     */
    intendedParticipants: number;
    /**
     *
     * @type {UserShortOutput}
     * @memberof HappeningViewModel
     */
    projectLeader?: UserShortOutput;
    /**
     *
     * @type {UserShortOutput}
     * @memberof HappeningViewModel
     */
    contact?: UserShortOutput;
    /**
     *
     * @type {Array<UserShortOutput>}
     * @memberof HappeningViewModel
     */
    projectMembers: Array<UserShortOutput>;
    /**
     *
     * @type {Array<UserShortOutput>}
     * @memberof HappeningViewModel
     */
    projectCoLeaders: Array<UserShortOutput>;
    /**
     *
     * @type {LocationType}
     * @memberof HappeningViewModel
     */
    locationType: LocationType;
    /**
     *
     * @type {LocationViewModel}
     * @memberof HappeningViewModel
     */
    location?: LocationViewModel;
    /**
     *
     * @type {number}
     * @memberof HappeningViewModel
     */
    price: number;
    /**
     *
     * @type {number}
     * @memberof HappeningViewModel
     */
    proRata: number;
    /**
     *
     * @type {Array<ImageViewModel>}
     * @memberof HappeningViewModel
     */
    images: Array<ImageViewModel>;
    /**
     *
     * @type {Array<Video>}
     * @memberof HappeningViewModel
     */
    videos: Array<Video>;
    /**
     *
     * @type {boolean}
     * @memberof HappeningViewModel
     */
    requiresApprovalFromMarketing: boolean;
    /**
     *
     * @type {number}
     * @memberof HappeningViewModel
     */
    canceledRegistrationsCount: number;
    /**
     *
     * @type {{ [key: string]: PresenceStatistic; }}
     * @memberof HappeningViewModel
     */
    presenceStatistics: { [key: string]: PresenceStatistic };
    /**
     *
     * @type {PaymentMethod}
     * @memberof HappeningViewModel
     */
    allowedPaymentMethods: PaymentMethod;
    /**
     *
     * @type {string}
     * @memberof HappeningViewModel
     */
    organiserInfoId?: string;
    /**
     *
     * @type {Array<RegistrationRuleViewModel>}
     * @memberof HappeningViewModel
     */
    registrationRules: Array<RegistrationRuleViewModel>;
    /**
     *
     * @type {Array<QuestionOutput>}
     * @memberof HappeningViewModel
     */
    extraQuestions: Array<QuestionOutput>;
    /**
     *
     * @type {boolean}
     * @memberof HappeningViewModel
     */
    requiresRegistration: boolean;
    /**
     *
     * @type {boolean}
     * @memberof HappeningViewModel
     */
    isFavorite: boolean;
    /**
     *
     * @type {boolean}
     * @memberof HappeningViewModel
     */
    isAccessible: boolean;
    /**
     *
     * @type {ClusterViewModel}
     * @memberof HappeningViewModel
     */
    cluster?: ClusterViewModel;
    /**
     *
     * @type {UserShortOutput}
     * @memberof HappeningViewModel
     */
    createdBy?: UserShortOutput;
    /**
     *
     * @type {boolean}
     * @memberof HappeningViewModel
     */
    isGroupHappening: boolean;
    /**
     *
     * @type {boolean}
     * @memberof HappeningViewModel
     */
    hasSpotInTeamForIndividuals: boolean;
    /**
     *
     * @type {boolean}
     * @memberof HappeningViewModel
     */
    userIsRegisteredAsGroup: boolean;
    /**
     *
     * @type {boolean}
     * @memberof HappeningViewModel
     */
    canOnlyRegisterAsGroup: boolean;
    /**
     *
     * @type {number}
     * @memberof HappeningViewModel
     */
    priceForIndividual: number;
    /**
     *
     * @type {boolean}
     * @memberof HappeningViewModel
     */
    isProRata: boolean;
    /**
     *
     * @type {number}
     * @memberof HappeningViewModel
     */
    minPeoplePerGroup: number;
    /**
     *
     * @type {number}
     * @memberof HappeningViewModel
     */
    maxPeoplePerGroup: number;
    /**
     *
     * @type {Array<TeamViewModel>}
     * @memberof HappeningViewModel
     */
    teams?: Array<TeamViewModel>;
    /**
     *
     * @type {RecurrenceOutput}
     * @memberof HappeningViewModel
     */
    recurrence?: RecurrenceOutput;
    /**
     *
     * @type {boolean}
     * @memberof HappeningViewModel
     */
    userIsOnNotifyList: boolean;
    /**
     *
     * @type {number}
     * @memberof HappeningViewModel
     */
    durationInMinutes?: number;
    /**
     *
     * @type {HappeningRegistrationType}
     * @memberof HappeningViewModel
     */
    happeningRegistrationType: HappeningRegistrationType;
    /**
     *
     * @type {Array<TargetGroupViewModel>}
     * @memberof HappeningViewModel
     */
    readonly targetGroups: Array<TargetGroupViewModel>;
    /**
     *
     * @type {Array<HappeningTimeAndDateViewModel>}
     * @memberof HappeningViewModel
     */
    dateTimes: Array<HappeningTimeAndDateViewModel>;
    /**
     *
     * @type {boolean}
     * @memberof HappeningViewModel
     */
    readonly isOnline: boolean;
    /**
     *
     * @type {Permissions}
     * @memberof HappeningViewModel
     */
    permissions?: Permissions;
}

/**
 * Check if a given object implements the HappeningViewModel interface.
 */
export function instanceOfHappeningViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currentNumberOfRegistrations" in value;
    isInstance = isInstance && "maxNumberOfRegistrations" in value;
    isInstance = isInstance && "freeSpots" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "isInternal" in value;
    isInstance = isInstance && "displayState" in value;
    isInstance = isInstance && "targetGroup" in value;
    isInstance = isInstance && "targetOrganisations" in value;
    isInstance = isInstance && "minAge" in value;
    isInstance = isInstance && "maxAge" in value;
    isInstance = isInstance && "restrictAge" in value;
    isInstance = isInstance && "schools" in value;
    isInstance = isInstance && "askForSchool" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "infoLevel" in value;
    isInstance = isInstance && "isFull" in value;
    isInstance = isInstance && "registrations" in value;
    isInstance = isInstance && "reserveList" in value;
    isInstance = isInstance && "intendedParticipants" in value;
    isInstance = isInstance && "projectMembers" in value;
    isInstance = isInstance && "projectCoLeaders" in value;
    isInstance = isInstance && "locationType" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "proRata" in value;
    isInstance = isInstance && "images" in value;
    isInstance = isInstance && "videos" in value;
    isInstance = isInstance && "requiresApprovalFromMarketing" in value;
    isInstance = isInstance && "canceledRegistrationsCount" in value;
    isInstance = isInstance && "presenceStatistics" in value;
    isInstance = isInstance && "allowedPaymentMethods" in value;
    isInstance = isInstance && "registrationRules" in value;
    isInstance = isInstance && "extraQuestions" in value;
    isInstance = isInstance && "requiresRegistration" in value;
    isInstance = isInstance && "isFavorite" in value;
    isInstance = isInstance && "isAccessible" in value;
    isInstance = isInstance && "isGroupHappening" in value;
    isInstance = isInstance && "hasSpotInTeamForIndividuals" in value;
    isInstance = isInstance && "userIsRegisteredAsGroup" in value;
    isInstance = isInstance && "canOnlyRegisterAsGroup" in value;
    isInstance = isInstance && "priceForIndividual" in value;
    isInstance = isInstance && "isProRata" in value;
    isInstance = isInstance && "minPeoplePerGroup" in value;
    isInstance = isInstance && "maxPeoplePerGroup" in value;
    isInstance = isInstance && "userIsOnNotifyList" in value;
    isInstance = isInstance && "happeningRegistrationType" in value;
    isInstance = isInstance && "targetGroups" in value;
    isInstance = isInstance && "dateTimes" in value;
    isInstance = isInstance && "isOnline" in value;

    return isInstance;
}

export function HappeningViewModelFromJSON(json: any): HappeningViewModel {
    return HappeningViewModelFromJSONTyped(json, false);
}

export function HappeningViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HappeningViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        currentNumberOfRegistrations: !exists(json, "currentNumberOfRegistrations")
            ? (undefined as any)
            : json["currentNumberOfRegistrations"],
        maxNumberOfRegistrations: !exists(json, "maxNumberOfRegistrations")
            ? (undefined as any)
            : json["maxNumberOfRegistrations"],
        happeningGroup: !exists(json, "happeningGroup")
            ? (undefined as any)
            : OrganizerViewModelFromJSON(json["happeningGroup"]),
        freeSpots: !exists(json, "freeSpots") ? (undefined as any) : json["freeSpots"],
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        type: !exists(json, "type") ? (undefined as any) : HappeningTypeFromJSON(json["type"]),
        title: !exists(json, "title") ? (undefined as any) : json["title"],
        description: !exists(json, "description") ? (undefined as any) : json["description"],
        link: !exists(json, "link") ? (undefined as any) : json["link"],
        linkLabel: !exists(json, "linkLabel") ? (undefined as any) : json["linkLabel"],
        state: !exists(json, "state") ? (undefined as any) : HappeningStateFromJSON(json["state"]),
        isInternal: !exists(json, "isInternal") ? (undefined as any) : json["isInternal"],
        publicationDate: !exists(json, "publicationDate") ? (undefined as any) : new Date(json["publicationDate"]),
        displayState: !exists(json, "displayState") ? (undefined as any) : HappeningStateFromJSON(json["displayState"]),
        targetGroup: !exists(json, "targetGroup") ? (undefined as any) : UserSubsetFromJSON(json["targetGroup"]),
        targetOrganisations: !exists(json, "targetOrganisations")
            ? (undefined as any)
            : ((json["targetOrganisations"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        minAge: !exists(json, "minAge") ? (undefined as any) : json["minAge"],
        maxAge: !exists(json, "maxAge") ? (undefined as any) : json["maxAge"],
        restrictAge: !exists(json, "restrictAge") ? (undefined as any) : json["restrictAge"],
        schools: !exists(json, "schools") ? (undefined as any) : json["schools"],
        askForSchool: !exists(json, "askForSchool") ? (undefined as any) : json["askForSchool"],
        tags: !exists(json, "tags")
            ? (undefined as any)
            : ((json["tags"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        infoLevel: !exists(json, "infoLevel") ? (undefined as any) : InfoLevelFromJSON(json["infoLevel"]),
        registrationStartDate: !exists(json, "registrationStartDate")
            ? (undefined as any)
            : new Date(json["registrationStartDate"]),
        registrationEndDate: !exists(json, "registrationEndDate")
            ? (undefined as any)
            : new Date(json["registrationEndDate"]),
        isFull: !exists(json, "isFull") ? (undefined as any) : json["isFull"],
        registrations: !exists(json, "registrations")
            ? (undefined as any)
            : ((json["registrations"] as Array<any>) ?? []).map(HappeningRegistrationOutputFromJSON),
        reserveList: !exists(json, "reserveList")
            ? (undefined as any)
            : ((json["reserveList"] as Array<any>) ?? []).map(ProfileOutputFromJSON),
        intendedParticipants: !exists(json, "intendedParticipants") ? (undefined as any) : json["intendedParticipants"],
        projectLeader: !exists(json, "projectLeader")
            ? (undefined as any)
            : UserShortOutputFromJSON(json["projectLeader"]),
        contact: !exists(json, "contact") ? (undefined as any) : UserShortOutputFromJSON(json["contact"]),
        projectMembers: !exists(json, "projectMembers")
            ? (undefined as any)
            : ((json["projectMembers"] as Array<any>) ?? []).map(UserShortOutputFromJSON),
        projectCoLeaders: !exists(json, "projectCoLeaders")
            ? (undefined as any)
            : ((json["projectCoLeaders"] as Array<any>) ?? []).map(UserShortOutputFromJSON),
        locationType: !exists(json, "locationType") ? (undefined as any) : LocationTypeFromJSON(json["locationType"]),
        location: !exists(json, "location") ? (undefined as any) : LocationViewModelFromJSON(json["location"]),
        price: !exists(json, "price") ? (undefined as any) : json["price"],
        proRata: !exists(json, "proRata") ? (undefined as any) : json["proRata"],
        images: !exists(json, "images")
            ? (undefined as any)
            : ((json["images"] as Array<any>) ?? []).map(ImageViewModelFromJSON),
        videos: !exists(json, "videos")
            ? (undefined as any)
            : ((json["videos"] as Array<any>) ?? []).map(VideoFromJSON),
        requiresApprovalFromMarketing: !exists(json, "requiresApprovalFromMarketing")
            ? (undefined as any)
            : json["requiresApprovalFromMarketing"],
        canceledRegistrationsCount: !exists(json, "canceledRegistrationsCount")
            ? (undefined as any)
            : json["canceledRegistrationsCount"],
        presenceStatistics: !exists(json, "presenceStatistics")
            ? (undefined as any)
            : mapValues(json["presenceStatistics"], PresenceStatisticFromJSON),
        allowedPaymentMethods: !exists(json, "allowedPaymentMethods")
            ? (undefined as any)
            : PaymentMethodFromJSON(json["allowedPaymentMethods"]),
        organiserInfoId: !exists(json, "organiserInfoId") ? (undefined as any) : json["organiserInfoId"],
        registrationRules: !exists(json, "registrationRules")
            ? (undefined as any)
            : ((json["registrationRules"] as Array<any>) ?? []).map(RegistrationRuleViewModelFromJSON),
        extraQuestions: !exists(json, "extraQuestions")
            ? (undefined as any)
            : ((json["extraQuestions"] as Array<any>) ?? []).map(QuestionOutputFromJSON),
        requiresRegistration: !exists(json, "requiresRegistration") ? (undefined as any) : json["requiresRegistration"],
        isFavorite: !exists(json, "isFavorite") ? (undefined as any) : json["isFavorite"],
        isAccessible: !exists(json, "isAccessible") ? (undefined as any) : json["isAccessible"],
        cluster: !exists(json, "cluster") ? (undefined as any) : ClusterViewModelFromJSON(json["cluster"]),
        createdBy: !exists(json, "createdBy") ? (undefined as any) : UserShortOutputFromJSON(json["createdBy"]),
        isGroupHappening: !exists(json, "isGroupHappening") ? (undefined as any) : json["isGroupHappening"],
        hasSpotInTeamForIndividuals: !exists(json, "hasSpotInTeamForIndividuals")
            ? (undefined as any)
            : json["hasSpotInTeamForIndividuals"],
        userIsRegisteredAsGroup: !exists(json, "userIsRegisteredAsGroup")
            ? (undefined as any)
            : json["userIsRegisteredAsGroup"],
        canOnlyRegisterAsGroup: !exists(json, "canOnlyRegisterAsGroup")
            ? (undefined as any)
            : json["canOnlyRegisterAsGroup"],
        priceForIndividual: !exists(json, "priceForIndividual") ? (undefined as any) : json["priceForIndividual"],
        isProRata: !exists(json, "isProRata") ? (undefined as any) : json["isProRata"],
        minPeoplePerGroup: !exists(json, "minPeoplePerGroup") ? (undefined as any) : json["minPeoplePerGroup"],
        maxPeoplePerGroup: !exists(json, "maxPeoplePerGroup") ? (undefined as any) : json["maxPeoplePerGroup"],
        teams: !exists(json, "teams")
            ? (undefined as any)
            : ((json["teams"] as Array<any>) ?? []).map(TeamViewModelFromJSON),
        recurrence: !exists(json, "recurrence") ? (undefined as any) : RecurrenceOutputFromJSON(json["recurrence"]),
        userIsOnNotifyList: !exists(json, "userIsOnNotifyList") ? (undefined as any) : json["userIsOnNotifyList"],
        durationInMinutes: !exists(json, "durationInMinutes") ? (undefined as any) : json["durationInMinutes"],
        happeningRegistrationType: !exists(json, "happeningRegistrationType")
            ? (undefined as any)
            : HappeningRegistrationTypeFromJSON(json["happeningRegistrationType"]),
        targetGroups: !exists(json, "targetGroups")
            ? (undefined as any)
            : ((json["targetGroups"] as Array<any>) ?? []).map(TargetGroupViewModelFromJSON),
        dateTimes: !exists(json, "dateTimes")
            ? (undefined as any)
            : ((json["dateTimes"] as Array<any>) ?? []).map(HappeningTimeAndDateViewModelFromJSON),
        isOnline: !exists(json, "isOnline") ? (undefined as any) : json["isOnline"],
        permissions: !exists(json, "permissions") ? (undefined as any) : PermissionsFromJSON(json["permissions"]),
    };
}

export function HappeningViewModelToJSON(value?: HappeningViewModel | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
