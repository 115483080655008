import React from "react";
import styles from "./OrganisationIntro.module.scss";
import classNames from "classnames";
import Title from "../Title/Title";
import { OrganisationInfoViewModel } from "../../openapi/backend";
import Text from "../Text/Text";
type Props = {
    className: string;
    organisation: OrganisationInfoViewModel;
};

const OrganisationIntro = ({ className, organisation }: Props) => {
    const { title, description } = organisation;

    return (
        <div className={classNames(styles.container, className)}>
            <Title className={styles.title} heading="h1" label={title} />
            <Text className={styles.description} label={description} />
        </div>
    );
};

OrganisationIntro.defaultProps = {
    className: "",
};

export default OrganisationIntro;
