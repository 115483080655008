import React from "react";
import { OrganisationInfoAppListViewModel } from "../../openapi/backend";
import LazyImage from "../LazyImage/LazyImage";
import Title from "../Title/Title";
import { ChevronRight } from "../../assets/images/Images";
import { t } from "i18next";
import styles from "./NearbyListCard.module.scss";
import { useAgeLabel } from "../../hooks/useAgeLabel";
import classNames from "classnames";
import Text from "../Text/Text";
const IMAGE_SIZE = 100;
const ARROW_SIZE = 22;

function getImageDimensions(showOnMap: boolean) {
    return showOnMap ? { width: IMAGE_SIZE, height: IMAGE_SIZE } : { width: "100%", height: IMAGE_SIZE * 1.75 };
}

interface Props {
    organisation: OrganisationInfoAppListViewModel;
    showOnMap: boolean;
}
function NearbyListCard({ showOnMap, organisation }: Props) {
    const { title, images, minimumAge, category, maximumAge } = organisation;
    const ageLabel = useAgeLabel(minimumAge, maximumAge);
    const imageDimensions = getImageDimensions(showOnMap);
    return (
        <div className={classNames(styles.container, showOnMap && styles.map)}>
            <LazyImage
                width={imageDimensions.width}
                height={imageDimensions.height}
                threshold={268}
                alt={t("image.cover.alt", { name: title })}
                className={styles.organisationImage}
                src={images[0].href}
            />
            <div className={styles.organisationDetails}>
                <Title label={title} heading={showOnMap ? "h2" : "h4"} className={styles.title} />
                <Text
                    type={"info-card-dark"}
                    style={{ color: category.color }}
                    label={category.name}
                    className={styles.age}
                />
                <Text type={"info-card-dark"} label={ageLabel} />
                <div className={styles.arrow}>
                    <img src={ChevronRight} alt={t("icon.right_arrow")} width={ARROW_SIZE} height={ARROW_SIZE} />
                </div>
            </div>
        </div>
    );
}

export default NearbyListCard;
