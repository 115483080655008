import React, { Suspense, lazy, useCallback } from "react";
import { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.scss";

import styles from "./DatePicker.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { nl, enUS } from "date-fns/locale";

const ReactDatePicker = lazy(() => import("react-datepicker"));

function DatePicker<CustomModifierNames extends string = never, WithRange extends boolean | undefined = undefined>({
    className,
    dateFormat = "dd/MM/yyyy",
    ...props
}: ReactDatePickerProps<CustomModifierNames, WithRange>) {
    const { i18n } = useTranslation();

    const getLocale = useCallback(() => {
        if (i18n.language === "nl") {
            return nl;
        } else {
            return enUS;
        }
    }, [i18n.language]);

    return (
        <Suspense>
            <ReactDatePicker
                calendarClassName={classNames(styles.calendar, className)}
                className={`${styles.datePicker} ${className}`}
                locale={getLocale()}
                dateFormat={dateFormat}
                {...props}
            />
        </Suspense>
    );
}

export default DatePicker;
