import React, { useMemo } from "react";
import Carousel from "../Carousel/Carousel";
import { OrganisationInfoViewModel } from "../../openapi/backend";
import { MediaPreviewType } from "../../types/MediaPreview";
import styles from "./OrganisationDetailCard.module.scss";
import classNames from "classnames";
import Title from "../Title/Title";
import AvatarIcon from "../AvatarIcon/AvatarIcon";
import { strings } from "../../Localization/strings";
interface Props {
    className: string;
    organisation: OrganisationInfoViewModel;
}

function OrganisationDetailCard({ className, organisation }: Props) {
    const { images } = organisation;
    const media = useMemo(
        () =>
            [...images.map((img) => ({ ...img, isVideo: false }))].filter(
                (m) => !!m.altText && !!m.href,
            ) as Array<MediaPreviewType>,
        [images],
    );
    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.carouselContainer}>
                <Carousel className={styles.carousel} viewportClassName={styles.viewport} slides={media} />
            </div>
            <div className={styles.info}>
                <div className={styles.section}>
                    <Title className={styles.title} heading="h2" label={organisation.title} />
                    <AvatarIcon size={40} src={organisation.images[0].href} name={organisation.title} />
                </div>
                {organisation.locations.length > 1 && <Title heading="h3" label={strings.multipleLocations} />}
            </div>
        </div>
    );
}

export default OrganisationDetailCard;
