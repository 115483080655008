/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import { TagViewModel, TagViewModelFromJSON, TagViewModelFromJSONTyped, TagViewModelToJSON } from "./";

/**
 *
 * @export
 * @interface OrganisationInfoBackofficeListViewModel
 */
export interface OrganisationInfoBackofficeListViewModel {
    /**
     *
     * @type {string}
     * @memberof OrganisationInfoBackofficeListViewModel
     */
    id: string;
    /**
     *
     * @type {boolean}
     * @memberof OrganisationInfoBackofficeListViewModel
     */
    isActive: boolean;
    /**
     *
     * @type {string}
     * @memberof OrganisationInfoBackofficeListViewModel
     */
    title: string;
    /**
     *
     * @type {TagViewModel}
     * @memberof OrganisationInfoBackofficeListViewModel
     */
    organisationTag?: TagViewModel;
    /**
     *
     * @type {TagViewModel}
     * @memberof OrganisationInfoBackofficeListViewModel
     */
    category: TagViewModel;
}

/**
 * Check if a given object implements the OrganisationInfoBackofficeListViewModel interface.
 */
export function instanceOfOrganisationInfoBackofficeListViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isActive" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "category" in value;

    return isInstance;
}

export function OrganisationInfoBackofficeListViewModelFromJSON(json: any): OrganisationInfoBackofficeListViewModel {
    return OrganisationInfoBackofficeListViewModelFromJSONTyped(json, false);
}

export function OrganisationInfoBackofficeListViewModelFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): OrganisationInfoBackofficeListViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        isActive: !exists(json, "isActive") ? (undefined as any) : json["isActive"],
        title: !exists(json, "title") ? (undefined as any) : json["title"],
        organisationTag: !exists(json, "organisationTag")
            ? (undefined as any)
            : TagViewModelFromJSON(json["organisationTag"]),
        category: !exists(json, "category") ? (undefined as any) : TagViewModelFromJSON(json["category"]),
    };
}

export function OrganisationInfoBackofficeListViewModelToJSON(
    value?: OrganisationInfoBackofficeListViewModel | null,
): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
