const getVariable = (name: string, required = false): string => {
    const result = process.env[name];

    if (result === null || result === undefined) {
        const message = `Could not find required env variable '${name}'`;
        if (required) {
            throw Error(message);
        }
    }

    return result || "";
};

// Define all environment values here, so we can guard against Oopsies in runtime.
// Structured as { [variable]: required }
const AllVars = {
    NODE_ENV: false,
    REACT_APP_FULL_DEV_MODE: false,
    REACT_APP_GLITCHTIP_DSN: false,
    REACT_APP_TEST_MAKE_SCREENSHOTS: false,
    REACT_APP_BACKEND_VERSION: false,
    REACT_APP_BACKEND_URL: false,
    REACT_APP_FRONTEND_CLIENT_URL: false,
    REACT_APP_GOOGLE_API_KEY: false,
    REACT_APP_GOOGLE_MAP_API_KEY: false,
    REACT_APP_HAPPENING_DYNAMIC_APP_URL: false,
    REACT_APP_GOOGLE_MAP_ID: false,
};

type EnvKeys = keyof typeof AllVars;

export const Env = Object.entries(AllVars).reduce(
    (envObject, [key, required]) => ({ ...envObject, [key]: getVariable(key, required) }),
    {},
) as { [key in EnvKeys]: string } & typeof process.env;
