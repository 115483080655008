import { useCallback, useContext } from "react";
import { HappeningsApiContext } from "../contexts/HappeningsApiContext";
import { useApiCallback } from "./useApiCall";
import {
    FilterInclusion,
    GetHappeningsRequest,
    GetMapHappeningsRequest,
    TagViewModel,
    UsagePurpose,
} from "../openapi/backend";
import { Env } from "../utils/Env";
import { toEndOfDay } from "../utils/DateUtils";
import { GeoViewport } from "../utils/MapUtils";

export function useHappeningsApi() {
    return useContext(HappeningsApiContext);
}

export type HappeningFilter = Pick<
    GetHappeningsRequest,
    "query" | "fromDate" | "toDate" | "groups" | "minAge" | "maxAge"
> & {
    happeningTypes?: TagViewModel[];
    isInternal?: FilterInclusion;
    full?: boolean;
    isAccessible?: boolean;
};

export type MapHappeningFilter = Pick<
    GetMapHappeningsRequest,
    "query" | "fromDate" | "toDate" | "groups" | "minAge" | "maxAge"
> & {
    happeningTypes?: TagViewModel[];
    isInternal?: FilterInclusion;
    full?: boolean;
    isAccessible?: boolean;
};

export function useGetHappeningsOverview(clusterId?: string) {
    const api = useHappeningsApi();

    const callback = useCallback(
        (page: number, filter: HappeningFilter, itemsPerPage: number) => {
            const transformedFilter = {
                ...filter,
                happeningTypes: filter?.happeningTypes?.map((type: TagViewModel) => type.id),
                isInternal: filter?.isInternal,
                toDate: toEndOfDay(filter?.toDate),
                isAccessible: filter?.isAccessible ? FilterInclusion.With : FilterInclusion.Both,
                full: filter?.full ? FilterInclusion.Without : FilterInclusion.Both,
            } as Partial<GetHappeningsRequest>;

            return api.getHappenings({
                version: Env.REACT_APP_BACKEND_VERSION,
                itemsPerPage,
                itemsToSkip: 0,
                page,
                purpose: UsagePurpose.Participation,
                groupClusters: clusterId ? false : true,
                clusters: clusterId ? [clusterId] : undefined,
                ...transformedFilter,
            });
        },
        [clusterId, api],
    );

    return useApiCallback(callback);
}

export function useGetHappening() {
    const api = useHappeningsApi();

    const callback = useCallback(
        (id: string) => {
            return api.getHappening({
                version: Env.REACT_APP_BACKEND_VERSION,
                id,
            });
        },
        [api],
    );

    return useApiCallback(callback);
}

export function useGetHappeningCard() {
    const api = useHappeningsApi();

    const callback = useCallback(
        (id: string) => {
            return api.getHappeningCard({
                version: Env.REACT_APP_BACKEND_VERSION,
                id,
            });
        },
        [api],
    );

    return useApiCallback(callback);
}

export function useGetHappeningsMap(clusterId?: string) {
    const api = useHappeningsApi();
    const callback = useCallback(
        (filter: MapHappeningFilter) => {
            const transformedFilter = {
                ...filter,
                happeningTypes: filter?.happeningTypes?.map((type: TagViewModel) => type.id),
                isInternal: filter?.isInternal,
                toDate: toEndOfDay(filter?.toDate),
                isAccessible: filter?.isAccessible ? FilterInclusion.With : FilterInclusion.Both,
                full: filter?.full ? FilterInclusion.Without : FilterInclusion.Both,
            } as Partial<GetMapHappeningsRequest>;

            return api.getMapHappenings({
                version: Env.REACT_APP_BACKEND_VERSION,
                purpose: UsagePurpose.Participation,
                clusters: clusterId ? [clusterId] : undefined,
                ...transformedFilter,
            });
        },
        [clusterId, api],
    );

    return useApiCallback(callback);
}

export const useGetHappeningClusterListCallback = (viewport: GeoViewport, clusterId?: string) => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        (filter?: HappeningFilter) => {
            const transformedFilter = {
                ...filter,
                happeningTypes: filter?.happeningTypes?.map((type: TagViewModel) => type.id),
                isInternal: filter?.isInternal,
                toDate: toEndOfDay(filter?.toDate),
                isAccessible: filter?.isAccessible ? FilterInclusion.With : FilterInclusion.Both,
                full: filter?.full ? FilterInclusion.Without : FilterInclusion.Both,
            } as Partial<GetHappeningsRequest>;

            return api.getHappenings({
                version: Env.REACT_APP_BACKEND_VERSION,
                groupClusters: clusterId ? false : true,
                clusters: clusterId ? [clusterId] : undefined,
                ...transformedFilter,
                ...viewport,
            });
        },
        [api, viewport, clusterId],
    );

    return useApiCallback(callback);
};
