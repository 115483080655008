/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import { HappeningState, HappeningStateFromJSON, HappeningStateFromJSONTyped, HappeningStateToJSON } from "./";

/**
 *
 * @export
 * @interface ClusteredHappenings
 */
export interface ClusteredHappenings {
    /**
     *
     * @type {string}
     * @memberof ClusteredHappenings
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ClusteredHappenings
     */
    title?: string;
    /**
     *
     * @type {HappeningState}
     * @memberof ClusteredHappenings
     */
    state?: HappeningState;
    /**
     *
     * @type {HappeningState}
     * @memberof ClusteredHappenings
     */
    displayState?: HappeningState;
}

/**
 * Check if a given object implements the ClusteredHappenings interface.
 */
export function instanceOfClusteredHappenings(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClusteredHappeningsFromJSON(json: any): ClusteredHappenings {
    return ClusteredHappeningsFromJSONTyped(json, false);
}

export function ClusteredHappeningsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusteredHappenings {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        title: !exists(json, "title") ? (undefined as any) : json["title"],
        state: !exists(json, "state") ? (undefined as any) : HappeningStateFromJSON(json["state"]),
        displayState: !exists(json, "displayState") ? (undefined as any) : HappeningStateFromJSON(json["displayState"]),
    };
}

export function ClusteredHappeningsToJSON(value?: ClusteredHappenings | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
