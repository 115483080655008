import React, { useCallback, useEffect, useRef } from "react";
import styles from "./OrganisationDetail.module.scss";
import classNames from "classnames";
import { OrganisationInfoViewModel } from "../../openapi/backend";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import OrganisationDetailInfoSection from "../OrganisationDetailInfoSection/OrganisationDetailInfoSection";
import OrganisationIntro from "../OrganisationIntro/OrganisationIntro";
import ContactPersonFooter from "../ContactPersonFooter/ContactPersonFooter";
import OrganisationDetailCard from "../OrganisationDetailCard/OrganisationDetailCard";

export type ReusableProps = {
    onResize?: (height: number, width: number) => void;
};

type Props = ReusableProps & {
    className: string;
    organisation: OrganisationInfoViewModel;
    embed?: boolean;
    hideFooter?: boolean;
};

const OrganisationDetail = ({ className, organisation, embed, hideFooter, onResize }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const goBack = useCallback(() => navigate(-1), [navigate]);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!containerRef.current || !onResize) {
            return;
        }

        const resizeAction = () => {
            onResize(containerRef.current?.clientHeight || 0, containerRef.current?.clientWidth || 0);
        };

        resizeAction();

        const resizeObserver = new ResizeObserver(resizeAction);
        resizeObserver.observe(containerRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [onResize]);

    return (
        <div className={classNames(styles.container, className)} ref={containerRef}>
            <div className={styles.grid}>
                <div className={styles.main}>
                    {!embed && (
                        <Button
                            className={styles.back}
                            displayType="navigation"
                            onClick={goBack}
                            label={t("navigation.back.organisationsOverview")}
                        />
                    )}
                    <OrganisationIntro className={styles.intro} organisation={organisation} />
                    <OrganisationDetailInfoSection className={styles.details} organisation={organisation} />
                </div>
                <OrganisationDetailCard className={styles.sidebar} organisation={organisation} />
            </div>
            {organisation.contact && !hideFooter && (
                <ContactPersonFooter className={styles.footer} contact={organisation.contact} />
            )}
        </div>
    );
};

OrganisationDetail.defaultProps = {
    className: "",
};

export default OrganisationDetail;
