import React, { useCallback, useEffect, useState } from "react";
import { HappeningViewModel } from "../../openapi/backend";
import HappeningDetailInfoSection from "./HappeningDetailInfoSection";
import { useIntentParticipation } from "../../hooks/useRegistrationsApiHooks";
import { RequestState } from "../../types/RequestState";
import { useParseBackendError } from "../../hooks/useBackendError";

type Props = {
    className: string;
    happening: HappeningViewModel;
};

const HappeningDetailInfoSectionContainer = ({ happening, ...props }: Props) => {
    const [{ state, error }, intentParticipation] = useIntentParticipation();
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const onIntentParticipation = useCallback(
        () => intentParticipation(happening.id),
        [happening, intentParticipation],
    );
    const { title: errorMessage } = useParseBackendError(error);

    useEffect(() => {
        if (state === RequestState.DONE) {
            setDisableButton(true);
        }
    }, [state]);

    return (
        <HappeningDetailInfoSection
            disableButton={disableButton}
            onIntentParticipation={onIntentParticipation}
            loading={state === RequestState.LOADING}
            happening={happening}
            error={error ? errorMessage : undefined}
            {...props}
        />
    );
};

HappeningDetailInfoSectionContainer.defaultProps = {
    className: "",
};

export default HappeningDetailInfoSectionContainer;
