import classNames from "classnames";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { HappeningFilter } from "../../hooks/useHappeningsApiHooks";
import AgeRange from "../AgeRange/AgeRange";
import Button from "../Button/Button";
import DatePicker from "../DatePicker/DatePicker";
import SearchInput from "../SearchInput/SearchInput";
import Text from "../Text/Text";
import styles from "./HappeningListFilter.module.scss";
import Checkbox from "../Checkbox/Checkbox";
import HintButton from "../HintButton/HintButton";

type Props = {
    className: string;
    filters?: HappeningFilter;
    onChange: (filters: HappeningFilter) => void;
    onApply: () => void;
    onReset: () => void;
};

type DateRangeArray = [Date | null, Date | null];

const convertDateArrayToDateRange = ([fromDate, toDate]: DateRangeArray) => ({
    fromDate: fromDate ?? undefined,
    toDate: toDate ?? undefined,
});

const HappeningListFilter = ({ className, filters, onChange, onApply, onReset }: Props) => {
    const { t } = useTranslation();

    const onChangeDateRange = useCallback(
        (dateRange: DateRangeArray) => {
            onChange({ ...filters, ...convertDateArrayToDateRange(dateRange) });
        },
        [filters, onChange],
    );

    // Media queries taken from mixins.cardGrid
    const isTripleColumn = useMediaQuery({ minWidth: 901, maxWidth: 1399 });
    const isTwoColumn = useMediaQuery({ minWidth: 618, maxWidth: 900 });
    const isSingleColumn = useMediaQuery({ minWidth: 0, maxWidth: 617 });

    const SearchButton = useCallback(
        () => (
            <Button
                className={classNames(styles.applyButton)}
                displayType="default"
                label={t("overview.filters.button")}
                onClick={onApply}
            />
        ),
        [onApply, t],
    );

    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.row}>
                <div className={styles.filter}>
                    <Text className={styles.filterName} label={`${t("overview.filters.date")}:`} />
                    <DatePicker
                        minDate={new Date()}
                        startDate={filters?.fromDate}
                        endDate={filters?.toDate}
                        onChange={onChangeDateRange}
                        selectsRange
                        id="date-picker"
                    />
                </div>
                <div className={styles.filter}>
                    <Text className={styles.filterName} label={`${t("overview.filters.age")}:`} />
                    <AgeRange
                        minAge={filters?.minAge}
                        maxAge={filters?.maxAge}
                        onChange={(value) => onChange({ ...filters, ...value })}
                    />
                </div>
                <div className={classNames(!isSingleColumn && !isTripleColumn && styles.duo, styles.filter)}>
                    <Text className={styles.filterName} label={`${t("overview.filters.search")}:`} />
                    <div className={classNames(styles.searchAction)}>
                        <SearchInput
                            className={styles.single}
                            defaultValue={filters?.query ?? ""}
                            onChange={(value) => onChange({ ...filters, query: value })}
                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    onApply();
                                }
                            }}
                        />
                        {!isSingleColumn && <SearchButton />}
                    </div>
                </div>
            </div>
            <div className={styles.row}>
                <div
                    className={classNames(
                        styles.multiContainer,
                        !isTwoColumn && !isSingleColumn && styles.duo,
                        isTwoColumn && styles.twoColumn,
                        isSingleColumn && styles.singleColumn,
                    )}
                >
                    <Checkbox
                        className={styles.checkbox}
                        label={t("overview.filters.full")}
                        id="filter-full"
                        onChange={(value) => onChange({ ...filters, full: value })}
                        checked={!!filters?.full}
                        name="filter-full"
                    />
                    <Checkbox
                        className={styles.checkbox}
                        label={t("overview.filters.accessible.label")}
                        id="filter-accessible"
                        onChange={(value) => onChange({ ...filters, isAccessible: value })}
                        checked={!!filters?.isAccessible}
                        name="filter-accessible"
                    >
                        <HintButton
                            id="accessible"
                            place="right"
                            unsafeHtml={t("overview.filters.accessible.hint")}
                            className={styles.hint}
                        />
                    </Checkbox>
                </div>
                <Button
                    className={styles.resetButton}
                    displayType="link"
                    label={t("overview.filters.reset")}
                    onClick={onReset}
                />
            </div>
            {isSingleColumn && (
                <div className={classNames(styles.row, styles.endAligned)}>
                    <SearchButton />
                </div>
            )}
        </div>
    );
};

HappeningListFilter.defaultProps = {
    className: "",
};

export default HappeningListFilter;
