/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PaginationLinks
 */
export interface PaginationLinks {
    /**
     *
     * @type {string}
     * @memberof PaginationLinks
     */
    self: string;
    /**
     *
     * @type {string}
     * @memberof PaginationLinks
     */
    last: string;
    /**
     *
     * @type {string}
     * @memberof PaginationLinks
     */
    next?: string;
    /**
     *
     * @type {string}
     * @memberof PaginationLinks
     */
    previous?: string;
}

/**
 * Check if a given object implements the PaginationLinks interface.
 */
export function instanceOfPaginationLinks(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "self" in value;
    isInstance = isInstance && "last" in value;

    return isInstance;
}

export function PaginationLinksFromJSON(json: any): PaginationLinks {
    return PaginationLinksFromJSONTyped(json, false);
}

export function PaginationLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationLinks {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        self: !exists(json, "self") ? (undefined as any) : json["self"],
        last: !exists(json, "last") ? (undefined as any) : json["last"],
        next: !exists(json, "next") ? (undefined as any) : json["next"],
        previous: !exists(json, "previous") ? (undefined as any) : json["previous"],
    };
}

export function PaginationLinksToJSON(value?: PaginationLinks | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
