export interface PoorMansErrorContent {
    type: string;
    title: string;
    status: number;
    traceId: string;
    errors: { [key: string]: string[] };
}

export function isParseableValidationError(error: any): error is PoorMansErrorContent {
    if (!error) {
        return false;
    }

    return error.errors !== undefined;
}

export function getErrorMessages(content: PoorMansErrorContent) {
    return Object.values(content.errors)
        .flatMap((m) => m)
        .filter((m) => !!m);
}

export async function parseError(error: Response | undefined) {
    if (!error) {
        return [];
    }

    try {
        const errorContent = await error
            // .json consumes the body, so use clone to infinitely print
            .clone()
            .json();

        if (isParseableValidationError(errorContent)) {
            return getErrorMessages(errorContent);
        }
    } catch (e) {
        // Depending on the error the error could be a string instead of a json object.
        // Should use .text instead of .json in those cases.
        // Depending if we want to support the text cases, then you can have a peak at how SixBlocks handles it
        // https://git.milvum.com/sixblocks/sixblocks-monorepo/-/blob/develop/WebClientCanvas/src/hooks/useApiCall.ts
        // https://git.milvum.com/sixblocks/sixblocks-monorepo/-/blob/develop/WebClientCanvas/src/utils/NotificationUtils.tsx
        console.error("[BackendError] Something went wrong from while reading error response", e);
    }

    return [];
}
