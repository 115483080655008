export function hasValues<T>(array: Array<T> | undefined): array is Array<T> {
    if (!array) {
        return false;
    }

    return array.length !== 0;
}

export function isEmpty<T>(array: Array<T> | undefined): array is Array<T> {
    if (!array) {
        return false;
    }

    return array.length === 0;
}
