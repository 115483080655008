import { useMemo } from "react";
import { HappeningType } from "../openapi/backend";

export function useIsPrivateHappening(type: HappeningType) {
    return useMemo(() => type === HappeningType.PrivateHappening, [type]);
}

export function useIsStandardHappening(type: HappeningType) {
    return useMemo(() => type === HappeningType.Happening, [type]);
}
