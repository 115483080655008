import React, { useCallback, useEffect, useMemo } from "react";
import { useGetOrganisationsClusterListCallback } from "../../hooks/useOrganisationApiHooks";
import NearbyList from "./NearbyList";
import { GeoViewport } from "../../utils/MapUtils";
import { RequestState } from "../../types/RequestState";
import { toastError } from "../../utils/Toaster";
import { useParseBackendError } from "../../hooks/useBackendError";
interface Props {
    viewport: GeoViewport;
}
function NearbyListClusterContainer({ viewport }: Props) {
    const [{ value, state, error }, fetchRequest] = useGetOrganisationsClusterListCallback(viewport);
    const fetch = useCallback(() => fetchRequest({}), [fetchRequest]);

    const isLoading = useMemo(() => state === RequestState.LOADING, [state]);
    const { title, message } = useParseBackendError(error);

    if (state === RequestState.ERROR) {
        toastError(`${title} \n ${message}`);
    }

    useEffect(() => {
        fetch();
    }, [fetch]);

    return <NearbyList list={value?.items} loading={isLoading} showOnMap />;
}

export default NearbyListClusterContainer;
