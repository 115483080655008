/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 * - Append nested object fields in array as formdata fields
 * - Always check for nullable in "body: .map" for json patch
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

function appendToParams(formParams: { append(param: string, value: any): any }, path: string, value: any) {
    if (value === undefined) {
        return;
    } else if (Array.isArray(value)) {
        value.forEach((v, i) => appendToParams(formParams, `${path}[${i}]`, v));
    } else if (value instanceof Date) {
        formParams.append(path, value.toUTCString());
    } else if (value instanceof Blob) {
        formParams.append(path, value);
    } else if (typeof value == "object" && !!value) {
        Object.keys(value).forEach((key) => appendToParams(formParams, `${path}.${key}`, value[key]));
    } else {
        formParams.append(path, value);
    }
}

import * as runtime from "../runtime";
import {
    AbsenceInput,
    AbsenceInputFromJSON,
    AbsenceInputToJSON,
    AppointmentViewModel,
    AppointmentViewModelFromJSON,
    AppointmentViewModelToJSON,
    AttendOutput,
    AttendOutputFromJSON,
    AttendOutputToJSON,
    CountOutput,
    CountOutputFromJSON,
    CountOutputToJSON,
    ExistingProfileHappeningRegistationInput,
    ExistingProfileHappeningRegistationInputFromJSON,
    ExistingProfileHappeningRegistationInputToJSON,
    GroupHappeningRegistrationOutput,
    GroupHappeningRegistrationOutputFromJSON,
    GroupHappeningRegistrationOutputToJSON,
    HappeningRegistrationResult,
    HappeningRegistrationResultFromJSON,
    HappeningRegistrationResultToJSON,
    HappeningTimeAndDateInput,
    HappeningTimeAndDateInputFromJSON,
    HappeningTimeAndDateInputToJSON,
    NewProfileHappeningRegistrationInput,
    NewProfileHappeningRegistrationInputFromJSON,
    NewProfileHappeningRegistrationInputToJSON,
    OptionalBoolean,
    OptionalBooleanFromJSON,
    OptionalBooleanToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    ProfileRegistrationInput,
    ProfileRegistrationInputFromJSON,
    ProfileRegistrationInputToJSON,
} from "../models";

export interface AddMemberToGroupRequest {
    registrationId: string;
    version: string;
    happeningId: string;
    profileRegistrationInput?: ProfileRegistrationInput;
}

export interface AttendRequest {
    participantId: string;
    happeningId: string;
    version: string;
    happeningDateTimeId: number;
    attending?: boolean;
    attendingReason?: string;
    paidCash?: OptionalBoolean;
}

export interface CountAnonymousRegistrationsRequest {
    happeningId: string;
    version: string;
    count?: number;
    sessionId?: number;
}

export interface CreateAppointmentRequest {
    registrationId: string;
    version: string;
    appointer: string;
    sessions?: Array<HappeningTimeAndDateInput>;
    locationName?: string;
    locationAddress?: string;
    locationLongitude?: number;
    locationLatitude?: number;
    notes?: string;
}

export interface EditGroupRequest {
    happeningId: string;
    registrationId: string;
    version: string;
    existingProfiles?: Array<ExistingProfileHappeningRegistationInput>;
    newProfiles?: Array<NewProfileHappeningRegistrationInput>;
    groupMembers?: Array<ProfileRegistrationInput>;
    notes?: string;
    approvedForMarketing?: boolean;
    skipPayment?: boolean;
    amountOfRegistrations?: number;
    contactIsPartOfGroup?: boolean;
    groupName?: string;
    isGroupRegistration?: boolean;
    registrationDate?: Date;
}

export interface IntentParticipationRequest {
    happeningId: string;
    version: string;
}

export interface ReferRequest {
    registrationId: string;
    version: string;
    happeningId: string;
}

export interface RegisterRequest {
    happeningId: string;
    version: string;
    existingProfiles?: Array<ExistingProfileHappeningRegistationInput>;
    newProfiles?: Array<NewProfileHappeningRegistrationInput>;
    groupMembers?: Array<ProfileRegistrationInput>;
    notes?: string;
    approvedForMarketing?: boolean;
    skipPayment?: boolean;
    amountOfRegistrations?: number;
    contactIsPartOfGroup?: boolean;
    groupName?: string;
    isGroupRegistration?: boolean;
    registrationDate?: Date;
}

export interface RemoveMemberFromGroupRequest {
    member: string;
    registrationId: string;
    version: string;
    happeningId: string;
    memberId: string;
}

export interface ReportAbsenceRequest {
    registrationId: string;
    sessionId: number;
    version: string;
    absenceInput?: AbsenceInput;
}

export interface ResetAnonymousRegistrationsCountRequest {
    happeningId: string;
    version: string;
    sessionId?: number;
}

export interface SetAnonymousRegistrationsCountRequest {
    happeningId: string;
    version: string;
    count?: number;
    sessionId?: number;
}

export interface UnsubscribeRequest {
    happeningId: string;
    version: string;
    profileIds?: Array<string>;
    reason?: string;
}

/**
 * no description
 */
export class RegistrationsApi extends runtime.BaseAPI {
    /**
     */
    async addMemberToGroupRaw(
        requestParameters: AddMemberToGroupRequest,
    ): Promise<runtime.ApiResponse<GroupHappeningRegistrationOutput>> {
        if (requestParameters.registrationId === null || requestParameters.registrationId === undefined) {
            throw new runtime.RequiredError(
                "registrationId",
                "Required parameter requestParameters.registrationId was null or undefined when calling addMemberToGroup.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling addMemberToGroup.",
            );
        }
        if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
            throw new runtime.RequiredError(
                "happeningId",
                "Required parameter requestParameters.happeningId was null or undefined when calling addMemberToGroup.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters["Content-Type"] = "application/json-patch+json";
        const response = await this.request({
            path: `/v{version}/Registrations/{happeningId}/{registrationId}/member`
                .replace(`{${"registrationId"}}`, encodeURIComponent(String(requestParameters.registrationId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version)))
                .replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: ProfileRegistrationInputToJSON(requestParameters.profileRegistrationInput),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            GroupHappeningRegistrationOutputFromJSON(jsonValue),
        );
    }
    /**
     */
    async addMemberToGroup(requestParameters: AddMemberToGroupRequest): Promise<GroupHappeningRegistrationOutput> {
        const response = await this.addMemberToGroupRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async attendRaw(requestParameters: AttendRequest): Promise<runtime.ApiResponse<AttendOutput>> {
        if (requestParameters.participantId === null || requestParameters.participantId === undefined) {
            throw new runtime.RequiredError(
                "participantId",
                "Required parameter requestParameters.participantId was null or undefined when calling attend.",
            );
        }
        if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
            throw new runtime.RequiredError(
                "happeningId",
                "Required parameter requestParameters.happeningId was null or undefined when calling attend.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling attend.",
            );
        }
        if (requestParameters.happeningDateTimeId === null || requestParameters.happeningDateTimeId === undefined) {
            throw new runtime.RequiredError(
                "happeningDateTimeId",
                "Required parameter requestParameters.happeningDateTimeId was null or undefined when calling attend.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.happeningDateTimeId !== undefined) {
            formParams.append("HappeningDateTimeId", requestParameters.happeningDateTimeId as any);
            hasParams = true;
        }
        if (requestParameters.attending !== undefined) {
            formParams.append("Attending", requestParameters.attending as any);
            hasParams = true;
        }
        if (requestParameters.attendingReason !== undefined) {
            formParams.append("AttendingReason", requestParameters.attendingReason as any);
            hasParams = true;
        }
        if (requestParameters.paidCash !== undefined) {
            formParams.append("PaidCash", requestParameters.paidCash as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Registrations/{participantId}/{happeningId}/attend`
                .replace(`{${"participantId"}}`, encodeURIComponent(String(requestParameters.participantId)))
                .replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => AttendOutputFromJSON(jsonValue));
    }
    /**
     */
    async attend(requestParameters: AttendRequest): Promise<AttendOutput> {
        const response = await this.attendRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async countAnonymousRegistrationsRaw(
        requestParameters: CountAnonymousRegistrationsRequest,
    ): Promise<runtime.ApiResponse<CountOutput>> {
        if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
            throw new runtime.RequiredError(
                "happeningId",
                "Required parameter requestParameters.happeningId was null or undefined when calling countAnonymousRegistrations.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling countAnonymousRegistrations.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.count !== undefined) {
            queryParameters["count"] = requestParameters.count;
        }
        if (requestParameters.sessionId !== undefined) {
            queryParameters["sessionId"] = requestParameters.sessionId;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Registrations/{happeningId}/count`
                .replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => CountOutputFromJSON(jsonValue));
    }
    /**
     */
    async countAnonymousRegistrations(requestParameters: CountAnonymousRegistrationsRequest): Promise<CountOutput> {
        const response = await this.countAnonymousRegistrationsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async createAppointmentRaw(
        requestParameters: CreateAppointmentRequest,
    ): Promise<runtime.ApiResponse<Array<AppointmentViewModel>>> {
        if (requestParameters.registrationId === null || requestParameters.registrationId === undefined) {
            throw new runtime.RequiredError(
                "registrationId",
                "Required parameter requestParameters.registrationId was null or undefined when calling createAppointment.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling createAppointment.",
            );
        }
        if (requestParameters.appointer === null || requestParameters.appointer === undefined) {
            throw new runtime.RequiredError(
                "appointer",
                "Required parameter requestParameters.appointer was null or undefined when calling createAppointment.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.sessions) {
            requestParameters.sessions.forEach((element, index) => {
                appendToParams(formParams, `Sessions[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.appointer !== undefined) {
            formParams.append("Appointer", requestParameters.appointer as any);
            hasParams = true;
        }
        if (requestParameters.locationName !== undefined) {
            formParams.append("Location.Name", requestParameters.locationName as any);
            hasParams = true;
        }
        if (requestParameters.locationAddress !== undefined) {
            formParams.append("Location.Address", requestParameters.locationAddress as any);
            hasParams = true;
        }
        if (requestParameters.locationLongitude !== undefined) {
            formParams.append("Location.Longitude", requestParameters.locationLongitude as any);
            hasParams = true;
        }
        if (requestParameters.locationLatitude !== undefined) {
            formParams.append("Location.Latitude", requestParameters.locationLatitude as any);
            hasParams = true;
        }
        if (requestParameters.notes !== undefined) {
            formParams.append("Notes", requestParameters.notes as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Registrations/{registrationId}/appointment`
                .replace(`{${"registrationId"}}`, encodeURIComponent(String(requestParameters.registrationId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppointmentViewModelFromJSON));
    }
    /**
     */
    async createAppointment(requestParameters: CreateAppointmentRequest): Promise<Array<AppointmentViewModel>> {
        const response = await this.createAppointmentRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async editGroupRaw(
        requestParameters: EditGroupRequest,
    ): Promise<runtime.ApiResponse<GroupHappeningRegistrationOutput>> {
        if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
            throw new runtime.RequiredError(
                "happeningId",
                "Required parameter requestParameters.happeningId was null or undefined when calling editGroup.",
            );
        }
        if (requestParameters.registrationId === null || requestParameters.registrationId === undefined) {
            throw new runtime.RequiredError(
                "registrationId",
                "Required parameter requestParameters.registrationId was null or undefined when calling editGroup.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling editGroup.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.existingProfiles) {
            requestParameters.existingProfiles.forEach((element, index) => {
                appendToParams(formParams, `ExistingProfiles[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.newProfiles) {
            requestParameters.newProfiles.forEach((element, index) => {
                appendToParams(formParams, `NewProfiles[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.groupMembers) {
            requestParameters.groupMembers.forEach((element, index) => {
                appendToParams(formParams, `GroupMembers[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.notes !== undefined) {
            formParams.append("Notes", requestParameters.notes as any);
            hasParams = true;
        }
        if (requestParameters.approvedForMarketing !== undefined) {
            formParams.append("ApprovedForMarketing", requestParameters.approvedForMarketing as any);
            hasParams = true;
        }
        if (requestParameters.skipPayment !== undefined) {
            formParams.append("SkipPayment", requestParameters.skipPayment as any);
            hasParams = true;
        }
        if (requestParameters.amountOfRegistrations !== undefined) {
            formParams.append("AmountOfRegistrations", requestParameters.amountOfRegistrations as any);
            hasParams = true;
        }
        if (requestParameters.contactIsPartOfGroup !== undefined) {
            formParams.append("ContactIsPartOfGroup", requestParameters.contactIsPartOfGroup as any);
            hasParams = true;
        }
        if (requestParameters.groupName !== undefined) {
            formParams.append("GroupName", requestParameters.groupName as any);
            hasParams = true;
        }
        if (requestParameters.isGroupRegistration !== undefined) {
            formParams.append("IsGroupRegistration", requestParameters.isGroupRegistration as any);
            hasParams = true;
        }
        if (requestParameters.registrationDate !== undefined) {
            formParams.append("RegistrationDate", (requestParameters.registrationDate as any).toUTCString());
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Registrations/{happeningId}/{registrationId}`
                .replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId)))
                .replace(`{${"registrationId"}}`, encodeURIComponent(String(requestParameters.registrationId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            GroupHappeningRegistrationOutputFromJSON(jsonValue),
        );
    }
    /**
     */
    async editGroup(requestParameters: EditGroupRequest): Promise<GroupHappeningRegistrationOutput> {
        const response = await this.editGroupRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async intentParticipationRaw(requestParameters: IntentParticipationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
            throw new runtime.RequiredError(
                "happeningId",
                "Required parameter requestParameters.happeningId was null or undefined when calling intentParticipation.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling intentParticipation.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Registrations/{happeningId}/intent`
                .replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async intentParticipation(requestParameters: IntentParticipationRequest): Promise<void> {
        await this.intentParticipationRaw(requestParameters);
    }
    /**
     */
    async referRaw(requestParameters: ReferRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.registrationId === null || requestParameters.registrationId === undefined) {
            throw new runtime.RequiredError(
                "registrationId",
                "Required parameter requestParameters.registrationId was null or undefined when calling refer.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling refer.",
            );
        }
        if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
            throw new runtime.RequiredError(
                "happeningId",
                "Required parameter requestParameters.happeningId was null or undefined when calling refer.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.happeningId !== undefined) {
            formParams.append("HappeningId", requestParameters.happeningId as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Registrations/{registrationId}/refer`
                .replace(`{${"registrationId"}}`, encodeURIComponent(String(requestParameters.registrationId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async refer(requestParameters: ReferRequest): Promise<void> {
        await this.referRaw(requestParameters);
    }
    /**
     */
    async registerRaw(requestParameters: RegisterRequest): Promise<runtime.ApiResponse<HappeningRegistrationResult>> {
        if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
            throw new runtime.RequiredError(
                "happeningId",
                "Required parameter requestParameters.happeningId was null or undefined when calling register.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling register.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.existingProfiles) {
            requestParameters.existingProfiles.forEach((element, index) => {
                appendToParams(formParams, `ExistingProfiles[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.newProfiles) {
            requestParameters.newProfiles.forEach((element, index) => {
                appendToParams(formParams, `NewProfiles[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.groupMembers) {
            requestParameters.groupMembers.forEach((element, index) => {
                appendToParams(formParams, `GroupMembers[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.notes !== undefined) {
            formParams.append("Notes", requestParameters.notes as any);
            hasParams = true;
        }
        if (requestParameters.approvedForMarketing !== undefined) {
            formParams.append("ApprovedForMarketing", requestParameters.approvedForMarketing as any);
            hasParams = true;
        }
        if (requestParameters.skipPayment !== undefined) {
            formParams.append("SkipPayment", requestParameters.skipPayment as any);
            hasParams = true;
        }
        if (requestParameters.amountOfRegistrations !== undefined) {
            formParams.append("AmountOfRegistrations", requestParameters.amountOfRegistrations as any);
            hasParams = true;
        }
        if (requestParameters.contactIsPartOfGroup !== undefined) {
            formParams.append("ContactIsPartOfGroup", requestParameters.contactIsPartOfGroup as any);
            hasParams = true;
        }
        if (requestParameters.groupName !== undefined) {
            formParams.append("GroupName", requestParameters.groupName as any);
            hasParams = true;
        }
        if (requestParameters.isGroupRegistration !== undefined) {
            formParams.append("IsGroupRegistration", requestParameters.isGroupRegistration as any);
            hasParams = true;
        }
        if (requestParameters.registrationDate !== undefined) {
            formParams.append("RegistrationDate", (requestParameters.registrationDate as any).toUTCString());
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Registrations/{happeningId}`
                .replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HappeningRegistrationResultFromJSON(jsonValue));
    }
    /**
     */
    async register(requestParameters: RegisterRequest): Promise<HappeningRegistrationResult> {
        const response = await this.registerRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async removeMemberFromGroupRaw(
        requestParameters: RemoveMemberFromGroupRequest,
    ): Promise<runtime.ApiResponse<GroupHappeningRegistrationOutput>> {
        if (requestParameters.member === null || requestParameters.member === undefined) {
            throw new runtime.RequiredError(
                "member",
                "Required parameter requestParameters.member was null or undefined when calling removeMemberFromGroup.",
            );
        }
        if (requestParameters.registrationId === null || requestParameters.registrationId === undefined) {
            throw new runtime.RequiredError(
                "registrationId",
                "Required parameter requestParameters.registrationId was null or undefined when calling removeMemberFromGroup.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling removeMemberFromGroup.",
            );
        }
        if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
            throw new runtime.RequiredError(
                "happeningId",
                "Required parameter requestParameters.happeningId was null or undefined when calling removeMemberFromGroup.",
            );
        }
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError(
                "memberId",
                "Required parameter requestParameters.memberId was null or undefined when calling removeMemberFromGroup.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Registrations/{happeningId}/{registrationId}/member/{memberId}`
                .replace(`{${"member"}}`, encodeURIComponent(String(requestParameters.member)))
                .replace(`{${"registrationId"}}`, encodeURIComponent(String(requestParameters.registrationId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version)))
                .replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId)))
                .replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            GroupHappeningRegistrationOutputFromJSON(jsonValue),
        );
    }
    /**
     */
    async removeMemberFromGroup(
        requestParameters: RemoveMemberFromGroupRequest,
    ): Promise<GroupHappeningRegistrationOutput> {
        const response = await this.removeMemberFromGroupRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async reportAbsenceRaw(requestParameters: ReportAbsenceRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.registrationId === null || requestParameters.registrationId === undefined) {
            throw new runtime.RequiredError(
                "registrationId",
                "Required parameter requestParameters.registrationId was null or undefined when calling reportAbsence.",
            );
        }
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError(
                "sessionId",
                "Required parameter requestParameters.sessionId was null or undefined when calling reportAbsence.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling reportAbsence.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters["Content-Type"] = "application/json-patch+json";
        const response = await this.request({
            path: `/v{version}/Registrations/{registrationId}/absent/{sessionId}`
                .replace(`{${"registrationId"}}`, encodeURIComponent(String(requestParameters.registrationId)))
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: AbsenceInputToJSON(requestParameters.absenceInput),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async reportAbsence(requestParameters: ReportAbsenceRequest): Promise<void> {
        await this.reportAbsenceRaw(requestParameters);
    }
    /**
     */
    async resetAnonymousRegistrationsCountRaw(
        requestParameters: ResetAnonymousRegistrationsCountRequest,
    ): Promise<runtime.ApiResponse<CountOutput>> {
        if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
            throw new runtime.RequiredError(
                "happeningId",
                "Required parameter requestParameters.happeningId was null or undefined when calling resetAnonymousRegistrationsCount.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling resetAnonymousRegistrationsCount.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.sessionId !== undefined) {
            queryParameters["sessionId"] = requestParameters.sessionId;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Registrations/{happeningId}/count/reset`
                .replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => CountOutputFromJSON(jsonValue));
    }
    /**
     */
    async resetAnonymousRegistrationsCount(
        requestParameters: ResetAnonymousRegistrationsCountRequest,
    ): Promise<CountOutput> {
        const response = await this.resetAnonymousRegistrationsCountRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async setAnonymousRegistrationsCountRaw(
        requestParameters: SetAnonymousRegistrationsCountRequest,
    ): Promise<runtime.ApiResponse<CountOutput>> {
        if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
            throw new runtime.RequiredError(
                "happeningId",
                "Required parameter requestParameters.happeningId was null or undefined when calling setAnonymousRegistrationsCount.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling setAnonymousRegistrationsCount.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.count !== undefined) {
            queryParameters["count"] = requestParameters.count;
        }
        if (requestParameters.sessionId !== undefined) {
            queryParameters["sessionId"] = requestParameters.sessionId;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Registrations/{happeningId}/count/set`
                .replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => CountOutputFromJSON(jsonValue));
    }
    /**
     */
    async setAnonymousRegistrationsCount(
        requestParameters: SetAnonymousRegistrationsCountRequest,
    ): Promise<CountOutput> {
        const response = await this.setAnonymousRegistrationsCountRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async unsubscribeRaw(requestParameters: UnsubscribeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
            throw new runtime.RequiredError(
                "happeningId",
                "Required parameter requestParameters.happeningId was null or undefined when calling unsubscribe.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling unsubscribe.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.profileIds) {
            requestParameters.profileIds.forEach((element, index) => {
                appendToParams(formParams, `ProfileIds[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.reason !== undefined) {
            formParams.append("Reason", requestParameters.reason as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Registrations/{happeningId}/unsubscribe`
                .replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async unsubscribe(requestParameters: UnsubscribeRequest): Promise<void> {
        await this.unsubscribeRaw(requestParameters);
    }
}
