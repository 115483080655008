import React from "react";
import styles from "./CardLine.module.scss";
import classNames from "classnames";
import Text from "../Text/Text";
import {
    CalendarCardIcon,
    CalendarSeriesCardIcon,
    ClockCardIcon,
    EuroCardIcon,
    LocationCardIcon,
    TargetGroupCardIcon,
    TargetGroupGroupCardIcon,
    LockCardIcon,
    CalendarMultipleIcon,
    CalendarSeriesMultipleIcon,
    ClockIconMultiple,
    LocationMultipleIcon,
} from "../../assets/images/Images";

type LineType = "date" | "dateSeries" | "targetGroup" | "targetGroupGroup" | "time" | "price" | "location";
type LineIcon = LineType | "lock";
type LineTheme = "light" | "dark";

type Props = {
    className: string;
    label?: string;
    theme: LineTheme;
    type: LineType;
    icon?: LineIcon;
    altText?: string;
    multiple?: boolean;
};

function getIcon(type: LineIcon, multiple?: boolean) {
    switch (type) {
        case "date":
            return multiple ? CalendarMultipleIcon : CalendarCardIcon;
        case "dateSeries":
            return multiple ? CalendarSeriesMultipleIcon : CalendarSeriesCardIcon;
        case "targetGroup":
            return TargetGroupCardIcon;
        case "targetGroupGroup":
            return TargetGroupGroupCardIcon;
        case "time":
            return multiple ? ClockIconMultiple : ClockCardIcon;
        case "price":
            return EuroCardIcon;
        case "location":
            return multiple ? LocationMultipleIcon : LocationCardIcon;
        case "lock":
            return LockCardIcon;
    }
}

const CardLine = ({ className, label, type, icon, altText, multiple }: Props) => {
    return (
        <div className={classNames(styles.container, className)}>
            <img className={styles.icon} src={getIcon(type, multiple)} />
            {label && (
                <Text
                    className={classNames(styles.label, multiple && styles.multiple)}
                    label={label}
                    type={"info-card-dark"}
                />
            )}
            {icon && <img className={styles.icon} src={getIcon(icon)} alt={altText} />}
        </div>
    );
};

CardLine.defaultProps = {
    className: "",
};

export default CardLine;
