/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    HappeningListItemViewModel,
    HappeningListItemViewModelFromJSON,
    HappeningListItemViewModelFromJSONTyped,
    HappeningListItemViewModelToJSON,
    PaginationLinks,
    PaginationLinksFromJSON,
    PaginationLinksFromJSONTyped,
    PaginationLinksToJSON,
    PaginationMeta,
    PaginationMetaFromJSON,
    PaginationMetaFromJSONTyped,
    PaginationMetaToJSON,
} from "./";

/**
 *
 * @export
 * @interface HappeningListItemViewModelPaginatedViewModel
 */
export interface HappeningListItemViewModelPaginatedViewModel {
    /**
     *
     * @type {PaginationMeta}
     * @memberof HappeningListItemViewModelPaginatedViewModel
     */
    meta: PaginationMeta;
    /**
     *
     * @type {Array<HappeningListItemViewModel>}
     * @memberof HappeningListItemViewModelPaginatedViewModel
     */
    items: Array<HappeningListItemViewModel>;
    /**
     *
     * @type {PaginationLinks}
     * @memberof HappeningListItemViewModelPaginatedViewModel
     */
    links: PaginationLinks;
}

/**
 * Check if a given object implements the HappeningListItemViewModelPaginatedViewModel interface.
 */
export function instanceOfHappeningListItemViewModelPaginatedViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "meta" in value;
    isInstance = isInstance && "items" in value;
    isInstance = isInstance && "links" in value;

    return isInstance;
}

export function HappeningListItemViewModelPaginatedViewModelFromJSON(
    json: any,
): HappeningListItemViewModelPaginatedViewModel {
    return HappeningListItemViewModelPaginatedViewModelFromJSONTyped(json, false);
}

export function HappeningListItemViewModelPaginatedViewModelFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): HappeningListItemViewModelPaginatedViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        meta: !exists(json, "meta") ? (undefined as any) : PaginationMetaFromJSON(json["meta"]),
        items: !exists(json, "items")
            ? (undefined as any)
            : ((json["items"] as Array<any>) ?? []).map(HappeningListItemViewModelFromJSON),
        links: !exists(json, "links") ? (undefined as any) : PaginationLinksFromJSON(json["links"]),
    };
}

export function HappeningListItemViewModelPaginatedViewModelToJSON(
    value?: HappeningListItemViewModelPaginatedViewModel | null,
): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
