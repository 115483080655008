/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum LocationType {
    Online = "Online",
    OnLocation = "OnLocation",
    AtHome = "AtHome",
    ToBeDetermined = "ToBeDetermined",
    Multiple = "Multiple",
}

export function LocationTypeFromJSON(json: any): LocationType {
    return LocationTypeFromJSONTyped(json, false);
}

export function LocationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationType {
    return json as LocationType;
}

export function LocationTypeToJSON(value?: LocationType | null): any {
    return value as any;
}
