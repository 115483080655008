import React from "react";
import styles from "./AnchorButton.module.scss";
import classNames from "classnames";
import { HtmlAnchorProps } from "../../types/ReusableTypes";

type ButtonType = "default" | "hollow";

export type Props = HtmlAnchorProps & {
    label: string;
    displayType: ButtonType;
    disabled?: boolean;
};

const AnchorButton = ({ className, label, displayType, disabled, ...props }: Props) => {
    return (
        <a
            className={classNames(styles.container, styles[displayType], disabled && styles.disabled, className)}
            data-testid="AnchorButton"
            target="_blank"
            rel="noreferrer"
            aria-disabled={disabled ? "true" : "false"}
            {...props}
        >
            {label}
        </a>
    );
};

AnchorButton.defaultProps = {
    className: "",
    displayType: "default",
};

export default AnchorButton;
