/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    ContactPerson,
    ContactPersonFromJSON,
    ContactPersonFromJSONTyped,
    ContactPersonToJSON,
    Gender,
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
    HappeningRegistrationOutput,
    HappeningRegistrationOutputFromJSON,
    HappeningRegistrationOutputFromJSONTyped,
    HappeningRegistrationOutputToJSON,
    ImageViewModel,
    ImageViewModelFromJSON,
    ImageViewModelFromJSONTyped,
    ImageViewModelToJSON,
    Location,
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
    TagViewModel,
    TagViewModelFromJSON,
    TagViewModelFromJSONTyped,
    TagViewModelToJSON,
} from "./";

/**
 *
 * @export
 * @interface ProfileOutput
 */
export interface ProfileOutput {
    /**
     *
     * @type {string}
     * @memberof ProfileOutput
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof ProfileOutput
     */
    givenName: string;
    /**
     *
     * @type {string}
     * @memberof ProfileOutput
     */
    familyName: string;
    /**
     *
     * @type {Gender}
     * @memberof ProfileOutput
     */
    gender: Gender;
    /**
     *
     * @type {string}
     * @memberof ProfileOutput
     */
    postalCode?: string;
    /**
     *
     * @type {Location}
     * @memberof ProfileOutput
     */
    postalCodeLocation?: Location;
    /**
     *
     * @type {ImageViewModel}
     * @memberof ProfileOutput
     */
    avatar?: ImageViewModel;
    /**
     *
     * @type {Date}
     * @memberof ProfileOutput
     */
    dateOfBirth?: Date;
    /**
     *
     * @type {string}
     * @memberof ProfileOutput
     */
    phoneNumber?: string;
    /**
     *
     * @type {string}
     * @memberof ProfileOutput
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof ProfileOutput
     */
    school?: string;
    /**
     *
     * @type {string}
     * @memberof ProfileOutput
     */
    profession?: string;
    /**
     *
     * @type {string}
     * @memberof ProfileOutput
     */
    medicalNotes?: string;
    /**
     *
     * @type {boolean}
     * @memberof ProfileOutput
     */
    isBlocked?: boolean;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof ProfileOutput
     */
    tags: Array<TagViewModel>;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof ProfileOutput
     */
    preferredFilters: Array<TagViewModel>;
    /**
     *
     * @type {Array<HappeningRegistrationOutput>}
     * @memberof ProfileOutput
     */
    registrations: Array<HappeningRegistrationOutput>;
    /**
     *
     * @type {ContactPerson}
     * @memberof ProfileOutput
     */
    contactPerson?: ContactPerson;
}

/**
 * Check if a given object implements the ProfileOutput interface.
 */
export function instanceOfProfileOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "givenName" in value;
    isInstance = isInstance && "familyName" in value;
    isInstance = isInstance && "gender" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "preferredFilters" in value;
    isInstance = isInstance && "registrations" in value;

    return isInstance;
}

export function ProfileOutputFromJSON(json: any): ProfileOutput {
    return ProfileOutputFromJSONTyped(json, false);
}

export function ProfileOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileOutput {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        givenName: !exists(json, "givenName") ? (undefined as any) : json["givenName"],
        familyName: !exists(json, "familyName") ? (undefined as any) : json["familyName"],
        gender: !exists(json, "gender") ? (undefined as any) : GenderFromJSON(json["gender"]),
        postalCode: !exists(json, "postalCode") ? (undefined as any) : json["postalCode"],
        postalCodeLocation: !exists(json, "postalCodeLocation")
            ? (undefined as any)
            : LocationFromJSON(json["postalCodeLocation"]),
        avatar: !exists(json, "avatar") ? (undefined as any) : ImageViewModelFromJSON(json["avatar"]),
        dateOfBirth: !exists(json, "dateOfBirth") ? (undefined as any) : new Date(json["dateOfBirth"]),
        phoneNumber: !exists(json, "phoneNumber") ? (undefined as any) : json["phoneNumber"],
        email: !exists(json, "email") ? (undefined as any) : json["email"],
        school: !exists(json, "school") ? (undefined as any) : json["school"],
        profession: !exists(json, "profession") ? (undefined as any) : json["profession"],
        medicalNotes: !exists(json, "medicalNotes") ? (undefined as any) : json["medicalNotes"],
        isBlocked: !exists(json, "isBlocked") ? (undefined as any) : json["isBlocked"],
        tags: !exists(json, "tags")
            ? (undefined as any)
            : ((json["tags"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        preferredFilters: !exists(json, "preferredFilters")
            ? (undefined as any)
            : ((json["preferredFilters"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        registrations: !exists(json, "registrations")
            ? (undefined as any)
            : ((json["registrations"] as Array<any>) ?? []).map(HappeningRegistrationOutputFromJSON),
        contactPerson: !exists(json, "contactPerson")
            ? (undefined as any)
            : ContactPersonFromJSON(json["contactPerson"]),
    };
}

export function ProfileOutputToJSON(value?: ProfileOutput | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
