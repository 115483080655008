/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    OrganizerViewModel,
    OrganizerViewModelFromJSON,
    OrganizerViewModelFromJSONTyped,
    OrganizerViewModelToJSON,
} from "./";

/**
 *
 * @export
 * @interface RegistrationRuleViewModel
 */
export interface RegistrationRuleViewModel {
    /**
     *
     * @type {number}
     * @memberof RegistrationRuleViewModel
     */
    id: number;
    /**
     *
     * @type {Date}
     * @memberof RegistrationRuleViewModel
     */
    startDate: Date;
    /**
     *
     * @type {Date}
     * @memberof RegistrationRuleViewModel
     */
    endDate: Date;
    /**
     *
     * @type {number}
     * @memberof RegistrationRuleViewModel
     */
    maxFreeRegistrations: number;
    /**
     *
     * @type {OrganizerViewModel}
     * @memberof RegistrationRuleViewModel
     */
    organisation: OrganizerViewModel;
}

/**
 * Check if a given object implements the RegistrationRuleViewModel interface.
 */
export function instanceOfRegistrationRuleViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "endDate" in value;
    isInstance = isInstance && "maxFreeRegistrations" in value;
    isInstance = isInstance && "organisation" in value;

    return isInstance;
}

export function RegistrationRuleViewModelFromJSON(json: any): RegistrationRuleViewModel {
    return RegistrationRuleViewModelFromJSONTyped(json, false);
}

export function RegistrationRuleViewModelFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): RegistrationRuleViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        startDate: !exists(json, "startDate") ? (undefined as any) : new Date(json["startDate"]),
        endDate: !exists(json, "endDate") ? (undefined as any) : new Date(json["endDate"]),
        maxFreeRegistrations: !exists(json, "maxFreeRegistrations") ? (undefined as any) : json["maxFreeRegistrations"],
        organisation: !exists(json, "organisation")
            ? (undefined as any)
            : OrganizerViewModelFromJSON(json["organisation"]),
    };
}

export function RegistrationRuleViewModelToJSON(value?: RegistrationRuleViewModel | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
