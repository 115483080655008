/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    HappeningListItemViewModel,
    HappeningListItemViewModelFromJSON,
    HappeningListItemViewModelFromJSONTyped,
    HappeningListItemViewModelToJSON,
    ImageViewModel,
    ImageViewModelFromJSON,
    ImageViewModelFromJSONTyped,
    ImageViewModelToJSON,
    LocationViewModel,
    LocationViewModelFromJSON,
    LocationViewModelFromJSONTyped,
    LocationViewModelToJSON,
    OrganisationContactPersonViewModel,
    OrganisationContactPersonViewModelFromJSON,
    OrganisationContactPersonViewModelFromJSONTyped,
    OrganisationContactPersonViewModelToJSON,
    OrganisationExternalLinkViewModel,
    OrganisationExternalLinkViewModelFromJSON,
    OrganisationExternalLinkViewModelFromJSONTyped,
    OrganisationExternalLinkViewModelToJSON,
    TagViewModel,
    TagViewModelFromJSON,
    TagViewModelFromJSONTyped,
    TagViewModelToJSON,
} from "./";

/**
 *
 * @export
 * @interface OrganisationInfoViewModel
 */
export interface OrganisationInfoViewModel {
    /**
     *
     * @type {string}
     * @memberof OrganisationInfoViewModel
     */
    id: string;
    /**
     *
     * @type {boolean}
     * @memberof OrganisationInfoViewModel
     */
    isActive: boolean;
    /**
     *
     * @type {string}
     * @memberof OrganisationInfoViewModel
     */
    title: string;
    /**
     *
     * @type {TagViewModel}
     * @memberof OrganisationInfoViewModel
     */
    organisationTag?: TagViewModel;
    /**
     *
     * @type {TagViewModel}
     * @memberof OrganisationInfoViewModel
     */
    category: TagViewModel;
    /**
     *
     * @type {TagViewModel}
     * @memberof OrganisationInfoViewModel
     */
    sector?: TagViewModel;
    /**
     *
     * @type {number}
     * @memberof OrganisationInfoViewModel
     */
    minimumAge: number;
    /**
     *
     * @type {number}
     * @memberof OrganisationInfoViewModel
     */
    maximumAge: number;
    /**
     *
     * @type {TagViewModel}
     * @memberof OrganisationInfoViewModel
     */
    specificTargetGroup?: TagViewModel;
    /**
     *
     * @type {string}
     * @memberof OrganisationInfoViewModel
     */
    description: string;
    /**
     *
     * @type {Array<LocationViewModel>}
     * @memberof OrganisationInfoViewModel
     */
    locations: Array<LocationViewModel>;
    /**
     *
     * @type {Array<OrganisationExternalLinkViewModel>}
     * @memberof OrganisationInfoViewModel
     */
    externalLinks: Array<OrganisationExternalLinkViewModel>;
    /**
     *
     * @type {OrganisationContactPersonViewModel}
     * @memberof OrganisationInfoViewModel
     */
    contact: OrganisationContactPersonViewModel;
    /**
     *
     * @type {Array<ImageViewModel>}
     * @memberof OrganisationInfoViewModel
     */
    images: Array<ImageViewModel>;
    /**
     *
     * @type {Array<HappeningListItemViewModel>}
     * @memberof OrganisationInfoViewModel
     */
    happeningsOfOrganisationTag: Array<HappeningListItemViewModel>;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof OrganisationInfoViewModel
     */
    organisationsOnSharedLocation: Array<TagViewModel>;
    /**
     *
     * @type {boolean}
     * @memberof OrganisationInfoViewModel
     */
    isFavorite: boolean;
}

/**
 * Check if a given object implements the OrganisationInfoViewModel interface.
 */
export function instanceOfOrganisationInfoViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isActive" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "minimumAge" in value;
    isInstance = isInstance && "maximumAge" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "locations" in value;
    isInstance = isInstance && "externalLinks" in value;
    isInstance = isInstance && "contact" in value;
    isInstance = isInstance && "images" in value;
    isInstance = isInstance && "happeningsOfOrganisationTag" in value;
    isInstance = isInstance && "organisationsOnSharedLocation" in value;
    isInstance = isInstance && "isFavorite" in value;

    return isInstance;
}

export function OrganisationInfoViewModelFromJSON(json: any): OrganisationInfoViewModel {
    return OrganisationInfoViewModelFromJSONTyped(json, false);
}

export function OrganisationInfoViewModelFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): OrganisationInfoViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        isActive: !exists(json, "isActive") ? (undefined as any) : json["isActive"],
        title: !exists(json, "title") ? (undefined as any) : json["title"],
        organisationTag: !exists(json, "organisationTag")
            ? (undefined as any)
            : TagViewModelFromJSON(json["organisationTag"]),
        category: !exists(json, "category") ? (undefined as any) : TagViewModelFromJSON(json["category"]),
        sector: !exists(json, "sector") ? (undefined as any) : TagViewModelFromJSON(json["sector"]),
        minimumAge: !exists(json, "minimumAge") ? (undefined as any) : json["minimumAge"],
        maximumAge: !exists(json, "maximumAge") ? (undefined as any) : json["maximumAge"],
        specificTargetGroup: !exists(json, "specificTargetGroup")
            ? (undefined as any)
            : TagViewModelFromJSON(json["specificTargetGroup"]),
        description: !exists(json, "description") ? (undefined as any) : json["description"],
        locations: !exists(json, "locations")
            ? (undefined as any)
            : ((json["locations"] as Array<any>) ?? []).map(LocationViewModelFromJSON),
        externalLinks: !exists(json, "externalLinks")
            ? (undefined as any)
            : ((json["externalLinks"] as Array<any>) ?? []).map(OrganisationExternalLinkViewModelFromJSON),
        contact: !exists(json, "contact")
            ? (undefined as any)
            : OrganisationContactPersonViewModelFromJSON(json["contact"]),
        images: !exists(json, "images")
            ? (undefined as any)
            : ((json["images"] as Array<any>) ?? []).map(ImageViewModelFromJSON),
        happeningsOfOrganisationTag: !exists(json, "happeningsOfOrganisationTag")
            ? (undefined as any)
            : ((json["happeningsOfOrganisationTag"] as Array<any>) ?? []).map(HappeningListItemViewModelFromJSON),
        organisationsOnSharedLocation: !exists(json, "organisationsOnSharedLocation")
            ? (undefined as any)
            : ((json["organisationsOnSharedLocation"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        isFavorite: !exists(json, "isFavorite") ? (undefined as any) : json["isFavorite"],
    };
}

export function OrganisationInfoViewModelToJSON(value?: OrganisationInfoViewModel | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
