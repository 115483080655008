import React from "react";
import ContactPerson from "../ContactPerson/ContactPerson";
import styles from "./ContactPersonFooter.module.scss";
import classNames from "classnames";
import { OrganisationContactPersonViewModel } from "../../openapi/backend";
import Title from "../Title/Title";
import { strings } from "../../Localization/strings";
interface Props {
    className?: string;
    contact: OrganisationContactPersonViewModel;
}

function ContactPersonFooter({ className, contact }: Props) {
    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.grid}>
                <div>
                    <Title heading="h2" className={styles.title} label={strings.contactPerson} />
                    <ContactPerson className={styles.detail} contact={contact} />
                </div>
            </div>
        </div>
    );
}

export default ContactPersonFooter;
