import { t } from "i18next";
import { useMemo } from "react";
import { LocationType, LocationViewModel } from "../openapi/backend";

export function getLocationLabel(type: LocationType, location?: LocationViewModel) {
    if (location) {
        return location.address;
    }

    return t(`enum.locationType.${type}` as any);
}

export function useLocationLabel(type: LocationType, location?: LocationViewModel) {
    return useMemo(() => getLocationLabel(type, location), [type, location]);
}
