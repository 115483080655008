import React from "react";
import styles from "./IconButton.module.scss";
import classNames from "classnames";
import { HtmlButtonProps } from "../../types/ReusableTypes";

type ButtonType = "default" | "transparent";

type Props = HtmlButtonProps & {
    icon: string;
    alt?: string;
    displayType: ButtonType;
};

const IconButton = ({ className, icon, alt, displayType, ...props }: Props) => {
    return (
        <button
            className={classNames(styles.container, styles.hollow, styles.hover, styles[displayType], className)}
            type="button"
            data-testid="IconButton"
            {...props}
        >
            <img src={icon} className={styles.icon} alt={alt} />
        </button>
    );
};

IconButton.defaultProps = {
    className: "",
    displayType: "default",
};

export default IconButton;
