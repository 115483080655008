import React, { useCallback, useMemo, useState } from "react";
import styles from "./DateSessionList.module.scss";
import classNames from "classnames";
import { HappeningTimeAndDateViewModel } from "../../openapi/backend";
import { isInThePast } from "../../utils/DateUtils";
import DateSessionLine from "../DateSessionLine/DateSessionLine";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";

type Props = {
    className: string;
    sessions: Array<HappeningTimeAndDateViewModel>;
};

const DateSessionList = ({ className, sessions }: Props) => {
    const { t } = useTranslation();
    const [isExpanded, setExpanded] = useState(false);

    const list = useMemo(() => {
        const displayItems = isExpanded
            ? sessions
            : sessions.filter((s) => !s.cancelled && !isInThePast(s.sessionEnd)).slice(0, 3);

        return displayItems;
    }, [sessions, isExpanded]);

    const toggle = useCallback(() => {
        setExpanded((value) => !value);
    }, []);

    const showButton = useMemo(() => isExpanded || list.length !== sessions.length, [list, sessions, isExpanded]);

    return (
        <div className={classNames(styles.container, isExpanded && styles.open, className)}>
            {list.map((session) => (
                <DateSessionLine key={session.id} className={styles.session} session={session} />
            ))}
            {showButton && (
                <Button
                    className={styles.button}
                    displayType="inline"
                    label={isExpanded ? t("sessions.showLess") : t("sessions.showMore")}
                    onClick={toggle}
                />
            )}
        </div>
    );
};

DateSessionList.defaultProps = {
    className: "",
};

export default DateSessionList;
