import React from "react";
import styles from "./ProfileIcon.module.scss";
import classNames from "classnames";
import AvatarIcon, { Props as AvatarProps } from "../AvatarIcon/AvatarIcon";
import { UserShortOutput } from "../../openapi/backend";
import { getFullName } from "../../utils/Profile";

type Props = Omit<AvatarProps, "src" | "backgroundColor" | "name"> & {
    profile: UserShortOutput;
};

const ProfileIcon = ({ className, profile, ...props }: Props) => {
    return (
        <AvatarIcon
            className={classNames(styles.container, className)}
            name={getFullName(profile)}
            backgroundColor={profile.avatar?.href ? "white" : undefined}
            src={profile.avatar?.href}
            {...props}
        />
    );
};

ProfileIcon.defaultProps = {
    className: "",
};

export default ProfileIcon;
