import React, { useCallback, useEffect, useState, useRef } from "react";
import styles from "./Slider.module.scss";
import { asNumber } from "../../utils/Formatting";
import { strings } from "../../Localization/strings";
import { FilterConstants } from "../../constants/FilterContstants";

interface SliderProps {
    minRange?: number;
    maxRange: number;
    onChange: (value?: number) => void;
    maxDistanceText?: string;
}

const Slider: React.FC<SliderProps> = ({ minRange = 0, maxRange, onChange, maxDistanceText }) => {
    const minVal = minRange;
    const [maxVal, setMaxVal] = useState<number>(maxRange);
    const range = useRef<HTMLDivElement>(null);
    const [distanceText, setDistanceText] = useState<string | number>(maxDistanceText ?? maxVal);

    const getPercent = useCallback(
        (value: number) => ((value - minRange) / (maxRange - minRange)) * 100,
        [minRange, maxRange],
    );

    const updateRangeStyle = useCallback(() => {
        const minPercent = getPercent(minVal);
        const maxPercent = getPercent(maxVal);

        if (range.current) {
            range.current.style.left = `${minPercent}%`;
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [minVal, maxVal, getPercent]);

    useEffect(() => {
        updateRangeStyle();
    }, [minVal, maxVal, updateRangeStyle]);

    useEffect(() => {
        setMaxVal(maxRange);
        setDistanceText(maxRange > FilterConstants.MAXIMUM_DISTANCE_KILOMETERS ? `${strings.unlimited}` : maxRange);
    }, [maxRange, maxDistanceText]);

    const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Math.max(Number(e.currentTarget.value), minVal + 1);
        setMaxVal(value);
        setDistanceText(value > FilterConstants.MAXIMUM_DISTANCE_KILOMETERS ? `${strings.unlimited}` : value);
        onChange(asNumber(e.currentTarget.value));
    };

    return (
        <div className={styles.container}>
            <input
                type="range"
                min={minRange}
                max={maxRange}
                value={maxVal}
                onChange={handleMaxChange}
                className={`${styles.thumb} ${styles["thumb--right"]}`}
            />

            <div className={styles.slider}>
                <div className={styles.slider__track} />
                <div ref={range} className={styles.slider__range} />
                <div className={styles.slider__leftValue}>{minVal}</div>
                <div className={styles.slider__rightValue}>{distanceText}</div>
            </div>
        </div>
    );
};

export default Slider;
