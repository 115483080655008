import React, { PropsWithChildren } from "react";
import styles from "./FriendlyError.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import { useParseBackendError } from "../../hooks/useBackendError";
import Title from "../Title/Title";
import Text from "../Text/Text";
import { SadFaceIcon } from "../../assets/images/Images";
import { RequestState } from "../../types/RequestState";
import { CustomErrorMessage } from "../../types/CustomErrorMessage";

type Props = {
    className: string;
    state: RequestState;
    retry?: () => any;
    error?: Response;
    custom?: Array<CustomErrorMessage>;
};

const FriendlyError = ({ className, state, retry, error, custom, children }: PropsWithChildren<Props>) => {
    const { t } = useTranslation();
    const { title, message } = useParseBackendError(error, custom);

    if (state !== RequestState.ERROR) {
        return <>{children}</>;
    }

    return (
        <div className={classNames(styles.container, className)}>
            <img className={styles.icon} src={SadFaceIcon} alt=":(" />
            <Title heading="h3" label={title} />
            <Text className={styles.message} type="small" label={message} />
            {retry && <Button className={styles.button} label={t("error.button.retry")} onClick={retry} />}
        </div>
    );
};

FriendlyError.defaultProps = {
    className: "",
};

export default FriendlyError;
