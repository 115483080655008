import React, { useCallback } from "react";
import styles from "./HappeningDetailModal.module.scss";
import classNames from "classnames";
import Modal from "react-modal";
import HappeningDetailContainer from "../HappeningDetail/HappeningDetailContainer";
import { ReusableProps as HappeningDetailProps } from "../HappeningDetail/HappeningDetail";
import { useNavigate } from "react-router-dom";
import IconButton from "../IconButton/IconButton";
import { CloseIcon } from "../../assets/images/Images";
import { useTranslation } from "react-i18next";

export type Props = Omit<Modal.Props, "overlayClassName" | "className"> &
    HappeningDetailProps & {
        className: string;
        happeningId?: string | null;
        fillPage: boolean;
    };

const HappeningDetailModal = ({ className, happeningId, fillPage, onResize, ...props }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const goBack = useCallback(() => navigate(-1), [navigate]);

    return (
        <Modal
            className={classNames(styles.container, fillPage ? styles.fillPage : styles.modal, className)}
            testId="HappeningDetailModal"
            {...props}
            overlayClassName={styles.overlay}
            shouldCloseOnOverlayClick={!fillPage}
            onRequestClose={goBack}
        >
            {!fillPage && (
                <div className={styles.bar}>
                    <IconButton
                        icon={CloseIcon}
                        onClick={goBack}
                        displayType="transparent"
                        alt={t("navigation.back.overview")}
                    />
                </div>
            )}
            {happeningId && (
                <HappeningDetailContainer
                    className={styles.content}
                    id={happeningId}
                    onResize={onResize}
                    modal={!fillPage}
                />
            )}
        </Modal>
    );
};

HappeningDetailModal.defaultProps = {
    className: "",
    fillPage: true,
};

export default HappeningDetailModal;
