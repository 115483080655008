/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    TagViewModel,
    TagViewModelFromJSON,
    TagViewModelFromJSONTyped,
    TagViewModelToJSON,
    TargetGroupViewModel,
    TargetGroupViewModelFromJSON,
    TargetGroupViewModelFromJSONTyped,
    TargetGroupViewModelToJSON,
} from "./";

/**
 *
 * @export
 * @interface TagCollection
 */
export interface TagCollection {
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof TagCollection
     */
    happeningGroups: Array<TagViewModel>;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof TagCollection
     */
    happeningTypes: Array<TagViewModel>;
    /**
     *
     * @type {Array<TargetGroupViewModel>}
     * @memberof TagCollection
     */
    targetGroups: Array<TargetGroupViewModel>;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof TagCollection
     */
    municipalities: Array<TagViewModel>;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof TagCollection
     */
    partners: Array<TagViewModel>;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof TagCollection
     */
    serviceGroups: Array<TagViewModel>;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof TagCollection
     */
    others: Array<TagViewModel>;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof TagCollection
     */
    categories: Array<TagViewModel>;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof TagCollection
     */
    industries: Array<TagViewModel>;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof TagCollection
     */
    readonly districts?: Array<TagViewModel>;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof TagCollection
     */
    internal: Array<TagViewModel>;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof TagCollection
     */
    mediaCategories: Array<TagViewModel>;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof TagCollection
     */
    mediaTags: Array<TagViewModel>;
}

/**
 * Check if a given object implements the TagCollection interface.
 */
export function instanceOfTagCollection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "happeningGroups" in value;
    isInstance = isInstance && "happeningTypes" in value;
    isInstance = isInstance && "targetGroups" in value;
    isInstance = isInstance && "municipalities" in value;
    isInstance = isInstance && "partners" in value;
    isInstance = isInstance && "serviceGroups" in value;
    isInstance = isInstance && "others" in value;
    isInstance = isInstance && "categories" in value;
    isInstance = isInstance && "industries" in value;
    isInstance = isInstance && "internal" in value;
    isInstance = isInstance && "mediaCategories" in value;
    isInstance = isInstance && "mediaTags" in value;

    return isInstance;
}

export function TagCollectionFromJSON(json: any): TagCollection {
    return TagCollectionFromJSONTyped(json, false);
}

export function TagCollectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagCollection {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        happeningGroups: !exists(json, "happeningGroups")
            ? (undefined as any)
            : ((json["happeningGroups"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        happeningTypes: !exists(json, "happeningTypes")
            ? (undefined as any)
            : ((json["happeningTypes"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        targetGroups: !exists(json, "targetGroups")
            ? (undefined as any)
            : ((json["targetGroups"] as Array<any>) ?? []).map(TargetGroupViewModelFromJSON),
        municipalities: !exists(json, "municipalities")
            ? (undefined as any)
            : ((json["municipalities"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        partners: !exists(json, "partners")
            ? (undefined as any)
            : ((json["partners"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        serviceGroups: !exists(json, "serviceGroups")
            ? (undefined as any)
            : ((json["serviceGroups"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        others: !exists(json, "others")
            ? (undefined as any)
            : ((json["others"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        categories: !exists(json, "categories")
            ? (undefined as any)
            : ((json["categories"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        industries: !exists(json, "industries")
            ? (undefined as any)
            : ((json["industries"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        districts: !exists(json, "districts")
            ? (undefined as any)
            : ((json["districts"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        internal: !exists(json, "internal")
            ? (undefined as any)
            : ((json["internal"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        mediaCategories: !exists(json, "mediaCategories")
            ? (undefined as any)
            : ((json["mediaCategories"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        mediaTags: !exists(json, "mediaTags")
            ? (undefined as any)
            : ((json["mediaTags"] as Array<any>) ?? []).map(TagViewModelFromJSON),
    };
}

export function TagCollectionToJSON(value?: TagCollection | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
