import React, { useMemo } from "react";
import { OrganisationInfoAppListViewModel } from "../../openapi/backend";
import { hasValues, isEmpty } from "../../utils/Array";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import NearbyListCard from "../NearbyListCard/NearbyListCard";
import styles from "./NearbyList.module.scss";
import { DETAILS_KEY } from "../../utils/RouterConstants";
import { setToSearch } from "../../utils/RouterUtils";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ReactPaginateProps } from "react-paginate";
import Text from "../Text/Text";
import { t } from "i18next";
import Loading from "../Loading/Loading";
import GoToFiltersButton from "../GoToFiltersButton/GoToFiltersButton";
import Paginate from "../Paginate/Paginate";
import { useShowFilters } from "../../hooks/useRouterHooks";

interface Props {
    list?: OrganisationInfoAppListViewModel[];
    loading: boolean;
    getPage?: (event: { selected: number }) => void;
    pageCount: number;
    page: number;
    focusFilters?: () => void;
    showOnMap?: boolean;
    className?: string;
}
function NearbyList({ list, loading, getPage, pageCount, page, focusFilters, showOnMap = false, className }: Props) {
    const location = useLocation();
    const showFilters = useShowFilters();
    const { width } = useWindowDimensions();
    const paginateDisplayOptions = useMemo<Partial<ReactPaginateProps>>(() => {
        switch (true) {
            case width <= 500:
                return { pageRangeDisplayed: 3, marginPagesDisplayed: 1 };
            case width <= 800:
                return { pageRangeDisplayed: 5, marginPagesDisplayed: 2 };
            case width <= 1200:
                return { pageRangeDisplayed: 8, marginPagesDisplayed: 2 };
            default:
                return { pageRangeDisplayed: 10, marginPagesDisplayed: 3 };
        }
    }, [width]);
    return (
        <div className={classNames(styles.container, showOnMap && styles.map, className)}>
            {hasValues(list) && (
                <div className={classNames(showOnMap ? styles.mapListContainer : styles.listContainer)}>
                    {list.map((organisation) => {
                        return (
                            <Link
                                key={`Card-${organisation.id}`}
                                className={styles.item}
                                to={{
                                    pathname: location.pathname,
                                    search: setToSearch(
                                        [{ key: DETAILS_KEY, value: organisation.id }],
                                        location.search,
                                    ),
                                }}
                                data-testid="OrganisationListLink"
                            >
                                <NearbyListCard showOnMap={showOnMap} organisation={organisation} />
                            </Link>
                        );
                    })}
                </div>
            )}
            {isEmpty(list) && <Text className={styles.noResults} label={t("list.empty")} type="footer" />}
            {loading && <Loading className={styles.loading} />}
            {showFilters && !showOnMap && (
                <GoToFiltersButton focusFilters={focusFilters} className={styles.goToFiltersButton} />
            )}
            {!showOnMap && (
                <Paginate
                    className={styles.paginate}
                    pageCount={pageCount}
                    onPageChange={getPage}
                    forcePage={page}
                    {...paginateDisplayOptions}
                />
            )}
        </div>
    );
}
NearbyList.defaultProps = {
    className: "",
    pageCount: 0,
    page: 0,
};

export default NearbyList;
