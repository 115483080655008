/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PaginationMeta
 */
export interface PaginationMeta {
    /**
     *
     * @type {number}
     * @memberof PaginationMeta
     */
    totalItems: number;
    /**
     *
     * @type {number}
     * @memberof PaginationMeta
     */
    page?: number;
    /**
     *
     * @type {number}
     * @memberof PaginationMeta
     */
    itemsPerPage?: number;
    /**
     *
     * @type {number}
     * @memberof PaginationMeta
     */
    readonly totalPages?: number;
}

/**
 * Check if a given object implements the PaginationMeta interface.
 */
export function instanceOfPaginationMeta(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalItems" in value;

    return isInstance;
}

export function PaginationMetaFromJSON(json: any): PaginationMeta {
    return PaginationMetaFromJSONTyped(json, false);
}

export function PaginationMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationMeta {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        totalItems: !exists(json, "totalItems") ? (undefined as any) : json["totalItems"],
        page: !exists(json, "page") ? (undefined as any) : json["page"],
        itemsPerPage: !exists(json, "itemsPerPage") ? (undefined as any) : json["itemsPerPage"],
        totalPages: !exists(json, "totalPages") ? (undefined as any) : json["totalPages"],
    };
}

export function PaginationMetaToJSON(value?: PaginationMeta | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
