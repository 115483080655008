import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HappeningViewModel, UserSubset } from "../openapi/backend";

export function useTargetGroupLabel({ targetGroup, targetOrganisations }: HappeningViewModel) {
    const { t } = useTranslation();

    return useMemo(() => {
        switch (targetGroup) {
            case UserSubset.Participants:
                return undefined; // No label for participants, because it's the "default".
            case UserSubset.AllOrganisations:
                return t("targetGroup.all");
            case UserSubset.InternalOrganisations:
                return t("targetGroup.internal");
            case UserSubset.PartnerOrganisations:
                return t("targetGroup.partners");
            case UserSubset.SelectedOrganisations:
                return targetOrganisations.map((x) => x.name).join(", ");
            case UserSubset.NotVisibleForParticipation:
                return undefined; // No label as this is a hidden happening.
        }
    }, [t, targetGroup, targetOrganisations]);
}
