import React, { useCallback, ChangeEvent, useState, useEffect } from "react";
import styles from "./PostalCode.module.scss";
import InputField from "../Input/InputField";

type Props = {
    defaultValue?: string;
    onChange: (value?: string) => void;
    onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

const PostalCode = ({ defaultValue, onChange }: Props) => {
    const [postalCode, setPostalCode] = useState<string | undefined>(defaultValue);

    const onChangeInput = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            const newInput = e.currentTarget.value;
            setPostalCode(newInput);
            onChange(newInput);
        },
        [onChange],
    );

    useEffect(() => {
        setPostalCode(defaultValue);
    }, [defaultValue]);

    return (
        <InputField
            className={styles.input}
            value={postalCode}
            type="text"
            onChange={onChangeInput}
            invalid={postalCode ? !/^\d{4}[A-Za-z]{2}$/.test(postalCode.replace(/\s+/g, "")) : false}
        ></InputField>
    );
};

PostalCode.defaultProps = {
    className: "",
};

export default PostalCode;
