import React from "react";
import { LazyLoadImage, LazyLoadImageProps } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { lazyBeforeLoad } from "../../utils/Workarounds";

type Props = Omit<LazyLoadImageProps, "beforeLoad">;

const LazyImage = (props: Props) => {
    return <LazyLoadImage effect="opacity" threshold={100} beforeLoad={lazyBeforeLoad} {...props} />;
};

export default LazyImage;
