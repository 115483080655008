import React, { PropsWithChildren } from "react";
import { Configuration, RegistrationsApi } from "../openapi/backend";
import { ApiContextProvider, CreateApi } from "./BackendApiContext";

const createApi: CreateApi<RegistrationsApi> = (params) => new RegistrationsApi(new Configuration(params));

export const RegistrationsApiContext = React.createContext(createApi({}));

export const RegistrationsApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={RegistrationsApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
