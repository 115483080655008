/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    ImageViewModel,
    ImageViewModelFromJSON,
    ImageViewModelFromJSONTyped,
    ImageViewModelToJSON,
    Responsibilities,
    ResponsibilitiesFromJSON,
    ResponsibilitiesFromJSONTyped,
    ResponsibilitiesToJSON,
    TagType,
    TagTypeFromJSON,
    TagTypeFromJSONTyped,
    TagTypeToJSON,
} from "./";

/**
 *
 * @export
 * @interface TagViewModel
 */
export interface TagViewModel {
    /**
     *
     * @type {string}
     * @memberof TagViewModel
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof TagViewModel
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof TagViewModel
     */
    detailedName: string;
    /**
     *
     * @type {TagType}
     * @memberof TagViewModel
     */
    type: TagType;
    /**
     *
     * @type {boolean}
     * @memberof TagViewModel
     */
    isActive: boolean;
    /**
     *
     * @type {string}
     * @memberof TagViewModel
     */
    description?: string;
    /**
     *
     * @type {string}
     * @memberof TagViewModel
     */
    color?: string;
    /**
     *
     * @type {ImageViewModel}
     * @memberof TagViewModel
     */
    logo?: ImageViewModel;
    /**
     *
     * @type {boolean}
     * @memberof TagViewModel
     */
    hasApiKey: boolean;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof TagViewModel
     */
    childTags?: Array<TagViewModel>;
    /**
     *
     * @type {Array<string>}
     * @memberof TagViewModel
     */
    parentTags?: Array<string>;
    /**
     *
     * @type {Array<Responsibilities>}
     * @memberof TagViewModel
     */
    responsibilities?: Array<Responsibilities>;
    /**
     *
     * @type {boolean}
     * @memberof TagViewModel
     */
    isShownInCommunication?: boolean;
}

/**
 * Check if a given object implements the TagViewModel interface.
 */
export function instanceOfTagViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "detailedName" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "isActive" in value;
    isInstance = isInstance && "hasApiKey" in value;

    return isInstance;
}

export function TagViewModelFromJSON(json: any): TagViewModel {
    return TagViewModelFromJSONTyped(json, false);
}

export function TagViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        name: !exists(json, "name") ? (undefined as any) : json["name"],
        detailedName: !exists(json, "detailedName") ? (undefined as any) : json["detailedName"],
        type: !exists(json, "type") ? (undefined as any) : TagTypeFromJSON(json["type"]),
        isActive: !exists(json, "isActive") ? (undefined as any) : json["isActive"],
        description: !exists(json, "description") ? (undefined as any) : json["description"],
        color: !exists(json, "color") ? (undefined as any) : json["color"],
        logo: !exists(json, "logo") ? (undefined as any) : ImageViewModelFromJSON(json["logo"]),
        hasApiKey: !exists(json, "hasApiKey") ? (undefined as any) : json["hasApiKey"],
        childTags: !exists(json, "childTags")
            ? (undefined as any)
            : ((json["childTags"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        parentTags: !exists(json, "parentTags") ? (undefined as any) : json["parentTags"],
        responsibilities: !exists(json, "responsibilities")
            ? (undefined as any)
            : ((json["responsibilities"] as Array<any>) ?? []).map(ResponsibilitiesFromJSON),
        isShownInCommunication: !exists(json, "isShownInCommunication")
            ? (undefined as any)
            : json["isShownInCommunication"],
    };
}

export function TagViewModelToJSON(value?: TagViewModel | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
