import React, { useMemo } from "react";
import styles from "./HappeningListCard.module.scss";
import classNames from "classnames";
import { HappeningListItemViewModel, HappeningType } from "../../openapi/backend";
import { useTranslation } from "react-i18next";
import AttentionCardLabel from "../AttentionCardLabel/AttentionCardLabel";
import Title from "../Title/Title";
import CardLine from "../CardLine/CardLine";
import { formatDateMed, formatSessionTime, isNowOrInTheFuture } from "../../utils/DateUtils";
import { getPriceLabel, isGroupRegistration } from "../../utils/HappeningUtils";
import LazyImage from "../LazyImage/LazyImage";
import { useAgeLabel } from "../../hooks/useAgeLabel";
import OrganisationIcon from "../OrganisationIcon/OrganisationIcon";
import { useLocationLabel } from "../../hooks/useLocationLabel";
import { useIsPrivateHappening } from "../../hooks/useIsHappeningType";
import { AccessibilityFilledIcon, ClusterBar, ClusterBarExtra } from "../../assets/images/Images";
import { useClusterId, useHappeningId } from "../../hooks/useRouterHooks";
import { ExternalIcon } from "../../assets/images/Images";

type Props = {
    className: string;
    happening: HappeningListItemViewModel;
    showOnMap?: boolean;
};

const HappeningListCard = ({ className, happening, showOnMap = false }: Props) => {
    const { t } = useTranslation();
    const {
        cover,
        title,
        freeSpots,
        dateTimes,
        minAge,
        maxAge,
        price,
        location,
        happeningGroup,
        maxNumberOfRegistrations,
        isInternal,
        type,
        locationType,
        durationInMinutes,
        metaData: { isCluster, hasMultipleDates, hasMultipleLocations, hasMultipleTimes },
        minPrice,
        maxPrice,
        happeningRegistrationType,
        hasSpotInTeamForIndividuals,
        isAccessible,
        registrationEndDate,
    } = happening;

    const isClusterMultipleDates = useMemo(() => isCluster && hasMultipleDates, [isCluster, hasMultipleDates]);
    const isClusterMultipleLocations = useMemo(
        () => isCluster && hasMultipleLocations,
        [isCluster, hasMultipleLocations],
    );
    const isClusterMultipleTimes = useMemo(() => isCluster && hasMultipleTimes, [isCluster, hasMultipleTimes]);

    const upcomingSession = useMemo(
        () =>
            dateTimes.find((date) => isNowOrInTheFuture(date.sessionEnd) && !date.cancelled) ||
            dateTimes[dateTimes.length - 1],
        [dateTimes],
    );

    const isSeries = useMemo(() => dateTimes.length > 1, [dateTimes.length]);

    const ageLabel = useAgeLabel(minAge, maxAge);
    const locationLabel = useLocationLabel(locationType, location);
    const targetGroupLabel = useMemo(() => {
        return isInternal ? undefined : ageLabel;
    }, [isInternal, ageLabel]);

    const isPrivateHappening = useIsPrivateHappening(type);
    const theme = useMemo(() => (isInternal ? "dark" : "light"), [isInternal]);
    const clusterId = useClusterId();
    const hasHappeningId = useHappeningId();
    const isClusterCard = useMemo(
        () => isCluster && !clusterId && !hasHappeningId,
        [isCluster, clusterId, hasHappeningId],
    );
    const isGroupHappening = useMemo(() => isGroupRegistration(happeningRegistrationType), [happeningRegistrationType]);
    const isExternalHappening = useMemo(() => type === HappeningType.ExternalHappening, [type]);

    return (
        <div className={classNames(styles.container, className, showOnMap && styles.mapContainer)}>
            <div className={styles.coverContainer}>
                {isClusterCard && (
                    <div className={styles.barContainer}>
                        <img className={styles.barExtra} src={ClusterBarExtra} />
                        <img className={styles.bar} src={ClusterBar} />
                    </div>
                )}
                <LazyImage
                    src={cover?.href}
                    width="100%"
                    height="100%"
                    threshold={268}
                    className={styles.image}
                    alt={t("image.cover.alt", { name: title })}
                />
                <AttentionCardLabel
                    className={styles.attention}
                    spotsLeft={freeSpots}
                    maxNumberOfRegistrations={maxNumberOfRegistrations}
                    isGroupHappening={isGroupHappening}
                    hasSpotInTeamForIndividuals={hasSpotInTeamForIndividuals}
                    registrationEndDate={registrationEndDate}
                />
                {isAccessible && (
                    <img
                        src={AccessibilityFilledIcon}
                        className={classNames(styles.accessibility, isClusterCard && styles.clustered)}
                        alt={t("accessibility.alt")}
                    />
                )}
            </div>
            <div
                className={classNames(
                    styles.info,
                    happening.isInternal ? styles.internal : styles.public,
                    isClusterCard && styles.cluster,
                )}
            >
                <Title className={styles.name} label={title} heading="h4" />
                <div className={styles.row}>
                    <CardLine
                        className={styles.left}
                        type={isSeries ? "dateSeries" : "date"}
                        theme={theme}
                        label={
                            isPrivateHappening
                                ? t("sessions.toBeDetermined")
                                : isClusterMultipleDates
                                ? t("card.multipleDates")
                                : formatDateMed(upcomingSession.sessionStart)
                        }
                        multiple={isClusterMultipleDates}
                    />
                    <CardLine
                        className={styles.right}
                        type={isGroupHappening ? "targetGroupGroup" : "targetGroup"}
                        theme={theme}
                        label={targetGroupLabel}
                        icon={isInternal ? "lock" : undefined}
                        altText={isInternal ? t("card.internal") : undefined}
                    />
                </div>
                <div className={styles.row}>
                    <CardLine
                        className={styles.left}
                        type="time"
                        theme={theme}
                        label={
                            isPrivateHappening
                                ? t("duration.minutes", { value: durationInMinutes })
                                : isClusterMultipleTimes
                                ? t("card.multipleTimes")
                                : formatSessionTime(upcomingSession.sessionStart, upcomingSession.sessionEnd)
                        }
                        multiple={isClusterMultipleTimes}
                    />
                    <CardLine
                        className={styles.right}
                        type="price"
                        theme={theme}
                        label={isCluster ? getPriceLabel(price, minPrice, maxPrice) : getPriceLabel(price)}
                    />
                </div>
                <div className={styles.row}>
                    <CardLine
                        className={styles.left}
                        type="location"
                        theme={theme}
                        label={isClusterMultipleLocations ? t("card.multipleLocations") : locationLabel}
                        multiple={isClusterMultipleLocations}
                    />
                    {happeningGroup && (
                        <OrganisationIcon className={styles.right} organisation={happeningGroup} size={32} />
                    )}
                </div>
            </div>
            {isExternalHappening && <img className={styles.externalIcon} src={ExternalIcon} alt={t("card.external")} />}
        </div>
    );
};

HappeningListCard.defaultProps = {
    className: "",
};

export default HappeningListCard;
