import React from "react";
import styles from "./NotFoundPage.module.scss";
import classNames from "classnames";
import Title from "../../components/Title/Title";
import { useTranslation } from "react-i18next";
import LinkButton from "../../components/LinkButton/LinkButton";

type Props = {
    className: string;
};

const NotFoundPage = ({ className }: Props) => {
    const { t } = useTranslation();

    return (
        <div className={classNames(styles.container, className)}>
            <Title heading="h1" label={t("error.title.pageNotFound")} />
            <LinkButton className={styles.button} label={t("error.button.toHome")} to="/" replace={true} />
        </div>
    );
};

NotFoundPage.defaultProps = {
    className: "",
};

export default NotFoundPage;
