import React, { useMemo } from "react";
import styles from "./HappeningOrganisers.module.scss";
import classNames from "classnames";
import { HappeningViewModel, TagType } from "../../openapi/backend";
import Text from "../Text/Text";
import { useTranslation } from "react-i18next";
import PartnerIcon from "../PartnerIcon/PartnerIcon";

type Props = {
    className: string;
    happening: HappeningViewModel;
};

const HappeningContact = ({ className, happening }: Props) => {
    const { t } = useTranslation();
    const { happeningGroup, tags } = happening;
    const partners = useMemo(
        () =>
            tags.filter(
                (x) =>
                    [TagType.Partner, TagType.HappeningGroup].includes(x.type) &&
                    x.isActive &&
                    x.isShownInCommunication,
            ),
        [tags],
    );
    const withPartners = partners.length > 0;
    const descriptionLabel = withPartners
        ? t("organisers.withoutPartners", { name: happeningGroup?.name })
        : t("organisers.withPartners", { name: happeningGroup?.name });

    return (
        <div className={classNames(styles.container, className)}>
            <Text className={styles.description} label={descriptionLabel} />
            {withPartners && (
                <div className={styles.partnersList}>
                    {partners.map((organiser) => {
                        return <PartnerIcon partner={organiser} key={organiser.id} size={40} />;
                    })}
                </div>
            )}
        </div>
    );
};

HappeningContact.defaultProps = {
    className: "",
};

export default HappeningContact;
