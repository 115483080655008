/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import { QuestionType, QuestionTypeFromJSON, QuestionTypeFromJSONTyped, QuestionTypeToJSON } from "./";

/**
 *
 * @export
 * @interface QuestionOutput
 */
export interface QuestionOutput {
    /**
     *
     * @type {number}
     * @memberof QuestionOutput
     */
    id: number;
    /**
     *
     * @type {QuestionType}
     * @memberof QuestionOutput
     */
    type: QuestionType;
    /**
     *
     * @type {string}
     * @memberof QuestionOutput
     */
    title: string;
    /**
     *
     * @type {boolean}
     * @memberof QuestionOutput
     */
    isRequired: boolean;
    /**
     *
     * @type {boolean}
     * @memberof QuestionOutput
     */
    multipleOptionsAllowed?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof QuestionOutput
     */
    options?: Array<string>;
}

/**
 * Check if a given object implements the QuestionOutput interface.
 */
export function instanceOfQuestionOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "isRequired" in value;

    return isInstance;
}

export function QuestionOutputFromJSON(json: any): QuestionOutput {
    return QuestionOutputFromJSONTyped(json, false);
}

export function QuestionOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionOutput {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        type: !exists(json, "type") ? (undefined as any) : QuestionTypeFromJSON(json["type"]),
        title: !exists(json, "title") ? (undefined as any) : json["title"],
        isRequired: !exists(json, "isRequired") ? (undefined as any) : json["isRequired"],
        multipleOptionsAllowed: !exists(json, "multipleOptionsAllowed")
            ? (undefined as any)
            : json["multipleOptionsAllowed"],
        options: !exists(json, "options") ? (undefined as any) : json["options"],
    };
}

export function QuestionOutputToJSON(value?: QuestionOutput | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
