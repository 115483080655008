/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    ClusteredHappenings,
    ClusteredHappeningsFromJSON,
    ClusteredHappeningsFromJSONTyped,
    ClusteredHappeningsToJSON,
    ImageViewModel,
    ImageViewModelFromJSON,
    ImageViewModelFromJSONTyped,
    ImageViewModelToJSON,
    OrganizerViewModel,
    OrganizerViewModelFromJSON,
    OrganizerViewModelFromJSONTyped,
    OrganizerViewModelToJSON,
} from "./";

/**
 *
 * @export
 * @interface ClusterViewModel
 */
export interface ClusterViewModel {
    /**
     *
     * @type {string}
     * @memberof ClusterViewModel
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof ClusterViewModel
     */
    name: string;
    /**
     *
     * @type {ImageViewModel}
     * @memberof ClusterViewModel
     */
    cover?: ImageViewModel;
    /**
     *
     * @type {OrganizerViewModel}
     * @memberof ClusterViewModel
     */
    organizer?: OrganizerViewModel;
    /**
     *
     * @type {Array<ClusteredHappenings>}
     * @memberof ClusterViewModel
     */
    happenings: Array<ClusteredHappenings>;
}

/**
 * Check if a given object implements the ClusterViewModel interface.
 */
export function instanceOfClusterViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "happenings" in value;

    return isInstance;
}

export function ClusterViewModelFromJSON(json: any): ClusterViewModel {
    return ClusterViewModelFromJSONTyped(json, false);
}

export function ClusterViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        name: !exists(json, "name") ? (undefined as any) : json["name"],
        cover: !exists(json, "cover") ? (undefined as any) : ImageViewModelFromJSON(json["cover"]),
        organizer: !exists(json, "organizer") ? (undefined as any) : OrganizerViewModelFromJSON(json["organizer"]),
        happenings: !exists(json, "happenings")
            ? (undefined as any)
            : ((json["happenings"] as Array<any>) ?? []).map(ClusteredHappeningsFromJSON),
    };
}

export function ClusterViewModelToJSON(value?: ClusterViewModel | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
