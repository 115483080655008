/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum MonthlyRecurrenceType {
    Date = "Date",
    WeekDayOfMonth = "WeekDayOfMonth",
}

export function MonthlyRecurrenceTypeFromJSON(json: any): MonthlyRecurrenceType {
    return MonthlyRecurrenceTypeFromJSONTyped(json, false);
}

export function MonthlyRecurrenceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonthlyRecurrenceType {
    return json as MonthlyRecurrenceType;
}

export function MonthlyRecurrenceTypeToJSON(value?: MonthlyRecurrenceType | null): any {
    return value as any;
}
