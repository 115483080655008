import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useTagCollection } from "../hooks/TagHooks";
import { TagCollection } from "../openapi/backend";
import { RequestState } from "../types/RequestState";

const emptyCollection: TagCollection = {
    happeningGroups: [],
    happeningTypes: [],
    municipalities: [],
    others: [],
    partners: [],
    serviceGroups: [],
    targetGroups: [],
    categories: [],
    industries: [],
    internal: [],
    mediaCategories: [],
    mediaTags: [],
};

/**
 * Context that provides the Tag data. undefined data means it's still being loaded so act accordingly.
 */
export const TagsContext = React.createContext<TagContext>({
    ...emptyCollection,
    refresh() {},
});

export interface TagContext extends TagCollection {
    refresh: () => void;
}

export const TagsContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const [{ value, state }, refreshTags] = useTagCollection();
    const [tagCollection, setTagCollection] = useState<TagCollection>(emptyCollection);

    // Invoke refreshTags on component mount
    useEffect(() => {
        if (state === RequestState.IDLE) {
            refreshTags();
        }
    }, [refreshTags, state]);

    // Update tagCollection when value changes
    useEffect(() => {
        if (value) {
            setTagCollection((current) => ({
                ...current,
                ...value,
            }));
        }
    }, [value]);

    const context = useMemo<TagContext>(
        () => ({
            ...tagCollection,
            refresh: refreshTags,
        }),
        [tagCollection, refreshTags],
    );

    return <TagsContext.Provider value={context}>{children}</TagsContext.Provider>;
};
