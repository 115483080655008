import React from "react";
import AvatarIcon from "../AvatarIcon/AvatarIcon";
import { OrganisationContactPersonViewModel } from "../../openapi/backend";
import styles from "./ContactPerson.module.scss";
import Title from "../Title/Title";
import Text from "../Text/Text";
import classNames from "classnames";
interface Props {
    className: string;
    contact: OrganisationContactPersonViewModel;
}

function ContactPerson({ contact, className }: Props) {
    return (
        <div className={classNames(styles.container, className)}>
            <AvatarIcon size={60} name={contact.name} />
            <div className={styles.detail}>
                <Title heading="h2" label={contact.name} />
                <div>
                    <Text type="inline-link" label={contact.email} />
                    <Text type="info-details" label={contact.phoneNumber} />
                </div>
            </div>
        </div>
    );
}

export default ContactPerson;
