import React from "react";
import styles from "./OrganisationIcon.module.scss";
import classNames from "classnames";
import AvatarIcon, { Props as AvatarProps } from "../AvatarIcon/AvatarIcon";
import { OrganizerViewModel } from "../../openapi/backend";

type Props = Omit<AvatarProps, "src" | "backgroundColor" | "name"> & {
    organisation: OrganizerViewModel;
};

const OrganisationIcon = ({ className, organisation, ...props }: Props) => {
    return (
        <AvatarIcon
            className={classNames(styles.container, className)}
            name={organisation.name}
            backgroundColor={organisation.color}
            src={organisation.logo?.href}
            {...props}
        />
    );
};

OrganisationIcon.defaultProps = {
    className: "",
};

export default OrganisationIcon;
