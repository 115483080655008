import React, { useMemo } from "react";
import styles from "./AvatarIcon.module.scss";
import classNames from "classnames";
import Text from "../Text/Text";
import LazyImage from "../LazyImage/LazyImage";

type Size = 32 | 36 | 40 | 60;

export type Props = {
    className: string;
    size: Size;
    name: string;
    src?: string;
    backgroundColor?: string;
};

const AvatarIcon = ({ className, size, name, src, backgroundColor }: Props) => {
    const initials = useMemo(
        () =>
            name
                .split(" ")
                .map((v, i) => (i < 2 ? v.charAt(0).toUpperCase() : undefined))
                .join(""),
        [name],
    );

    const containerStyle = useMemo(
        () => ({
            height: size,
            width: size,
            borderRadius: size,
            ...(backgroundColor ? { backgroundColor } : {}),
        }),
        [backgroundColor, size],
    );
    const textStyle = useMemo(() => ({ fontSize: Math.ceil((size / 40) * 14) }), [size]);

    return (
        <div className={classNames(styles.container, styles.background, className)} style={containerStyle}>
            {src ? (
                <LazyImage src={src} height={size} width={size} className={styles.image} alt="Avatar" threshold={100} />
            ) : (
                <Text className={styles.initials} style={textStyle} label={initials} type="bold" />
            )}
        </div>
    );
};

AvatarIcon.defaultProps = {
    className: "",
};

export default AvatarIcon;
