export const asNumber = (v: number | string | undefined | string[]): number | undefined => {
    let value: number | undefined = undefined;
    if (typeof v === "string" && v !== "") {
        var localized = v.replace(",", ".");
        value = Number.isNaN(Number(localized)) ? undefined : Number(v);
    } else if (typeof v === "number" && !Number.isNaN(v)) {
        value = v;
    }

    return value;
};

export const asRoundedNumber = (v: number | string | undefined | string[]): number | undefined => {
    const result = asNumber(v);
    return result ? Math.round(result) : result;
};
