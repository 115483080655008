import React, { PropsWithChildren } from "react";
import { Configuration, HappeningsApi } from "../openapi/backend";
import { ApiContextProvider, CreateApi } from "./BackendApiContext";

const createApi: CreateApi<HappeningsApi> = (params) => new HappeningsApi(new Configuration(params));

export const HappeningsApiContext = React.createContext(createApi({}));

export const HappeningsApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={HappeningsApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
