/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MapItemProperties
 */
export interface MapItemProperties {
    /**
     *
     * @type {string}
     * @memberof MapItemProperties
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof MapItemProperties
     */
    color?: string;
    /**
     *
     * @type {string}
     * @memberof MapItemProperties
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof MapItemProperties
     */
    imageHref?: string;
    /**
     *
     * @type {string}
     * @memberof MapItemProperties
     */
    imageAltText?: string;
    /**
     *
     * @type {string}
     * @memberof MapItemProperties
     */
    imageFileName?: string;
}

/**
 * Check if a given object implements the MapItemProperties interface.
 */
export function instanceOfMapItemProperties(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function MapItemPropertiesFromJSON(json: any): MapItemProperties {
    return MapItemPropertiesFromJSONTyped(json, false);
}

export function MapItemPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MapItemProperties {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        color: !exists(json, "color") ? (undefined as any) : json["color"],
        name: !exists(json, "name") ? (undefined as any) : json["name"],
        imageHref: !exists(json, "imageHref") ? (undefined as any) : json["imageHref"],
        imageAltText: !exists(json, "imageAltText") ? (undefined as any) : json["imageAltText"],
        imageFileName: !exists(json, "imageFileName") ? (undefined as any) : json["imageFileName"],
    };
}

export function MapItemPropertiesToJSON(value?: MapItemProperties | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
