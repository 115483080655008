import React, { PropsWithChildren, useCallback, useEffect, useState } from "react";
import styles from "./Carousel.module.scss";
import classNames from "classnames";
import { DotButton, PrevNextButton, Slide } from "./CarouselElements";
import useEmblaCarousel, { EmblaOptionsType } from "embla-carousel-react";
import { MediaPreviewType } from "../../types/MediaPreview";

type Props = {
    className: string;
    viewportClassName?: string;
    slides: MediaPreviewType[];
    options?: EmblaOptionsType;
};

const Carousel = ({ className, viewportClassName, slides, options, children }: PropsWithChildren<Props>) => {
    const [emblaRef, emblaApi] = useEmblaCarousel(options);
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
    const [slidesInView, setSlidesInView] = useState<number[]>([]);

    const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
    const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
    const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

    const onSelect = useCallback(() => {
        if (!emblaApi) {
            return;
        }

        setSelectedIndex(emblaApi.selectedScrollSnap());
        setPrevBtnEnabled(emblaApi.canScrollPrev());
        setNextBtnEnabled(emblaApi.canScrollNext());
    }, [emblaApi]);

    const findSlidesInView = useCallback(() => {
        if (!emblaApi) {
            return;
        }

        setSlidesInView((slidesInView) => {
            if (slidesInView.length === emblaApi.slideNodes().length) {
                emblaApi.off("select", findSlidesInView);
            }
            const inView = emblaApi.slidesInView(true).filter((index) => slidesInView.indexOf(index) === -1);
            return inView.length === 0 ? slidesInView : slidesInView.concat(inView);
        });
    }, [emblaApi]);

    useEffect(() => {
        if (!emblaApi) {
            return;
        }

        emblaApi.on("select", onSelect);
        emblaApi.on("reInit", onSelect);
        emblaApi.on("select", findSlidesInView);
        emblaApi.on("reInit", findSlidesInView);
        onSelect();
        findSlidesInView();
        setScrollSnaps(emblaApi.scrollSnapList());

        return () => {
            emblaApi.off("select", onSelect);
            emblaApi.off("reInit", onSelect);
            emblaApi.off("select", findSlidesInView);
            emblaApi.off("reInit", findSlidesInView);
        };
    }, [emblaApi, onSelect, findSlidesInView]);

    return (
        <div className={classNames(styles.container, className)}>
            <PrevNextButton enabled={prevBtnEnabled} onClick={scrollPrev} direction="previous" />
            <div className={classNames(styles.viewport, viewportClassName)} ref={emblaRef}>
                <div className={styles.slidesContainer}>
                    {slides.map((media, index) => (
                        <Slide key={index} media={media} inView={slidesInView.indexOf(index) > -1} />
                    ))}
                </div>
                {children}
            </div>
            <PrevNextButton
                className={styles.nextButton}
                enabled={nextBtnEnabled}
                onClick={scrollNext}
                direction="next"
            />
            <div className={styles.dotsContainer}>
                {scrollSnaps.map((_, index) => (
                    <DotButton key={index} selected={index === selectedIndex} onClick={() => scrollTo(index)} />
                ))}
            </div>
        </div>
    );
};

Carousel.defaultProps = {
    className: "",
};

export default Carousel;
