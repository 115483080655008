/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum Responsibilities {
    Development = "Development",
    Financing = "Financing",
    Support = "Support",
    Execution = "Execution",
    Warrant = "Warrant",
}

export function ResponsibilitiesFromJSON(json: any): Responsibilities {
    return ResponsibilitiesFromJSONTyped(json, false);
}

export function ResponsibilitiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Responsibilities {
    return json as Responsibilities;
}

export function ResponsibilitiesToJSON(value?: Responsibilities | null): any {
    return value as any;
}
