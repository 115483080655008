/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface OrganisationExternalLinkViewModel
 */
export interface OrganisationExternalLinkViewModel {
    /**
     *
     * @type {string}
     * @memberof OrganisationExternalLinkViewModel
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof OrganisationExternalLinkViewModel
     */
    url: string;
}

/**
 * Check if a given object implements the OrganisationExternalLinkViewModel interface.
 */
export function instanceOfOrganisationExternalLinkViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "url" in value;

    return isInstance;
}

export function OrganisationExternalLinkViewModelFromJSON(json: any): OrganisationExternalLinkViewModel {
    return OrganisationExternalLinkViewModelFromJSONTyped(json, false);
}

export function OrganisationExternalLinkViewModelFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): OrganisationExternalLinkViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        name: !exists(json, "name") ? (undefined as any) : json["name"],
        url: !exists(json, "url") ? (undefined as any) : json["url"],
    };
}

export function OrganisationExternalLinkViewModelToJSON(value?: OrganisationExternalLinkViewModel | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
