import { useTranslation } from "react-i18next";
import { parseError } from "../utils/BackendError";
import { useEffect, useState } from "react";
import { CustomErrorMessage } from "../types/CustomErrorMessage";

export function useParseBackendError(error?: Response, custom?: Array<CustomErrorMessage>) {
    const { t } = useTranslation();
    const [parsedError, setParsedError] = useState({
        title: t("error.title.default"),
        message: t("error.message.default"),
    });

    useEffect(() => {
        const asyncFunction = async () => {
            let errorTitle = t("error.title.default");
            let errorMessage = error?.statusText || t("error.message.default");

            const customMatch = custom?.find((value) => value.status === error?.status);

            if (!error) {
                setParsedError((old) => {
                    if (old.title === t("error.title.default") && old.message === t("error.message.default")) {
                        return old;
                    }

                    return {
                        title: t("error.title.default"),
                        message: t("error.message.default"),
                    };
                });
                return;
            } else if (customMatch) {
                setParsedError({
                    title: customMatch.title || errorTitle,
                    message: customMatch.message,
                });
                return;
            } else if (error.status === 400) {
                errorMessage = t("error.message.default400");

                try {
                    const messages = await parseError(error);
                    const errorContent = await error
                        // .json consumes the body, so use clone to infinitely print
                        .clone()
                        .json();

                    const groupedMessage =
                        messages.length === 1 ? messages[0] : messages.map((m) => `\n - ${m}`).join("");
                    errorMessage = `${groupedMessage}` || t("error.message.default400");
                    console.warn("Oopsie", JSON.stringify(errorContent, null, 4));
                } catch (e) {
                    // Depending on the error the error could be a string instead of a json object.
                    // Should use .text instead of .json in those cases.
                    // Depending if we want to support the text cases, then you can have a peak at how SixBlocks handles it
                    // https://git.milvum.com/sixblocks/sixblocks-monorepo/-/blob/develop/WebClientCanvas/src/hooks/useApiCall.ts
                    // https://git.milvum.com/sixblocks/sixblocks-monorepo/-/blob/develop/WebClientCanvas/src/utils/NotificationUtils.tsx
                    console.error("[useBackendError] Something went wrong from while reading error response", e);
                }
            } else if ([502, 503, 504].includes(error.status)) {
                setParsedError({
                    title: t("error.title.busy"),
                    message: t("error.message.busy"),
                });
                return;
            }

            errorMessage = `${t("error.prefix.code")}: ${error?.status} \n${t(
                "error.prefix.message",
            )}: ${errorMessage}`;

            setParsedError({ title: errorTitle, message: errorMessage });
        };

        // Simple way of using async await in useEffect
        asyncFunction();
    }, [error, custom, t]);

    return parsedError;
}
