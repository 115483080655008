import React, { useCallback, useEffect, useMemo } from "react";
import { useGetHappening } from "../../hooks/useHappeningsApiHooks";
import { useEmbed, useHappeningId, useIsIframe } from "../../hooks/useRouterHooks";
import { RequestState } from "../../types/RequestState";
import Loading from "../Loading/Loading";
import HappeningDetail, { ReusableProps } from "./HappeningDetail";
import FriendlyError from "../FriendlyError/FriendlyError";
import { useTranslation } from "react-i18next";
import { CustomErrorMessage } from "../../types/CustomErrorMessage";

type Props = ReusableProps & {
    className: string;
    id?: string;
    modal?: boolean;
};

const HappeningDetailContainer = ({ id, modal, ...props }: Props) => {
    const { t } = useTranslation();
    const embed = useEmbed();
    const paramId = useHappeningId();
    const isIframe = useIsIframe();
    const [{ state, value, error }, fetchRequest] = useGetHappening();
    const customErrorMessages = useMemo<Array<CustomErrorMessage>>(
        () => [
            {
                status: 403,
                message: t("error.message.happening403"),
            },
        ],
        [t],
    );

    const happeningId = id || paramId;

    const getHappening = useCallback(() => {
        if (!happeningId) {
            return;
        }

        fetchRequest(happeningId);
    }, [fetchRequest, happeningId]);

    useEffect(() => {
        getHappening();
    }, [getHappening]);

    if ([RequestState.IDLE, RequestState.LOADING].includes(state)) {
        return <Loading />;
    }

    return (
        <FriendlyError state={state} error={error} retry={getHappening} custom={customErrorMessages}>
            <HappeningDetail happening={value!} embed={embed || modal} hideFooter={isIframe || modal} {...props} />
        </FriendlyError>
    );
};

HappeningDetailContainer.defaultProps = {
    className: "",
};

export default HappeningDetailContainer;
