import React from "react";
import OrganisationDetailModal, { Props as ModalProps } from "./OrganisationDetailModal";
import { ReusableProps as OrganisationDetailProps } from "../OrganisationDetail/OrganisationDetail";
import { useModalOrganisationId } from "../../hooks/useRouterHooks";

type Props = Pick<ModalProps, "fillPage" | "onAfterOpen"> & OrganisationDetailProps;

const OrganisationDetailModalContainer = (props: Props) => {
    const organisationId = useModalOrganisationId();
    return <OrganisationDetailModal isOpen={!!organisationId} organisationId={organisationId} {...props} />;
};

OrganisationDetailModalContainer.defaultProps = {
    className: "",
    fillPage: true,
};

export default OrganisationDetailModalContainer;
