type SearchParam = {
    key: string;
    value: string;
};

export function appendToSearch(newParams: SearchParam[], search?: string) {
    const params = new URLSearchParams(search);

    newParams.forEach((element) => {
        params.append(element.key, element.value);
    });

    return params.toString();
}

export function setToSearch(newParams: SearchParam[], search?: string) {
    const params = new URLSearchParams(search);

    newParams.forEach((element) => {
        params.set(element.key, element.value);
    });

    return params.toString();
}
