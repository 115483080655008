import React from "react";
import styles from "./OrganisationFooter.module.scss";
import classNames from "classnames";
import { OrganizerViewModel } from "../../openapi/backend";
import OrganisationIcon from "../OrganisationIcon/OrganisationIcon";
import Text from "../Text/Text";

type Props = {
    className: string;
    organisation: OrganizerViewModel;
};

const OrganisationFooter = ({ className, organisation }: Props) => {
    return (
        <div className={classNames(styles.container, className)}>
            <OrganisationIcon className={styles.icon} organisation={organisation} size={40} />
            <Text className={styles.text} label={organisation.name} />
        </div>
    );
};

OrganisationFooter.defaultProps = {
    className: "",
};

export default OrganisationFooter;
