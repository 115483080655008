/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import { Gender, GenderFromJSON, GenderFromJSONTyped, GenderToJSON } from "./";

/**
 *
 * @export
 * @interface ProfileRegistrationInput
 */
export interface ProfileRegistrationInput {
    /**
     *
     * @type {string}
     * @memberof ProfileRegistrationInput
     */
    givenName: string;
    /**
     *
     * @type {string}
     * @memberof ProfileRegistrationInput
     */
    familyName?: string;
    /**
     *
     * @type {string}
     * @memberof ProfileRegistrationInput
     */
    email?: string;
    /**
     *
     * @type {Date}
     * @memberof ProfileRegistrationInput
     */
    dateOfBirth?: Date;
    /**
     *
     * @type {number}
     * @memberof ProfileRegistrationInput
     */
    age?: number;
    /**
     *
     * @type {string}
     * @memberof ProfileRegistrationInput
     */
    postalCode: string;
    /**
     *
     * @type {Gender}
     * @memberof ProfileRegistrationInput
     */
    gender: Gender;
    /**
     *
     * @type {string}
     * @memberof ProfileRegistrationInput
     */
    password?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof ProfileRegistrationInput
     */
    tags?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof ProfileRegistrationInput
     */
    preferredFilters?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof ProfileRegistrationInput
     */
    phoneNumber?: string;
    /**
     *
     * @type {string}
     * @memberof ProfileRegistrationInput
     */
    school?: string;
}

/**
 * Check if a given object implements the ProfileRegistrationInput interface.
 */
export function instanceOfProfileRegistrationInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "givenName" in value;
    isInstance = isInstance && "postalCode" in value;
    isInstance = isInstance && "gender" in value;

    return isInstance;
}

export function ProfileRegistrationInputFromJSON(json: any): ProfileRegistrationInput {
    return ProfileRegistrationInputFromJSONTyped(json, false);
}

export function ProfileRegistrationInputFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ProfileRegistrationInput {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        givenName: !exists(json, "givenName") ? (undefined as any) : json["givenName"],
        familyName: !exists(json, "familyName") ? (undefined as any) : json["familyName"],
        email: !exists(json, "email") ? (undefined as any) : json["email"],
        dateOfBirth: !exists(json, "dateOfBirth") ? (undefined as any) : new Date(json["dateOfBirth"]),
        age: !exists(json, "age") ? (undefined as any) : json["age"],
        postalCode: !exists(json, "postalCode") ? (undefined as any) : json["postalCode"],
        gender: !exists(json, "gender") ? (undefined as any) : GenderFromJSON(json["gender"]),
        password: !exists(json, "password") ? (undefined as any) : json["password"],
        tags: !exists(json, "tags") ? (undefined as any) : json["tags"],
        preferredFilters: !exists(json, "preferredFilters") ? (undefined as any) : json["preferredFilters"],
        phoneNumber: !exists(json, "phoneNumber") ? (undefined as any) : json["phoneNumber"],
        school: !exists(json, "school") ? (undefined as any) : json["school"],
    };
}

export function ProfileRegistrationInputToJSON(value?: ProfileRegistrationInput | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
