export const EMBED_KEY = "embed";
export const SHARE_KEY = "share";
export const GROUP_KEY = "groups";
export const PAGE_KEY = "pagina";
export const DETAILS_KEY = "details";
export const HAPPENING_CLUSTER_KEY = "cluster";
export const SHOW_FILTERS_KEY = "filters";
export const DATE_START_KEY = "fromDate";
export const DATE_END_KEY = "toDate";
export const SEARCH_KEY = "query";
export const MIN_AGE_KEY = "minAge";
export const MAX_AGE_KEY = "maxAge";
export const HIDE_FULL_KEY = "vol";
export const ACCESSIBLE_ONLY_KEY = "aaanbod";
export const HOST_KEY = "host";
export const ORGANISATION_KEY = "organisation";
export const MAP_KEY = "map";
export const MAX_DISTANCE_KEY = "distance";
export const POSTAL_CODE_KEY = "postalCode";
export const CATEGORY_KEY = "category";
export const TARGET_AGES_KEY = "tagetAges";
