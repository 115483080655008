/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum RecurrenceType {
    Monthly = "Monthly",
    Weekly = "Weekly",
}

export function RecurrenceTypeFromJSON(json: any): RecurrenceType {
    return RecurrenceTypeFromJSONTyped(json, false);
}

export function RecurrenceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecurrenceType {
    return json as RecurrenceType;
}

export function RecurrenceTypeToJSON(value?: RecurrenceType | null): any {
    return value as any;
}
