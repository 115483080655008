import React, { useCallback, useEffect, useMemo } from "react";
import { useEmbed, useIsIframe, useOrganisationId } from "../../hooks/useRouterHooks";
import { RequestState } from "../../types/RequestState";
import Loading from "../Loading/Loading";
import OrganisationDetail, { ReusableProps } from "./OrganisationDetail";
import FriendlyError from "../FriendlyError/FriendlyError";
import { useTranslation } from "react-i18next";
import { CustomErrorMessage } from "../../types/CustomErrorMessage";
import { useGetOrganisationInfoCallback } from "../../hooks/useOrganisationApiHooks";

type Props = ReusableProps & {
    className: string;
    id?: string;
    modal?: boolean;
};

const OrganisationDetailContainer = ({ id, modal, ...props }: Props) => {
    const { t } = useTranslation();
    const embed = useEmbed();
    const paramId = useOrganisationId();
    const isIframe = useIsIframe();
    const [{ state, value, error }, fetchRequest] = useGetOrganisationInfoCallback();

    const customErrorMessages = useMemo<Array<CustomErrorMessage>>(
        () => [
            {
                status: 403,
                message: t("error.message.organisation403"),
            },
        ],
        [t],
    );

    const OrganisationId = id || paramId;

    const getOrganisation = useCallback(() => {
        if (!OrganisationId) {
            return;
        }
        fetchRequest(OrganisationId);
    }, [fetchRequest, OrganisationId]);

    useEffect(() => {
        getOrganisation();
    }, [getOrganisation]);

    if ([RequestState.IDLE, RequestState.LOADING].includes(state)) {
        return <Loading />;
    }

    return (
        <FriendlyError state={state} error={error} retry={getOrganisation} custom={customErrorMessages}>
            <OrganisationDetail
                organisation={value!}
                embed={embed || modal}
                hideFooter={isIframe || modal}
                {...props}
            />
        </FriendlyError>
    );
};

OrganisationDetailContainer.defaultProps = {
    className: "",
};

export default OrganisationDetailContainer;
