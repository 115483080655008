/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ImageViewModel
 */
export interface ImageViewModel {
    /**
     *
     * @type {number}
     * @memberof ImageViewModel
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ImageViewModel
     */
    href: string;
    /**
     *
     * @type {string}
     * @memberof ImageViewModel
     */
    fileName: string;
    /**
     *
     * @type {string}
     * @memberof ImageViewModel
     */
    orginalFileName: string;
    /**
     *
     * @type {string}
     * @memberof ImageViewModel
     */
    altText: string;
}

/**
 * Check if a given object implements the ImageViewModel interface.
 */
export function instanceOfImageViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "href" in value;
    isInstance = isInstance && "fileName" in value;
    isInstance = isInstance && "orginalFileName" in value;
    isInstance = isInstance && "altText" in value;

    return isInstance;
}

export function ImageViewModelFromJSON(json: any): ImageViewModel {
    return ImageViewModelFromJSONTyped(json, false);
}

export function ImageViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        href: !exists(json, "href") ? (undefined as any) : json["href"],
        fileName: !exists(json, "fileName") ? (undefined as any) : json["fileName"],
        orginalFileName: !exists(json, "orginalFileName") ? (undefined as any) : json["orginalFileName"],
        altText: !exists(json, "altText") ? (undefined as any) : json["altText"],
    };
}

export function ImageViewModelToJSON(value?: ImageViewModel | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
